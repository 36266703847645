.brz-ow-hidden {
  overflow: hidden !important; }

.brz-ow-visible {
  overflow: visible !important; }

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  scroll-behavior: auto; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

@-ms-viewport {
  width: device-width; }

.brz .brz-figcaption,
.brz .brz-figure,
.brz .brz-section {
  display: block; }

body {
  margin: 0; }
  body.brz-ed {
    background-color: #f3f3f3; }
  body.brz::before, body.brz::after {
    display: none; }

.brz-iframe {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

.brz {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .brz .brz-root__container.brz-reset-all {
    display: block;
    overflow: hidden;
    font-family: inherit; }

.brz .brz-hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin: 0; }

.brz .brz-h1,
.brz .brz-h2,
.brz .brz-h3,
.brz .brz-h4,
.brz .brz-h5,
.brz .brz-h6 {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0; }

.brz .brz-p {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0; }

.brz .brz-span {
  float: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit; }

.brz .brz-ol,
.brz .brz-ul,
.brz .brz-dl {
  margin: 0;
  list-style: none;
  padding: 0; }

.brz .brz-ol .brz-ol,
.brz .brz-ul .brz-ul,
.brz .brz-ol .brz-ul,
.brz .brz-ul .brz-ol {
  margin: 0; }

.brz .brz-dt {
  font-weight: bold; }

.brz .brz-dd {
  margin-bottom: 0.5rem;
  margin-left: 0; }

.brz .brz-blockquote {
  margin: 0 0 1rem; }

.brz .brz-dfn {
  font-style: italic; }

.brz .brz-b,
.brz .brz-strong {
  font-weight: 700; }

.brz .brz-a:not(.brz-btn) {
  color: #3dbfe8;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .brz .brz-a:not(.brz-btn)[href]:hover {
    color: #1aabd8;
    -webkit-box-shadow: none;
            box-shadow: none;
    cursor: pointer; }
  .brz .brz-a:not(.brz-btn):focus, .brz .brz-a:not(.brz-btn):active, .brz .brz-a:not(.brz-btn):hover {
    outline: none; }

.brz .brz-a.brz-btn[href] {
  text-decoration: none; }

.brz .brz-figure {
  margin: 0;
  min-width: auto;
  min-height: auto; }

.brz .brz-img {
  vertical-align: middle;
  border-style: none; }

.brz svg:not(:root) {
  overflow: hidden; }

.brz .brz-button,
.brz .brz-input,
.brz .brz-label,
.brz .brz-textarea {
  -ms-touch-action: manipulation;
      touch-action: manipulation; }

.brz .brz-table {
  border-collapse: collapse; }

.brz .brz-caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #828b92;
  text-align: left;
  caption-side: bottom; }

.brz .brz-th {
  text-align: left; }

.brz .brz-form {
  margin: 0; }

.brz .brz-label {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal; }

.brz .brz-button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

.brz .brz-input,
.brz .brz-button,
.brz .brz-select,
.brz .brz-optgroup,
.brz .brz-textarea {
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  height: initial;
  min-height: initial; }

.brz .brz-button,
.brz .brz-input {
  overflow: visible; }

.brz .brz-button,
.brz .brz-select {
  text-transform: none; }

.brz .brz-button {
  -webkit-appearance: button; }

.brz .brz-button::-moz-focus-inner {
  padding: 0;
  border-style: none; }

.brz .brz-input[type="radio"],
.brz .brz-input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

.brz .brz-input[type="number"] {
  -moz-appearance: textfield; }

.brz .brz-input[type="date"],
.brz .brz-input[type="time"],
.brz .brz-input[type="datetime-local"],
.brz .brz-input[type="month"] {
  -webkit-appearance: listbox; }

.brz .brz-textarea {
  overflow: auto;
  resize: vertical; }

.brz .brz-fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

.brz .brz-iframe,
.brz .brz-object {
  margin-bottom: 0;
  max-width: 100%; }

.brz .brz-iframe {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

.brz [hidden] {
  display: none !important; }

.brz .brz-reset-all {
  -ms-flex-line-pack: stretch;
      align-content: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -ms-flex-item-align: auto;
      align-self: auto;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-duration: 0s;
          animation-duration: 0s;
  -webkit-animation-fill-mode: none;
          animation-fill-mode: none;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-name: none;
          animation-name: none;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  azimuth: center;
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  background-attachment: scroll;
  background-blend-mode: normal;
  background-clip: border-box;
  background-color: transparent;
  background-image: none;
  background-origin: padding-box;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto auto;
  block-size: auto;
  border-block-end-color: currentcolor;
  border-block-end-style: none;
  border-block-end-width: medium;
  border-block-start-color: currentcolor;
  border-block-start-style: none;
  border-block-start-width: medium;
  border-bottom-color: currentcolor;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-collapse: separate;
  border-image-outset: 0s;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-inline-end-color: currentcolor;
  border-inline-end-style: none;
  border-inline-end-width: medium;
  border-inline-start-color: currentcolor;
  border-inline-start-style: none;
  border-inline-start-width: medium;
  border-left-color: currentcolor;
  border-left-style: none;
  border-left-width: medium;
  border-right-color: currentcolor;
  border-right-style: none;
  border-right-width: medium;
  border-spacing: 0;
  border-top-color: currentcolor;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-style: none;
  border-top-width: medium;
  bottom: auto;
  -webkit-box-decoration-break: slice;
          box-decoration-break: slice;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  -webkit-column-break-after: auto;
          break-after: auto;
  -webkit-column-break-before: auto;
          break-before: auto;
  -webkit-column-break-inside: auto;
          break-inside: auto;
  caption-side: top;
  caret-color: auto;
  clear: none;
  clip: auto;
  -webkit-clip-path: none;
          clip-path: none;
  color: initial;
  -webkit-column-count: auto;
     -moz-column-count: auto;
          column-count: auto;
  -webkit-column-fill: balance;
     -moz-column-fill: balance;
          column-fill: balance;
  -webkit-column-gap: normal;
     -moz-column-gap: normal;
          column-gap: normal;
  -webkit-column-rule-color: currentcolor;
     -moz-column-rule-color: currentcolor;
          column-rule-color: currentcolor;
  -webkit-column-rule-style: none;
     -moz-column-rule-style: none;
          column-rule-style: none;
  -webkit-column-rule-width: medium;
     -moz-column-rule-width: medium;
          column-rule-width: medium;
  -webkit-column-span: none;
     -moz-column-span: none;
          column-span: none;
  -webkit-column-width: auto;
     -moz-column-width: auto;
          column-width: auto;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  display: inline;
  empty-cells: show;
  -webkit-filter: none;
          filter: none;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  float: none;
  font-family: initial;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  -webkit-font-kerning: auto;
          font-kerning: auto;
  -webkit-font-language-override: normal;
          font-language-override: normal;
  font-size: medium;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  -webkit-font-variant-ligatures: normal;
          font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-position: normal;
  font-weight: normal;
  grid-auto-columns: auto;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-column-end: auto;
  grid-column-gap: 0;
  grid-column-start: auto;
  grid-row-end: auto;
  grid-row-gap: 0;
  grid-row-start: auto;
  grid-template-areas: none;
  grid-template-columns: none;
  grid-template-rows: none;
  height: auto;
  -webkit-hyphens: manual;
      -ms-hyphens: manual;
          hyphens: manual;
  image-orientation: 0deg;
  image-rendering: auto;
  image-resolution: 1dppx;
  ime-mode: auto;
  inline-size: auto;
  isolation: auto;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  left: auto;
  letter-spacing: normal;
  line-break: auto;
  line-height: normal;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: disc;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  margin-bottom: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  -webkit-mask-clip: border-box;
          mask-clip: border-box;
  -webkit-mask-composite: add;
          mask-composite: add;
  -webkit-mask-image: none;
          mask-image: none;
  mask-mode: match-source;
  -webkit-mask-origin: border-box;
          mask-origin: border-box;
  -webkit-mask-position: 0% 0%;
          mask-position: 0% 0%;
  -webkit-mask-repeat: repeat;
          mask-repeat: repeat;
  -webkit-mask-size: auto;
          mask-size: auto;
  mask-type: luminance;
  max-height: none;
  max-width: none;
  min-block-size: 0;
  min-height: 0;
  min-inline-size: 0;
  min-width: 0;
  mix-blend-mode: normal;
  -o-object-fit: fill;
     object-fit: fill;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  offset-block-end: auto;
  offset-block-start: auto;
  offset-inline-end: auto;
  offset-inline-start: auto;
  opacity: 1;
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
  orphans: 2;
  outline-color: initial;
  outline-offset: 0;
  outline-style: none;
  outline-width: medium;
  overflow: visible;
  overflow-wrap: normal;
  overflow-x: visible;
  overflow-y: visible;
  -webkit-padding-after: 0;
          padding-block-end: 0;
  -webkit-padding-before: 0;
          padding-block-start: 0;
  padding-bottom: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  -webkit-perspective: none;
          perspective: none;
  -webkit-perspective-origin: 50% 50%;
          perspective-origin: 50% 50%;
  pointer-events: auto;
  position: static;
  quotes: initial;
  resize: none;
  right: auto;
  ruby-align: space-around;
  ruby-merge: separate;
  ruby-position: over;
  scroll-behavior: auto;
  -ms-scroll-snap-coordinate: none;
      scroll-snap-coordinate: none;
  -ms-scroll-snap-destination: 0 0;
      scroll-snap-destination: 0 0;
  -ms-scroll-snap-points-x: none;
      scroll-snap-points-x: none;
  -ms-scroll-snap-points-y: none;
      scroll-snap-points-y: none;
  -ms-scroll-snap-type: none;
      scroll-snap-type: none;
  shape-image-threshold: 0;
  shape-margin: 0;
  shape-outside: none;
  -moz-tab-size: 8;
    -o-tab-size: 8;
       tab-size: 8;
  table-layout: auto;
  text-align: initial;
  -moz-text-align-last: auto;
       text-align-last: auto;
  text-combine-upright: none;
  -webkit-text-decoration-color: currentcolor;
          text-decoration-color: currentcolor;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  -webkit-text-emphasis-color: currentcolor;
          text-emphasis-color: currentcolor;
  -webkit-text-emphasis-position: over;
          text-emphasis-position: over right;
  -webkit-text-emphasis-style: none;
          text-emphasis-style: none;
  text-indent: 0;
  text-justify: auto;
  text-orientation: mixed;
  text-overflow: clip;
  text-rendering: auto;
  text-shadow: none;
  text-transform: none;
  text-underline-position: auto;
  top: auto;
  -ms-touch-action: auto;
      touch-action: auto;
  -webkit-transform: none;
          transform: none;
  transform-box: border-box;
  -webkit-transform-origin: 50% 50% 0;
          transform-origin: 50% 50% 0;
  -webkit-transform-style: flat;
          transform-style: flat;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transition-duration: 0s;
          transition-duration: 0s;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 2;
  width: auto;
  will-change: auto;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  -webkit-writing-mode: horizontal-tb;
      -ms-writing-mode: lr-tb;
          writing-mode: horizontal-tb;
  z-index: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none; }

.brz .brz-h1,
.brz .brz-h2,
.brz .brz-h3,
.brz .brz-h4,
.brz .brz-h5,
.brz .brz-h6 {
  font-family: inherit;
  font-weight: 700;
  line-height: 1.2;
  color: inherit; }

.brz .brz-h1 {
  font-size: 55px;
  font-weight: 400; }

.brz .brz-h2 {
  font-size: 40px; }

.brz .brz-h3 {
  font-size: 32px; }

.brz .brz-h4 {
  font-size: 26px; }

.brz .brz-h5 {
  font-size: 19px; }

.brz .brz-h6 {
  font-size: 14px; }

.brz .brz-p {
  font-size: 14px;
  font-weight: normal; }

.brz .brz-flex-xs-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.brz .brz-flex-xs-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }

.brz .brz-flex-xs-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.brz .brz-justify-content-xs-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important; }

.brz .brz-justify-content-xs-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }

.brz .brz-justify-content-xs-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important; }

.brz .brz-justify-content-xs-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }

.brz .brz-justify-content-xs-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.brz .brz-align-items-xs-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important; }

.brz .brz-align-items-xs-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important; }

.brz .brz-align-items-xs-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important; }

.brz .brz-align-items-xs-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important; }

.brz .brz-align-items-xs-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important; }

.brz .brz-align-self-xs-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.brz .brz-align-self-xs-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.brz .brz-align-self-xs-center {
  -ms-flex-item-align: center !important;
      align-self: center !important; }

.brz .brz-align-self-xs-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.brz .brz-align-self-xs-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }

.brz .brz-flex-xs-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.brz .brz-flex-xs-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

@media (min-width: 480px) {
  .brz .brz-flex-ms-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .brz .brz-flex-ms-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .brz .brz-flex-ms-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .brz .brz-justify-content-ms-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .brz .brz-justify-content-ms-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .brz .brz-justify-content-ms-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .brz .brz-justify-content-ms-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .brz .brz-justify-content-ms-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .brz .brz-align-items-ms-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .brz .brz-align-items-ms-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .brz .brz-align-items-ms-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .brz .brz-align-items-ms-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .brz .brz-align-items-ms-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .brz .brz-align-self-ms-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .brz .brz-align-self-ms-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .brz .brz-align-self-ms-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .brz .brz-align-self-ms-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .brz .brz-align-self-ms-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; }
  .brz .brz-flex-ms-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .brz .brz-flex-ms-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; } }

@media (min-width: 768px) {
  .brz .brz-flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .brz .brz-flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .brz .brz-flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .brz .brz-justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .brz .brz-justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .brz .brz-justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .brz .brz-justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .brz .brz-justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .brz .brz-align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .brz .brz-align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .brz .brz-align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .brz .brz-align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .brz .brz-align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .brz .brz-align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .brz .brz-align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .brz .brz-align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .brz .brz-align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .brz .brz-align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; }
  .brz .brz-flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .brz .brz-flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; } }

@media (min-width: 992px) {
  .brz .brz-flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .brz .brz-flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .brz .brz-flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .brz .brz-justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .brz .brz-justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .brz .brz-justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .brz .brz-justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .brz .brz-justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .brz .brz-align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .brz .brz-align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .brz .brz-align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .brz .brz-align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .brz .brz-align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .brz .brz-align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .brz .brz-align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .brz .brz-align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .brz .brz-align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .brz .brz-align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; }
  .brz .brz-flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .brz .brz-flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; } }

@media (min-width: 1200px) {
  .brz .brz-flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .brz .brz-flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .brz .brz-flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .brz .brz-justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .brz .brz-justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .brz .brz-justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .brz .brz-justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .brz .brz-justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .brz .brz-align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .brz .brz-align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .brz .brz-align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .brz .brz-align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .brz .brz-align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .brz .brz-align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .brz .brz-align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .brz .brz-align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .brz .brz-align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .brz .brz-align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; }
  .brz .brz-flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .brz .brz-flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; } }

@media (min-width: 1400px) {
  .brz .brz-flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .brz .brz-flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .brz .brz-flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .brz .brz-justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .brz .brz-justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .brz .brz-justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .brz .brz-justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .brz .brz-justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .brz .brz-align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .brz .brz-align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .brz .brz-align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .brz .brz-align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .brz .brz-align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .brz .brz-align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .brz .brz-align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .brz .brz-align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .brz .brz-align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .brz .brz-align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; }
  .brz .brz-flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .brz .brz-flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; } }

.brz .brz-d-block {
  display: block !important; }

.brz .brz-d-inline-block {
  display: inline-block !important; }

.brz .brz-d-inline {
  display: inline !important; }

.brz .brz-d-none {
  display: none !important; }

.brz .brz-d-table {
  width: 100%;
  display: table;
  position: relative; }

.brz .brz-d-table-cell {
  display: table-cell; }

.brz .brz-blocked {
  pointer-events: none; }

.brz .brz-d-xs-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 0; }

.brz .brz-d-xs-inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }

@media (min-width: 480px) {
  .brz .brz-d-ms-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 0; }
  .brz .brz-d-ms-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; } }

@media (min-width: 768px) {
  .brz .brz-d-sm-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 0; }
  .brz .brz-d-sm-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; } }

@media (min-width: 992px) {
  .brz .brz-d-md-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 0; }
  .brz .brz-d-md-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; } }

@media (min-width: 1200px) {
  .brz .brz-d-lg-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 0; }
  .brz .brz-d-lg-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; } }

@media (min-width: 1400px) {
  .brz .brz-d-xl-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 0; }
  .brz .brz-d-xl-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; } }

.brz .brz-p-relative {
  position: relative !important; }

.brz .brz-p-absolute {
  position: absolute !important; }

.brz .brz-p-fixed {
  position: fixed !important; }

.brz .brz-invisible {
  visibility: hidden !important; }

.brz .brz-visible {
  visibility: visible !important; }

.brz .brz-hidden {
  display: none !important; }

.brz .brz-visible-print-block {
  display: none !important; }
  @media print {
    .brz .brz-visible-print-block {
      display: block !important; } }

.brz .brz-visible-print-inline {
  display: none !important; }
  @media print {
    .brz .brz-visible-print-inline {
      display: inline !important; } }

.brz .brz-visible-print-inline-block {
  display: none !important; }
  @media print {
    .brz .brz-visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .brz .brz-hidden-print {
    display: none !important; } }

.brz .brz-ow-hidden {
  overflow: hidden !important; }

.brz .brz-ow-visible {
  overflow: visible !important; }

.brz .brz-pointer-events-none {
  pointer-events: none !important; }

.brz .brz-pointer-events-auto {
  pointer-events: auto !important; }

.brz .brz-fw-100 {
  font-weight: 100 !important; }

.brz .brz-fw-200 {
  font-weight: 200 !important; }

.brz .brz-fw-300 {
  font-weight: 300 !important; }

.brz .brz-fw-400 {
  font-weight: 400 !important; }

.brz .brz-fw-500 {
  font-weight: 500 !important; }

.brz .brz-fw-600 {
  font-weight: 600 !important; }

.brz .brz-fw-700 {
  font-weight: 700 !important; }

.brz .brz-fw-800 {
  font-weight: 800 !important; }

.brz .brz-fw-900 {
  font-weight: 900 !important; }

.brz .brz-typography-inherit {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit; }

.brz .brz--required {
  color: #ff0e0e;
  padding-left: 5px; }

.brz .brz-animated {
  opacity: 0;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards; }
  .brz .brz-animated:not(.brz-animate) {
    -webkit-animation-name: none;
            animation-name: none; }
  .brz .brz-animated.brz-animate-opacity {
    opacity: 1; }

.brz .brz-icon-svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke: none;
  max-width: none;
  position: relative;
  fill: currentColor;
  color: currentColor; }
  .brz .brz-icon-svg use {
    /* change 'inherit' to a color value if you want to add a secondary color to Nucleo icons */
    fill: inherit; }
  .brz .brz-icon-svg path {
    -webkit-transition: none;
    transition: none; }
  .brz .brz-icon-svg.grid-16 {
    height: 16px;
    width: 16px; }
  .brz .brz-icon-svg.grid-24 {
    height: 24px;
    width: 24px; }
  .brz .brz-icon-svg.glyph {
    fill: currentColor;
    stroke: none; }
  .brz .brz-icon-svg.outline {
    stroke: currentColor;
    fill: none; }
    .brz .brz-icon-svg.outline.stroke-2 {
      stroke-width: 2px; }

.brz .brz-section {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: visible; }
  .brz .brz-section__content {
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%; }
    .brz .brz-section__content > .brz-bg {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-align: inherit;
          -ms-flex-align: inherit;
              align-items: inherit;
      width: 100%;
      height: 100%; }
    .brz .brz-section__content > .brz-bg > .brz-bg-content {
      height: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
    .brz .brz-section__content .brz-container__wrap {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
    .brz .brz-section__content > .brz-container {
      position: relative;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
  .brz .brz-section > .brz-slick-slider > .slick-list > .slick-track > .slick-slide {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
  .brz .brz-section > .brz-slick-slider .brz-section__content {
    height: 100%; }
  .brz .brz-section > .brz-slick-slider:not(.slick-initialized) {
    overflow: hidden; }
    .brz .brz-section > .brz-slick-slider:not(.slick-initialized) > .brz-section__content:not(:first-child) {
      display: none; }

.brz .brz-section__header > .brz-section__menu-item {
  position: relative;
  z-index: 1055;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.brz .brz-section__header--animated {
  position: fixed;
  z-index: 1056;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
  opacity: 0;
  -webkit-transition: none;
  transition: none; }
  .brz .brz-section__header--animated-opened {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
    -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear; }
  .brz .brz-section__header--animated-closed {
    -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear; }

.brz .brz-section__header--fixed {
  position: relative;
  z-index: 1055; }
  .brz .brz-section__header--fixed-opened {
    position: fixed;
    z-index: 1056;
    top: 0;
    left: 0;
    width: 100%; }

.brz .brz-section__header--fixed > .brz-section__menu-item, .brz .brz-section__header-sticky-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.brz .brz-section__menu-item > .brz-bg:after,
.brz .brz-section__header-sticky-item > .brz-bg:after,
.brz .brz-footer > .brz-bg:after {
  content: "";
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.brz .brz-footer {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .brz .brz-footer > .brz-container {
    position: relative;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }

.brz .brz-popup__inner {
  height: 100%; }

.brz .brz-footer {
  position: relative; }
  .brz .brz-footer__bg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .brz .brz-footer__bg > .brz-bg-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 100%;
    width: 100%; }
  .brz .brz-footer .brz-container__wrap {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }

.brz .brz-story {
  background-color: #222;
  min-height: 100vh; }
  .brz .brz-story .brz-container {
    font-size: 436px; }
  .brz .brz-story .brz-ed-box__resizer {
    font-size: initial; }
  .brz .brz-story > .brz-slick-slider .brz-slick-slider__arrow {
    padding: 20px;
    border-radius: 50%;
    position: absolute;
    background-color: #c9c9c9;
    top: 50%;
    opacity: 0.4;
    z-index: 3;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .brz .brz-story > .brz-slick-slider .brz-slick-slider__arrow .brz-icon-svg {
      width: 20px;
      height: 20px; }
  .brz .brz-story > .brz-slick-slider .brz-slick-slider__arrow-prev {
    left: 20px; }
  .brz .brz-story > .brz-slick-slider .brz-slick-slider__arrow-next,
  .brz .brz-story > .brz-slick-slider .brz-slick-slider__arrow-replay {
    right: 20px; }
  .brz .brz-story > .brz-slick-slider .brz-slick-slider__arrow-next.slick-disabled,
  .brz .brz-story > .brz-slick-slider .brz-slick-slider__arrow-prev.slick-disabled,
  .brz .brz-story > .brz-slick-slider .brz-slick-slider__arrow-replay {
    display: none !important;
    opacity: 0; }
  .brz .brz-story > .brz-slick-slider .brz-slick-slider__arrow-replay.slick-disabled {
    display: block !important;
    opacity: 0.4; }
  .brz .brz-story > .brz-slick-slider .brz-slick-slider__arrow:hover {
    opacity: 1; }
  .brz .brz-story > .brz-slick-slider > .slick-list > .slick-track > .slick-slide {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0.4;
    overflow: hidden;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
    .brz .brz-story > .brz-slick-slider > .slick-list > .slick-track > .slick-slide .brz-slick-slider__inner-arrow {
      display: none; }
    .brz .brz-story > .brz-slick-slider > .slick-list > .slick-track > .slick-slide > div:not(.brz-container) {
      font-size: 0; }
  .brz .brz-story > .brz-slick-slider > .slick-list > .slick-track > .slick-current {
    opacity: 1;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transform: scale(1);
            transform: scale(1); }

.brz:not(.brz-ed) .brz-story .brz-container {
  font-size: 37.5vh; }

.brz:not(.brz-ed) .brz-story .brz-wrapper {
  pointer-events: none; }

.brz:not(.brz-ed) .brz-story a.brz-a,
.brz:not(.brz-ed) .brz-story .brz-map,
.brz:not(.brz-ed) .brz-story .brz-video,
.brz:not(.brz-ed) .brz-story .brz-story-linked,
.brz:not(.brz-ed) .brz-story .brz-forms2,
.brz:not(.brz-ed) .brz-story .brz-embed-code,
.brz:not(.brz-ed) .brz-story .brz-rich-text a {
  pointer-events: initial; }

.brz:not(.brz-ed) .brz-story > .brz-slick-slider:not(.slick-initialized) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .brz:not(.brz-ed) .brz-story > .brz-slick-slider:not(.slick-initialized) > .brz-section__content {
    height: 75vh;
    max-width: 37.5vh;
    min-width: 320px;
    min-height: 533px;
    max-height: 100%;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }

.brz:not(.brz-ed) .brz-story > .brz-slick-slider {
  padding-top: 80px; }

.brz:not(.brz-ed) .brz-story > .slick-slider > .slick-list > .slick-track > .slick-slide {
  height: 75vh;
  max-width: 37.5vh;
  min-width: 320px;
  min-height: 533px;
  max-height: 100%; }

.brz:not(.brz-ed) .brz-story > .slick-slider > .brz-slick-slider__dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 740px;
  bottom: -70px;
  margin: 15px auto 0; }
  .brz:not(.brz-ed) .brz-story > .slick-slider > .brz-slick-slider__dots > li {
    padding: 15px 0;
    display: block;
    width: 30%;
    cursor: pointer;
    margin-right: 4px;
    float: left;
    color: rgba(255, 255, 255, 0.4); }
    .brz:not(.brz-ed) .brz-story > .slick-slider > .brz-slick-slider__dots > li > button {
      width: 100%;
      height: 4px;
      display: block;
      border-radius: 100px;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
  .brz:not(.brz-ed) .brz-story > .slick-slider > .brz-slick-slider__dots > li.slick-active {
    position: relative;
    overflow: hidden; }
    .brz:not(.brz-ed) .brz-story > .slick-slider > .brz-slick-slider__dots > li.slick-active::after {
      content: "";
      position: absolute;
      top: 15px;
      left: 0;
      width: 0%;
      height: 4px;
      background-color: white;
      border-radius: 100px; }
    .brz:not(.brz-ed) .brz-story > .slick-slider > .brz-slick-slider__dots > li.slick-active > button {
      background-color: #fff; }
  .brz:not(.brz-ed) .brz-story > .slick-slider > .brz-slick-slider__dots > li:last-child {
    margin-right: 0; }

@media (max-width: 767px) {
  .brz:not(.brz-ed) .brz-story {
    min-height: auto; }
    .brz:not(.brz-ed) .brz-story .brz-container {
      font-size: 100vw; }
    .brz:not(.brz-ed) .brz-story > .brz-slick-slider:not(.slick-initialized) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .brz:not(.brz-ed) .brz-story > .brz-slick-slider:not(.slick-initialized) > .brz-section__content {
        height: 100vh;
        min-width: 320px;
        min-height: 533px;
        max-width: 100vw;
        max-height: 100%; }
    .brz:not(.brz-ed) .brz-story > .brz-slick-slider {
      padding-top: 0; }
    .brz:not(.brz-ed) .brz-story .slick-slider .brz-slick-slider__arrow {
      display: none !important; }
    .brz:not(.brz-ed) .brz-story .slick-slider > .slick-list > .slick-track > .slick-slide {
      height: 100vh;
      min-width: 320px;
      min-height: 533px;
      max-width: 100vw;
      max-height: 100%;
      opacity: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-transform: translateZ(0);
              transform: translateZ(0); }
      .brz:not(.brz-ed) .brz-story .slick-slider > .slick-list > .slick-track > .slick-slide > div {
        width: 100%; }
      .brz:not(.brz-ed) .brz-story .slick-slider > .slick-list > .slick-track > .slick-slide .brz-slick-slider__inner-arrow {
        background-color: transparent;
        height: 100%;
        max-height: 100vh;
        top: 0;
        width: 50%;
        display: block;
        position: absolute; }
      .brz:not(.brz-ed) .brz-story .slick-slider > .slick-list > .slick-track > .slick-slide .brz-slick-slider__inner-arrow-next {
        right: 0; }
      .brz:not(.brz-ed) .brz-story .slick-slider > .slick-list > .slick-track > .slick-slide .brz-slick-slider__inner-arrow-prev {
        left: 0; }
    .brz:not(.brz-ed) .brz-story .slick-slider > .brz-slick-slider__dots {
      margin-top: 0;
      position: absolute;
      padding: 0 15px;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      top: 0;
      height: 30px; } }

@media (max-height: 711px) and (min-width: 767px) {
  .brz:not(.brz-ed) .brz-story .brz-container {
    font-size: 320px; } }

.brz _:-ms-lang(x)::-ms-backdrop,
.brz .brz-container,
.brz .brz-section__content .brz-container__wrap {
  -ms-flex: auto !important;
  flex: auto !important; }

@-webkit-keyframes storyProgress {
  to {
    width: 100%; } }

@keyframes storyProgress {
  to {
    width: 100%; } }

.brz .brz-container,
.brz .brz-container__wrap {
  width: 100%; }

.brz .brz-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: var(--brz-section-container-width, 100%); }

.brz .brz-container__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.brz .brz-row {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  text-align: left;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  max-width: 100%;
  width: 100%; }
  .brz .brz-row__container {
    position: relative;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .brz .brz-row__container .brz-link-container {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0; }
    .brz .brz-row__container > .brz-bg {
      left: auto; }
    .brz .brz-row__container [data-scroll-motion] {
      width: 100%;
      max-width: 100%; }

.brz .brz-columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 1px;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  will-change: flex, max-width; }
  .brz .brz-columns .brz-container-link {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }
  .brz .brz-columns > [data-scroll-motion] > .brz-bg,
  .brz .brz-columns > .brz-bg {
    width: auto;
    height: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.brz .brz-column__items {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto; }
  .brz .brz-column__items > div:not(.brz-ed-sortable--empty) {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    min-width: 0; }

.brz .brz-wrapper {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  min-width: 0;
  min-height: 1px; }

.brz .brz-wrapper-clone > div {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  min-width: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.brz .brz-wrapper-clone__item {
  position: relative;
  min-height: 1px;
  -ms-flex-preferred-size: auto;
      flex-basis: auto; }

.brz .brz-bg {
  overflow: hidden; }

.brz .brz-bg,
.brz .brz-bg-color,
.brz .brz-bg-image,
.brz .brz-bg-video,
.brz .brz-bg-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.brz .brz-bg-image {
  background-repeat: no-repeat;
  background-size: cover; }

.brz .brz-bg-shape {
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  pointer-events: none; }
  .brz .brz-bg-shape__top {
    top: 0; }
  .brz .brz-bg-shape__bottom {
    bottom: 0;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg); }

.brz .brz-bg-image-parallax {
  width: 100%;
  background-size: cover !important;
  background-position: 50% 50%;
  left: 0;
  top: -50vh;
  height: 100vh;
  -webkit-transition: all 0s ease !important;
  transition: all 0s ease !important;
  -webkit-transform-origin: center center 0;
          transform-origin: center center 0;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

.brz .brz-iframe.brz-bg-video__cover,
.brz .brz-iframe.brz-bg-map__cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  max-width: none;
  background-repeat: no-repeat;
  background-size: cover; }

.brz .brz-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #ccc;
  -webkit-transition: background 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  transition: background 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
  background-image: none;
  white-space: nowrap;
  font-weight: 400;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  text-decoration: none;
  outline: none; }
  .brz .brz-btn .brz-ed-box__resizer {
    height: auto;
    font-size: inherit; }
  .brz .brz-btn:hover, .brz .brz-btn:focus, .brz .brz-btn.focus {
    text-decoration: none; }
  .brz .brz-btn:focus {
    text-decoration: none; }
  .brz .brz-btn .brz-span {
    display: block; }
  .brz .brz-btn .brz-btn--story-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }

.brz .buttons {
  margin: -10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .brz .buttons .brz-wrapper-clone__item {
    padding: 10px; }

.brz .brz-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  vertical-align: middle;
  max-width: 100%;
  -webkit-transition: background 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  transition: background 0.3s ease, color 0.3s ease, border-color 0.3s ease; }

.brz .brz-story .brz-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .brz .brz-story .brz-icon .brz-icon-svg {
    width: 100%;
    height: 100%; }

.brz .brz-list {
  margin: -6px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .brz .brz-list .brz-list__item {
    padding: 6px; }

.brz .brz-countdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .brz .brz-countdown__item {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 25%;
            flex: 1 0 25%;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    word-break: break-word;
    word-wrap: break-word; }
  .brz .brz-countdown__number {
    font-size: 1em;
    margin-bottom: 15px; }
  .brz .brz-countdown .brz-ed-box__resizer {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%; }

.brz .brz-countdown2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .brz .brz-countdown2__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    text-align: center;
    word-break: break-word;
    word-wrap: break-word;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .brz .brz-countdown2__item:first-child {
      margin-left: 0 !important; }
    .brz .brz-countdown2__item:last-child {
      margin-right: 0 !important; }
  .brz .brz-countdown2__number {
    font-size: 1em; }
  .brz .brz-countdown2__label {
    text-transform: capitalize; }
  .brz .brz-countdown2 .brz-countdown2-parts {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%; }
  .brz .brz-countdown2 .brz-countdown2-message {
    display: none;
    min-height: 55px;
    width: 100%;
    margin-top: 15px;
    padding: 17px 30px;
    padding-bottom: 0;
    text-align: center; }

.brz .brz-countdown2-style1 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.brz .brz-countdown2-style2 .brz-countdown2__item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }

.brz .brz-countdown2-style2 .brz-countdown2__label {
  margin-left: 5px; }

.brz .brz-countdown2-style3 .brz-countdown2-parts {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.brz .brz-countdown2-style3 .brz-countdown2__item .brz-countdown2__label {
  display: none; }

.brz .brz-countdown2_custom {
  height: auto !important;
  margin: 0 !important; }

.brz .brz-spacer {
  width: 100%; }

.brz .brz-icon-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  text-align: left;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
  .brz .brz-icon-text .brz-text__editor,
  .brz .brz-icon-text .brz-text-btn {
    width: 100%; }
  .brz .brz-icon-text .brz-text__editor {
    white-space: pre-wrap; }
  .brz .brz-icon-text .brz-wrapper-clone {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word; }
  .brz .brz-icon-text .brz-icon__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: auto; }
    .brz .brz-icon-text .brz-icon__container .brz-ed-box__resizer {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }

.brz .brz-line {
  position: relative;
  line-height: 1;
  font-size: 0;
  padding-top: 7px;
  padding-bottom: 7px;
  display: inline-block; }
  .brz .brz-line-container::before, .brz .brz-line-container::after {
    content: "";
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
  .brz .brz-line-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center; }
  .brz .brz-line .brz-hr {
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    background: none;
    width: 100%; }

.brz .brz-map {
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: middle; }
  .brz .brz-map::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    pointer-events: none; }
  .brz .brz-map .brz-map-content {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%; }
  .brz .brz-map .brz-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; }

.brz .brz-video {
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: middle; }
  .brz .brz-video__ios iframe {
    opacity: 0; }
  .brz .brz-video:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    pointer-events: none; }
  .brz .brz-video .brz-video-content {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%; }
  .brz .brz-video .brz-iframe,
  .brz .brz-video .brz-video__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; }
  .brz .brz-video .brz-iframe {
    height: 100%;
    width: 100%; }
  .brz .brz-video .brz-video__cover {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 1; }
    .brz .brz-video .brz-video__cover:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      background-repeat: no-repeat; }
    .brz .brz-video .brz-video__cover .brz-video__cover-icon {
      position: relative;
      z-index: 1;
      color: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      border-radius: 50%; }
      .brz .brz-video .brz-video__cover .brz-video__cover-icon .brz-icon-svg {
        width: 100%;
        left: 2px; }
      .brz .brz-video .brz-video__cover .brz-video__cover-icon .brz-span {
        line-height: 0; }
    .brz .brz-video .brz-video__cover:hover {
      cursor: pointer; }
      .brz .brz-video .brz-video__cover:hover .brz-video__cover-icon {
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }
  .brz .brz-video .brz-shortcode__placeholder {
    position: absolute;
    top: 0; }
  .brz .brz-video .brz-video-elem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    z-index: 999;
    min-width: 150px; }
    .brz .brz-video .brz-video-elem video {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      z-index: 99; }
    .brz .brz-video .brz-video-elem .brz-video-custom-video-controls {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      height: 50px;
      z-index: 999; }
    .brz .brz-video .brz-video-elem .brz-video-custom-play-pause-btn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      cursor: pointer;
      z-index: 3; }
    .brz .brz-video .brz-video-elem .brz-video-custom-current-time {
      margin-left: 25px; }
    .brz .brz-video .brz-video-elem .brz-video-custom-total-time {
      margin-left: 15px; }
    .brz .brz-video .brz-video-elem .brz-video-custom-slider {
      position: relative;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      cursor: pointer; }
      .brz .brz-video .brz-video-elem .brz-video-custom-slider .brz-video-custom-progress {
        border-radius: inherit;
        position: absolute;
        pointer-events: none; }
      .brz .brz-video .brz-video-elem .brz-video-custom-slider:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        background-repeat: no-repeat;
        opacity: 0.35; }
    .brz .brz-video .brz-video-elem .brz-video-custom-controls {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      z-index: 3; }
      .brz .brz-video .brz-video-elem .brz-video-custom-controls .brz-video-custom-slider {
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 5px;
        height: 8px;
        overflow: hidden; }
        .brz .brz-video .brz-video-elem .brz-video-custom-controls .brz-video-custom-slider .brz-video-custom-progress {
          height: 100%; }
      .brz .brz-video .brz-video-elem .brz-video-custom-controls span {
        cursor: default; }
    .brz .brz-video .brz-video-elem .brz-video-custom-controls:empty {
      display: none; }
    .brz .brz-video .brz-video-elem .brz-video-custom-volume {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-left: 15px;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      z-index: 3; }
      .brz .brz-video .brz-video-elem .brz-video-custom-volume .brz-video-custom-fullscreen-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
      .brz .brz-video .brz-video-elem .brz-video-custom-volume .brz-video-custom-fullscreen-icon {
        margin-right: 15px;
        cursor: pointer; }
      .brz .brz-video .brz-video-elem .brz-video-custom-volume .brz-video-custom-volume-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        cursor: pointer; }
      .brz .brz-video .brz-video-elem .brz-video-custom-volume .brz-video-custom-volume-controls {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 60px;
        height: 8px;
        margin-left: 10px;
        border-radius: 5px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        overflow: hidden; }
        .brz .brz-video .brz-video-elem .brz-video-custom-volume .brz-video-custom-volume-controls .brz-video-custom-progress {
          bottom: 0;
          height: 100%;
          width: 100%; }
  .brz .brz-video .brz-video-custom-fullScreen-window-show video {
    padding-bottom: 50px; }
  .brz .brz-video .brz-video-custom-fullScreen-window-show .brz-video-custom-video-controls {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; }

.brz .brz-custom-video {
  min-width: 150px; }
  .brz .brz-custom-video .brz-video-content {
    overflow: hidden; }
    .brz .brz-custom-video .brz-video-content .brz-shortcode__placeholder {
      border-radius: 0 !important; }
    .brz .brz-custom-video .brz-video-content:hover .brz-shortcode__placeholder .brz-icon-svg {
      cursor: pointer; }

.brz .brz-custom-video.brz-video-on-controls-hidden .brz-video__cover,
.brz .brz-custom-video.brz-video-on-controls-hidden .brz-shortcode__placeholder,
.brz .brz-custom-video.brz-video-on-controls-hidden video {
  padding-bottom: 50px; }

.brz .brz-custom-video.brz-video-off-controls-hidden {
  background: inherit;
  padding-bottom: 0; }
  .brz .brz-custom-video.brz-video-off-controls-hidden video {
    padding-bottom: 0;
    background: inherit; }
  .brz .brz-custom-video.brz-video-off-controls-hidden .brz-shortcode__placeholder {
    top: 0;
    padding-top: 0; }
  .brz .brz-custom-video.brz-video-off-controls-hidden .brz-video__cover {
    padding-bottom: 0; }

.brz .brz-image-fix-1-1 {
  padding-top: 100%; }

.brz .brz-image-fix-2-1 {
  padding-top: 50%; }

.brz .brz-image-fix-2-3 {
  padding-top: 150%; }

.brz .brz-image-fix-3-4 {
  padding-top: 125%; }

.brz .brz-image-fix-4-3 {
  padding-top: 75%; }

.brz .brz-image-fix-9-16 {
  padding-top: 177.8%; }

.brz .brz-image-fix-16-9 {
  padding-top: 56.25%; }

.brz .brz-video-playlist {
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: middle; }
  .brz .brz-video-playlist .brz-play-button {
    line-height: 0; }
  .brz .brz-video-playlist:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    pointer-events: none; }
  .brz .brz-video-playlist .brz-iframe,
  .brz .brz-video-playlist .brz-video-playlist__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; }
  .brz .brz-video-playlist .brz-video-playlist__cover {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .brz .brz-video-playlist .brz-video-playlist__cover:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      background-size: cover;
      background-repeat: no-repeat; }
    .brz .brz-video-playlist .brz-video-playlist__cover .brz-video-playlist__cover-icon {
      position: relative;
      width: 80px;
      height: 80px;
      font-size: 28px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      border-radius: 50%;
      z-index: 1;
      color: #fff;
      -webkit-transition: -webkit-transform 0.3s ease;
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
      .brz .brz-video-playlist .brz-video-playlist__cover .brz-video-playlist__cover-icon .brz-icon-svg {
        width: 100%;
        left: 2px; }
      .brz .brz-video-playlist .brz-video-playlist__cover .brz-video-playlist__cover-icon a {
        line-height: 0; }
    .brz .brz-video-playlist .brz-video-playlist__cover:hover {
      cursor: pointer; }
      .brz .brz-video-playlist .brz-video-playlist__cover:hover .brz-video-playlist__cover-icon {
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }
  .brz .brz-video-playlist .brz-video-playlist-main,
  .brz .brz-video-playlist .brz-video-playlist-video-elem {
    position: relative; }
    .brz .brz-video-playlist .brz-video-playlist-main .brz-shortcode__placeholder,
    .brz .brz-video-playlist .brz-video-playlist-main .brz-img,
    .brz .brz-video-playlist .brz-video-playlist-video-elem .brz-shortcode__placeholder,
    .brz .brz-video-playlist .brz-video-playlist-video-elem .brz-img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%; }
  .brz .brz-video-playlist .brz-video-playlist-main__cover:after,
  .brz .brz-video-playlist .brz-video-playlist-video-elem:after {
    content: "";
    display: block;
    width: 100%;
    padding-top: 56.25%; }
  .brz .brz-video-playlist .brz-video-playlist-main__content {
    padding-top: 56.25%; }
  .brz .brz-video-playlist .brz-video-playlist-video-item {
    cursor: pointer; }
  .brz .brz-video-playlist .brz-video-playlist-title-video {
    word-break: break-word; }
    .brz .brz-video-playlist .brz-video-playlist-title-video .brz-span {
      display: block; }
    .brz .brz-video-playlist .brz-video-playlist-title-video .brz-span:last-child {
      margin-bottom: 0; }

.brz .brz-video-playlist-horizontal .brz-video-playlist-sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 100%;
  overflow: auto; }

.brz .brz-video-playlist-horizontal .brz-video-playlist-video-item {
  cursor: pointer;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .brz .brz-video-playlist-horizontal .brz-video-playlist-video-item .brz-video-playlist-title-video {
    margin-left: 10px; }
  .brz .brz-video-playlist-horizontal .brz-video-playlist-video-item .brz-img {
    -o-object-fit: cover;
       object-fit: cover; }

.brz .brz-video-playlist-horizontal ::-webkit-scrollbar {
  width: 8px;
  height: 95px;
  cursor: pointer; }

.brz .brz-video-playlist-horizontal ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0); }

.brz .brz-video-playlist-horizontal ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px; }

.brz .brz-video-playlist-vertical .brz-video-playlist-sidebar {
  white-space: nowrap;
  overflow: auto; }

.brz .brz-video-playlist-vertical .brz-video-playlist-video-item {
  text-align: center; }

.brz .brz-video-playlist-vertical .brz-video-playlist-video-elem {
  margin-left: auto;
  margin-right: auto; }

.brz .brz-video-playlist-vertical ::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  cursor: pointer; }

.brz .brz-video-playlist-vertical ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0); }

.brz .brz-video-playlist-vertical ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); }

.brz .brz-video-playlist-vertical.brz-video-playlist-under {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse; }
  .brz .brz-video-playlist-vertical.brz-video-playlist-under .brz-video-playlist__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse; }

@media (max-width: 767px) {
  .brz .brz-video-playlist .brz-video-playlist-main {
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    width: 100% !important;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); }
  .brz .brz-video-playlist .brz-video-playlist-main__placeholder .brz-video-playlist-main__content,
  .brz .brz-video-playlist .brz-video-playlist-main__video .brz-video-playlist-main__content {
    width: 100%; }
  .brz .brz-video-playlist .brz-video-playlist-sidebar {
    position: static;
    height: auto !important;
    width: 100% !important; }
  .brz .brz-video-playlist .brz-video-playlist-modal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; } }

.brz .brz-audio {
  pointer-events: auto;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content; }
  .brz .brz-audio .brz-custom-audio {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 3; }
    .brz .brz-audio .brz-custom-audio .brz-audio-play-pause-btn {
      cursor: pointer;
      z-index: 3; }
    .brz .brz-audio .brz-custom-audio .brz-audio-current-time {
      margin-left: 25px; }
    .brz .brz-audio .brz-custom-audio .brz-audio-total-time {
      margin-left: 15px; }
    .brz .brz-audio .brz-custom-audio .brz-audio-slider {
      position: relative;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      cursor: pointer; }
      .brz .brz-audio .brz-custom-audio .brz-audio-slider .brz-audio-progress {
        border-radius: inherit;
        position: absolute;
        pointer-events: none; }
      .brz .brz-audio .brz-custom-audio .brz-audio-slider:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        background-repeat: no-repeat;
        opacity: 0.35; }
    .brz .brz-audio .brz-custom-audio .brz-audio-controls {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      z-index: 3; }
      .brz .brz-audio .brz-custom-audio .brz-audio-controls .brz-audio-slider {
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 5px;
        height: 8px; }
        .brz .brz-audio .brz-custom-audio .brz-audio-controls .brz-audio-slider .brz-audio-progress {
          height: 100%; }
      .brz .brz-audio .brz-custom-audio .brz-audio-controls span {
        cursor: default; }
    .brz .brz-audio .brz-custom-audio .brz-audio-controls:empty {
      display: none; }
    .brz .brz-audio .brz-custom-audio .brz-audio-volume {
      position: relative;
      margin-left: 15px;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      z-index: 3; }
      .brz .brz-audio .brz-custom-audio .brz-audio-volume .brz-audio-volume-btn {
        cursor: pointer; }
      .brz .brz-audio .brz-custom-audio .brz-audio-volume .brz-audio-volume-controls {
        width: 60px;
        height: 8px;
        margin-left: 10px;
        border-radius: 5px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        .brz .brz-audio .brz-custom-audio .brz-audio-volume .brz-audio-volume-controls .brz-audio-slider {
          border-radius: 3px; }
          .brz .brz-audio .brz-custom-audio .brz-audio-volume .brz-audio-volume-controls .brz-audio-slider .brz-audio-progress {
            bottom: 0;
            height: 100%;
            width: 100%; }
  .brz .brz-audio .brz-audio__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border: none; }
    .brz .brz-audio .brz-audio__cover:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      background-repeat: no-repeat; }
    .brz .brz-audio .brz-audio__cover .brz-audio__cover-icon {
      position: relative;
      z-index: 1;
      color: #fff;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      border-radius: 50%;
      -webkit-transition: -webkit-transform 0.3s ease;
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.brz .brz-soundcloud {
  position: relative;
  font-size: 0; }
  .brz .brz-soundcloud:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    pointer-events: none; }
  .brz .brz-soundcloud .brz-soundCloud-content {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%; }
    .brz .brz-soundcloud .brz-soundCloud-content iframe {
      width: 100%; }
    .brz .brz-soundcloud .brz-soundCloud-content:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      pointer-events: none; }

.brz .brz-embed-code {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 20px;
  height: auto; }
  .brz .brz-embed-code:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    pointer-events: none;
    overflow: hidden; }
  .brz .brz-embed-code .brz-shortcode__placeholder {
    height: 45px; }
  .brz .brz-embed-code img {
    max-width: 100%; }
  .brz .brz-embed-code .brz-embed-content {
    width: 100%; }

.brz .brz-rich-text {
  -webkit-font-smoothing: antialiased;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  min-width: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word; }
  .brz .brz-rich-text .brz-ed-box__resizer {
    max-width: 100%; }
  .brz .brz-rich-text .brz-capitalize-on {
    text-transform: uppercase; }
    .brz .brz-rich-text .brz-capitalize-on span {
      text-transform: uppercase; }
  .brz .brz-rich-text > * {
    white-space: pre-wrap; }
  .brz .brz-rich-text i,
  .brz .brz-rich-text em {
    font-style: italic; }
  .brz .brz-rich-text ul li,
  .brz .brz-rich-text ol li {
    margin-left: 2em; }
  .brz .brz-rich-text ul {
    list-style-type: disc; }
  .brz .brz-rich-text ol {
    list-style-type: decimal; }
  .brz .brz-rich-text .ql-editor {
    white-space: pre-wrap; }
  .brz .brz-rich-text .brz-text-mask,
  .brz .brz-rich-text .brz-image-gradient {
    caret-color: #000;
    color: transparent !important;
    -webkit-background-clip: text;
    -webkit-box-decoration-break: clone;
    -webkit-text-fill-color: transparent;
    background-clip: text; }
  .brz .brz-rich-text > div > h1,
  .brz .brz-rich-text > div > h2,
  .brz .brz-rich-text > div > h3,
  .brz .brz-rich-text > div > h4,
  .brz .brz-rich-text > div > h5,
  .brz .brz-rich-text > div > h6 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    text-transform: initial;
    text-decoration: initial; }
    .brz .brz-rich-text > div > h1:before,
    .brz .brz-rich-text > div > h2:before,
    .brz .brz-rich-text > div > h3:before,
    .brz .brz-rich-text > div > h4:before,
    .brz .brz-rich-text > div > h5:before,
    .brz .brz-rich-text > div > h6:before {
      display: none; }
  .brz .brz-rich-text > div > p,
  .brz .brz-rich-text span {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: initial;
    text-decoration: initial; }
  .brz .brz-rich-text span {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit; }
  .brz .brz-rich-text p {
    display: block; }
  .brz .brz-rich-text a,
  .brz .brz-rich-text em,
  .brz .brz-rich-text span {
    display: inline; }
  .brz .brz-rich-text ol,
  .brz .brz-rich-text ul,
  .brz .brz-rich-text dl {
    margin: 0;
    padding: 0; }
  .brz .brz-rich-text ol ol,
  .brz .brz-rich-text ul ul,
  .brz .brz-rich-text ol ul,
  .brz .brz-rich-text ul ol {
    margin: 0; }
  .brz .brz-rich-text strong {
    font-weight: bold; }
  .brz .brz-rich-text .link--anchor,
  .brz .brz-rich-text .link--external,
  .brz .brz-rich-text .link--story,
  .brz .brz-rich-text .link--popup {
    text-decoration: none; }
    .brz .brz-rich-text .link--anchor:hover,
    .brz .brz-rich-text .link--external:hover,
    .brz .brz-rich-text .link--story:hover,
    .brz .brz-rich-text .link--popup:hover {
      text-decoration: underline; }
  .brz .brz-rich-text .brz-tp__dc-block:after {
    content: "";
    display: table;
    clear: both; }
  .brz .brz-rich-text .brz-tp__dc-block iframe,
  .brz .brz-rich-text .brz-tp__dc-block object,
  .brz .brz-rich-text .brz-tp__dc-block video,
  .brz .brz-rich-text .brz-tp__dc-block img {
    max-width: 100%; }

.brz .brz-rich-text__custom a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  .brz .brz-rich-text__custom a[href] :not(.brz-ed-content-dc-link):hover {
    text-decoration: underline; }

.brz .brz-rich-text__population-cloud h1,
.brz .brz-rich-text__population-cloud h2,
.brz .brz-rich-text__population-cloud h3,
.brz .brz-rich-text__population-cloud h4,
.brz .brz-rich-text__population-cloud h5,
.brz .brz-rich-text__population-cloud h6,
.brz .brz-rich-text__population-cloud p {
  margin: 0; }

.brz .brz-rich-text__population-cloud a {
  color: inherit;
  text-decoration: none; }
  .brz .brz-rich-text__population-cloud a:hover {
    text-decoration: underline; }

.brz .brz-rich-text__population-cloud blockquote {
  border-left: 4px solid #ccc;
  margin: 5px 0 5px;
  padding: 0 0 0 16px; }

.brz .brz-rich-text__population-cloud .ql-indent-1 {
  text-indent: 3em; }

.brz .brz-rich-text__population-cloud .ql-indent-2 {
  text-indent: 6em; }

.brz .brz-rich-text__population-cloud .ql-indent-3 {
  text-indent: 9em; }

.brz .brz-rich-text__population-cloud .ql-indent-4 {
  text-indent: 12em; }

.brz .brz-rich-text__population-cloud .ql-indent-5 {
  text-indent: 15em; }

.brz .brz-rich-text__population-cloud .ql-indent-6 {
  text-indent: 18em; }

.brz .brz-rich-text__population-cloud .ql-indent-7 {
  text-indent: 21em; }

.brz .brz-rich-text__population-cloud .ql-indent-8 {
  text-indent: 24em; }

.brz .brz-rich-text__population-cloud .ql-indent-9 {
  text-indent: 27em; }

.brz .brz-rich-text__population-cloud .ql-align-center {
  text-align: center; }

.brz .brz-rich-text__population-cloud .ql-align-right {
  text-align: right; }

.brz .brz-rich-text__population-cloud .ql-align-justify {
  text-align: justify; }

.brz .brz-text__editor {
  min-width: 10px; }
  .brz .brz-text__editor:empty:before {
    content: "\00a0\00a0"; }

.brz:not(.brz-ed) .brz-population-mask {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent !important; }

.brz:not(.brz-ed) .brz-story .brz-rich-text > div {
  width: 100%; }

.brz .brz-counter {
  position: relative; }
  .brz .brz-counter .brz-counter-figures {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .brz .brz-counter .brz-counter-figures span {
      word-break: normal;
      white-space: pre; }
  .brz .brz-counter-simple {
    width: auto !important; }
  .brz .brz-counter-radial .brz-counter-figures {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .brz .brz-counter-empty .brz-counter-figures {
    display: none; }
  .brz .brz-counter-pie .brz-counter-figures {
    display: none; }
  .brz .brz-counter .brz-ed-box__resizer {
    height: auto; }
  .brz .brz-counter .brz-counter-chart {
    text-align: center;
    margin: 0 auto; }
    .brz .brz-counter .brz-counter-chart svg {
      width: 100%;
      height: 100%;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      background: yellowgreen;
      border-radius: 50%; }
    .brz .brz-counter .brz-counter-chart.brz-counter-chart-radial svg {
      background: transparent; }
    .brz .brz-counter .brz-counter-chart.brz-counter-chart-radial .brz-counter-pie-chart {
      fill: transparent; }
    .brz .brz-counter .brz-counter-chart.brz-counter-chart-empty svg {
      background: transparent; }
    .brz .brz-counter .brz-counter-chart.brz-counter-chart-empty .brz-counter-pie-chart {
      fill: transparent; }
    .brz .brz-counter .brz-counter-chart.brz-counter-chart-empty .brz-counter-pie-chart {
      stroke-width: 32; }
    .brz .brz-counter .brz-counter-chart.brz-counter-chart-pie svg {
      background: transparent; }
    .brz .brz-counter .brz-counter-chart.brz-counter-chart-pie .brz-counter-pie-chart {
      stroke-width: 32; }

.brz .brz-progress-bar__wrapper {
  overflow: hidden; }

.brz .brz-progress-bar .brz-d-xs-flex {
  width: 100%; }

.brz .brz-progress-bar .brz-text__editor {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.brz .brz-progress-bar.brz-without-text .brz-progress-bar__percent {
  width: 100%;
  text-align: right; }

.brz .brz-progress-bar-style1::after,
.brz .brz-progress-bar-style2 .brz-progress-bar-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }

.brz .brz-progress-bar-style1,
.brz .brz-progress-bar-style2 .brz-progress-bar-overlay {
  position: relative;
  width: 100%; }

.brz .brz-progress-bar-style2 .brz-progress-bar__percent {
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  margin-bottom: 10px;
  text-align: center; }

.brz .brz-shortcode__placeholder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #ececec; }
  .brz .brz-shortcode__placeholder * {
    color: #c7c8ca !important; }
  .brz .brz-shortcode__placeholder .brz-icon-svg {
    width: calc(16px + (0.075 * 100%));
    height: calc(16px + (0.075 * 100%)); }

.brz .brz-soundcloud {
  position: relative;
  font-size: 0; }
  .brz .brz-soundcloud::before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    pointer-events: none; }
  .brz .brz-soundcloud .brz-soundCloud-content {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%; }
    .brz .brz-soundcloud .brz-soundCloud-content iframe {
      width: 100%; }
    .brz .brz-soundcloud .brz-soundCloud-content::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      pointer-events: none; }

.brz .brz-slick-slider .brz-rich-text *,
.brz .brz-slick-slider .brz-icon-svg {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.brz .brz-slick-slider .brz-bg-color {
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }

.brz .brz-slick-slider .slick-slide > div,
.brz .brz-slick-slider .brz-section__content {
  outline: none; }

.brz .brz-slick-slider--fade .slick-slide {
  height: auto;
  visibility: hidden; }
  .brz .brz-slick-slider--fade .slick-slide.slick-active {
    visibility: visible; }

.brz .slick-initialized .slick-slide {
  display: block; }

.brz .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  will-change: left; }

.brz .brz-slick-slider__dots {
  position: absolute;
  bottom: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 0;
  list-style: none;
  margin: 0;
  font-size: 0;
  text-align: center; }
  .brz .brz-slick-slider__dots li {
    display: inline-block;
    vertical-align: middle;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px; }
  .brz .brz-slick-slider__dots button {
    cursor: pointer;
    color: inherit !important;
    outline: none;
    overflow: hidden;
    padding: 0;
    text-indent: -9999px;
    border: 2px solid currentColor;
    border-radius: 0;
    background-color: transparent !important;
    -webkit-transition: background-color linear 0.2s, -webkit-transform linear 0.2s;
    transition: background-color linear 0.2s, -webkit-transform linear 0.2s;
    transition: transform linear 0.2s, background-color linear 0.2s;
    transition: transform linear 0.2s, background-color linear 0.2s, -webkit-transform linear 0.2s; }
  .brz .brz-slick-slider__dots .slick-active button {
    background-color: currentColor !important;
    -webkit-transition: background-color linear 0.2s, -webkit-transform linear 0.2s;
    transition: background-color linear 0.2s, -webkit-transform linear 0.2s;
    transition: transform linear 0.2s, background-color linear 0.2s;
    transition: transform linear 0.2s, background-color linear 0.2s, -webkit-transform linear 0.2s; }
  .brz .brz-slick-slider__dots--circle button {
    width: 10px;
    height: 10px;
    border-radius: 10px; }
  .brz .brz-slick-slider__dots--diamond button {
    width: 10px;
    height: 10px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .brz .brz-slick-slider__dots--square button {
    width: 10px;
    height: 10px; }

.brz .brz-slick-slider__arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 2;
  font-size: 30px;
  cursor: pointer;
  -webkit-transition: opacity linear 0.2s;
  transition: opacity linear 0.2s;
  color: #000;
  background-color: transparent;
  border: none;
  overflow: auto; }
  .brz .brz-slick-slider__arrow:hover {
    -webkit-transition: opacity linear 0.2s;
    transition: opacity linear 0.2s; }
  .brz .brz-slick-slider__arrow .brz-icon-svg {
    display: block; }
  .brz .brz-slick-slider__arrow-prev {
    -webkit-transform: translateY(-50%) rotate(-180deg);
            transform: translateY(-50%) rotate(-180deg);
    left: 30px; }
  .brz .brz-slick-slider__arrow-next {
    right: 30px; }

.brz .brz-forms {
  width: 100%; }
  .brz .brz-forms__wrap, .brz .brz-forms__fields {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .brz .brz-forms__wrap {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end; }
  .brz .brz-forms__item {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .brz .brz-forms .brz-input,
  .brz .brz-forms .brz-textarea {
    display: block;
    width: 100%;
    height: auto;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    line-height: inherit;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .brz .brz-forms .brz-input::-webkit-input-placeholder,
    .brz .brz-forms .brz-textarea::-webkit-input-placeholder {
      color: inherit; }
    .brz .brz-forms .brz-input::-moz-placeholder,
    .brz .brz-forms .brz-textarea::-moz-placeholder {
      color: inherit; }
    .brz .brz-forms .brz-input::-ms-input-placeholder,
    .brz .brz-forms .brz-textarea::-ms-input-placeholder {
      color: inherit; }
    .brz .brz-forms .brz-input::placeholder,
    .brz .brz-forms .brz-textarea::placeholder {
      color: inherit; }
  .brz .brz-forms .brz-textarea {
    height: 100%;
    resize: none; }
  .brz .brz-forms .brz-label {
    display: block; }
  .brz .brz-forms .brz-forms__field {
    padding: 14px 24px;
    text-align: left; }
  .brz .brz-forms .brz-forms__field-label {
    border: none;
    cursor: auto; }
  .brz .brz-forms__select {
    position: relative; }
  .brz .brz-forms__select-current {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .brz .brz-forms__select-current .brz-span {
      width: 100%;
      cursor: pointer; }
  .brz .brz-forms__select-list {
    border: 1px solid #dcdee1;
    border-top-width: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1041;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff; }
  .brz .brz-forms__select-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    border: 0;
    height: 52px; }
    .brz .brz-forms__select-item:not(:last-child) {
      border-bottom: 1px solid #f1f1f2; }
    .brz .brz-forms__select-item__input {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto;
      font-size: 16px;
      color: rgba(115, 119, 127, 0.7);
      line-height: 1;
      font-family: inherit;
      font-weight: inherit;
      padding: 14px 24px; }
      .brz .brz-forms__select-item__input .brz-input {
        border-width: 0;
        height: 100%;
        width: 100%;
        color: inherit; }
        .brz .brz-forms__select-item__input .brz-input::-webkit-input-placeholder {
          color: rgba(115, 119, 127, 0.7);
          font-style: italic; }
        .brz .brz-forms__select-item__input .brz-input:-moz-placeholder {
          color: rgba(115, 119, 127, 0.7);
          font-style: italic; }
        .brz .brz-forms__select-item__input .brz-input::-moz-placeholder {
          color: rgba(115, 119, 127, 0.7);
          font-style: italic; }
        .brz .brz-forms__select-item__input .brz-input:-ms-input-placeholder {
          color: rgba(115, 119, 127, 0.7);
          font-style: italic; }
        .brz .brz-forms__select-item__input .brz-input.brz-input {
          cursor: text; }
    .brz .brz-forms__select-item__icon {
      color: rgba(115, 119, 127, 0.7);
      -ms-flex-preferred-size: 52px;
          flex-basis: 52px;
      text-align: center;
      font-size: 13px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      cursor: pointer; }
  .brz .brz-forms__select--arrow {
    font-size: 14px; }
  .brz .brz-forms .brz-control__select {
    position: relative; }
  .brz .brz-forms .brz-control__select-current,
  .brz .brz-forms .brz-control__select-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-size: inherit;
    color: inherit; }
  .brz .brz-forms .brz-control__select-current {
    padding: 14px 24px;
    cursor: pointer; }
    .brz .brz-forms .brz-control__select-current .brz-control__select-option {
      border: 0;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
      height: 100%; }
    .brz .brz-forms .brz-control__select-current .brz-control__select--arrow {
      font-size: 14px; }
  .brz .brz-forms .brz-control__select-options {
    border: 1px solid #dcdee1;
    border-top-width: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1030;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    display: none; }
    .brz .brz-forms .brz-control__select-options .brz-control__select-option {
      padding: 14px 24px;
      font-size: 16px;
      height: 52px;
      line-height: 1.5;
      cursor: pointer; }
      .brz .brz-forms .brz-control__select-options .brz-control__select-option:not(:last-child) {
        border-bottom: 1px solid #f1f1f2; }
  .brz .brz-forms__alert {
    min-height: 55px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 2.5px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 17px 30px;
    color: #fff;
    margin-top: 15px; }
    .brz .brz-forms__alert--success {
      background-color: #15d053; }
    .brz .brz-forms__alert--error {
      background-color: #f00; }
  .brz .brz-forms__item--error .brz-forms__field,
  .brz .brz-forms__item--error .brz-control__select-current {
    border-color: #f00 !important;
    border-style: solid !important; }

@media (max-width: 767px) {
  .brz .brz-forms__select-item__input {
    font-size: 14px;
    padding: 10px 16px; }
  .brz .brz-forms .brz-forms__field {
    padding: 10px 16px; }
  .brz .brz-forms .brz-control__select-current {
    padding: 10px 16px; }
  .brz .brz-forms .brz-control__select-options .brz-control__select-option {
    padding: 10px 16px;
    font-size: 14px; } }

.brz .brz-forms2 {
  width: 100%; }
  .brz .brz-forms2 .brz-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .brz .brz-forms2 .brz-form-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .brz .brz-forms2__wrap, .brz .brz-forms2__fields {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .brz .brz-forms2__field {
    outline: none; }
  .brz .brz-forms2__wrap {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end; }
  .brz .brz-forms2__item {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .brz .brz-forms2 .brz-input,
  .brz .brz-forms2 .brz-textarea {
    display: block;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-height: auto;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    line-height: inherit;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .brz .brz-forms2 .brz-input::-webkit-input-placeholder,
    .brz .brz-forms2 .brz-textarea::-webkit-input-placeholder {
      color: inherit;
      opacity: 0.5; }
    .brz .brz-forms2 .brz-input::-moz-placeholder,
    .brz .brz-forms2 .brz-textarea::-moz-placeholder {
      color: inherit;
      opacity: 0.5; }
    .brz .brz-forms2 .brz-input::-ms-input-placeholder,
    .brz .brz-forms2 .brz-textarea::-ms-input-placeholder {
      color: inherit;
      opacity: 0.5; }
    .brz .brz-forms2 .brz-input::placeholder,
    .brz .brz-forms2 .brz-textarea::placeholder {
      color: inherit;
      opacity: 0.5; }
  .brz .brz-forms2 .brz-textarea {
    height: 100%;
    resize: none; }
  .brz .brz-forms2 .brz-label {
    display: block; }
  .brz .brz-forms2 .brz-forms2__field {
    text-align: left; }
  .brz .brz-forms2 .brz-forms2__field-label {
    border: none;
    cursor: auto; }
  .brz .brz-forms2__radio.brz-forms2__field {
    padding: 0;
    border: none !important;
    margin-bottom: -20px; }
  .brz .brz-forms2__radio-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 1.2em; }
    .brz .brz-forms2__radio-option .brz-span {
      margin-left: 10px;
      margin-right: auto; }
    .brz .brz-forms2__radio-option input.brz-input {
      cursor: auto; }
  .brz .brz-forms2__checkbox-options, .brz .brz-forms2__radio-options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .brz .brz-forms2 .brz-control__check-group-icon,
  .brz .brz-forms2 .brz-control__radio-check {
    -ms-flex-preferred-size: 1em;
        flex-basis: 1em; }
  .brz .brz-forms2 .brz-forms2__checkbox-option-name,
  .brz .brz-forms2 .brz-forms2__radio-option-name {
    -ms-flex-preferred-size: calc(100% - 1em - 10px);
        flex-basis: calc(100% - 1em - 10px); }
  .brz .brz-forms2__checkbox.brz-forms2__field {
    padding: 0;
    border: none !important;
    margin-bottom: -20px; }
  .brz .brz-forms2__checkbox-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 1.2em; }
    .brz .brz-forms2__checkbox-option .brz-forms2__checkbox-option-name {
      margin-left: 10px;
      margin-right: auto; }
    .brz .brz-forms2__checkbox-option input.brz-input {
      cursor: auto; }
    .brz .brz-forms2__checkbox-option .brz-control__radio-check {
      border-radius: 0; }
  .brz .brz-forms2__field-select,
  .brz .brz-forms2 .brz-forms2__field-fileUpload {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: stretch;
        -ms-flex-pack: stretch;
            justify-content: stretch; }
  .brz .brz-forms2__select-current,
  .brz .brz-forms2 .brz-forms2__field-fileUpload .brz-label {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
  .brz .brz-forms2__item-button .brz-btn {
    outline: none;
    width: 100%;
    position: relative; }
  .brz .brz-forms2__item-button .brz-icon-svg {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0; }
  .brz .brz-forms2__select-list .brz-forms2__select-item:not(:last-child) {
    border-bottom-width: 0; }
  .brz .brz-forms2__alert {
    min-height: 55px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 2.5px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 17px 30px;
    color: #fff;
    margin-top: 15px; }
    .brz .brz-forms2__alert--success {
      background-color: #15d053; }
    .brz .brz-forms2__alert--error {
      background-color: #f00; }
  .brz .brz-forms2__item--error .brz-control__check-group,
  .brz .brz-forms2__item--error .brz-control__radio {
    color: #f00 !important; }

.brz.brz:not(.brz-ed) .brz-control__check-group .brz-label {
  font: inherit;
  display: inherit;
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit;
  width: 100%;
  cursor: pointer; }

.brz.brz:not(.brz-ed) .brz-control__check-group--check {
  display: none; }

.brz.brz:not(.brz-ed) .brz-control__check-group .brz-input {
  display: none; }
  .brz.brz:not(.brz-ed) .brz-control__check-group .brz-input:checked ~ .brz-label .brz-control__check-group--check {
    display: inline-block; }
  .brz.brz:not(.brz-ed) .brz-control__check-group .brz-input:checked ~ .brz-label .brz-control__check-group--uncheck {
    display: none; }

.brz.brz:not(.brz-ed) .brz-control__radio .brz-label {
  font: inherit;
  display: inherit;
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  width: 100%;
  cursor: pointer; }

.brz.brz:not(.brz-ed) .brz-control__radio--check {
  display: none; }

.brz.brz:not(.brz-ed) .brz-control__radio .brz-input {
  display: none; }
  .brz.brz:not(.brz-ed) .brz-control__radio .brz-input:checked ~ .brz-label .brz-control__radio--check {
    display: inline-block; }
  .brz.brz:not(.brz-ed) .brz-control__radio .brz-input:checked ~ .brz-label .brz-control__radio--uncheck {
    display: none; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select {
  padding: 0; }

.brz.brz:not(.brz-ed) .brz-select {
  width: 100%;
  visibility: hidden; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-dropdown {
  background-color: #fff;
  border: none;
  border-radius: 0; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-results__option {
  margin: 0;
  padding: 12px 24px;
  font-size: inherit;
  line-height: inherit;
  height: auto;
  cursor: pointer;
  background-color: inherit; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-results__option:not(:last-child) {
  border-bottom-width: 0; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2 .selection, .brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-container, .brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-selection {
  outline: none; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-selection--multiple, .brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-selection--single {
  border: none;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }
  .brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-selection--multiple .select2-selection__rendered, .brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-selection--single .select2-selection__rendered {
    color: inherit;
    line-height: inherit;
    padding: 0;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-selection--multiple .select2-selection__rendered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -2.5px;
  font-family: inherit; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-selection--multiple .select2-selection__choice {
  margin: 2.5px;
  float: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: none;
  padding: 0 11px; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-selection--multiple .select2-selection__choice__remove {
  display: block;
  font-size: inherit;
  font-weight: normal;
  color: inherit;
  margin-right: 0;
  margin-left: 8px; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-selection__arrow {
  top: 50%;
  right: 18px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-selection__clear {
  margin-right: 14px; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-search__field {
  font: inherit;
  color: inherit;
  margin: 0;
  letter-spacing: inherit; }
  .brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-search__field::-webkit-input-placeholder {
    color: inherit; }
  .brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-search__field::-moz-placeholder {
    color: inherit; }
  .brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-search__field::-ms-input-placeholder {
    color: inherit; }
  .brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-search__field::placeholder {
    color: inherit; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-container .select2-search--inline {
  margin: 2.5px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  float: none; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-container--default .select2-results > .select2-results__options {
  max-height: initial; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: inherit;
  color: inherit; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: inherit; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-container--default .select2-selection--single .select2-selection__placeholder:empty:before {
  content: "Fake content";
  visibility: hidden; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: currentColor transparent transparent; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-container--default.select2-container--open .select2-selection--single
.select2-selection__arrow
b {
  border-color: transparent transparent currentColor; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-container--open .select2-dropdown {
  top: 1px;
  max-height: 200px;
  overflow: hidden; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .select2-dropdown--below {
  background-color: transparent; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps__thumb-y {
  background-color: rgba(0, 0, 0, 0.2); }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps .ps__rail-x:hover,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps .ps__rail-y:hover,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps .ps__rail-x:focus,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps .ps__rail-y:focus,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps .ps__rail-x.ps--clicking,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps .ps__rail-y.ps--clicking {
  background-color: transparent; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps__rail-y:hover > .ps__thumb-y,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps__rail-y:focus > .ps__thumb-y,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps__rail-y.ps--clicking .ps__thumb-y {
  width: 6px; }

.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps__rail-x,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps__rail-y,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps:hover > .ps__rail-x,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps:hover > .ps__rail-y,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps--focus > .ps__rail-x,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps--focus > .ps__rail-y,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps--scrolling-x > .ps__rail-x,
.brz.brz:not(.brz-ed) .brz-forms2__field-select .ps--scrolling-y > .ps__rail-y {
  opacity: 1; }

.brz.brz:not(.brz-ed) .flatpickr-calendar {
  font-family: "Lato", sans-serif;
  width: 306px; }

.brz.brz:not(.brz-ed) .flatpickr-calendar.hasTime {
  width: 150px; }

.brz.brz:not(.brz-ed) .flatpickr-calendar.open {
  max-height: 600px; }

.brz.brz:not(.brz-ed) .flatpickr-calendar .dayContainer {
  width: 286px;
  min-width: 286px;
  max-width: 286px; }

.brz.brz:not(.brz-ed) .flatpickr-calendar .numInputWrapper:hover {
  background-color: transparent; }

.brz.brz:not(.brz-ed) .flatpickr-months {
  padding: 10px 10px 0; }

.brz.brz:not(.brz-ed) .flatpickr-innerContainer {
  padding: 0 10px 10px; }

.brz.brz:not(.brz-ed) .flatpickr-next-month, .brz.brz:not(.brz-ed) .flatpickr-prev-month {
  top: 7px; }

.brz.brz:not(.brz-ed) .flatpickr-next-month {
  right: 10px; }

.brz.brz:not(.brz-ed) .flatpickr-prev-month {
  left: 10px; }

.brz.brz:not(.brz-ed) .flatpickr-months .flatpickr-prev-month,
.brz.brz:not(.brz-ed) .flatpickr-months .flatpickr-next-month {
  color: rgba(0, 0, 0, 0.5); }

.brz.brz:not(.brz-ed) .flatpickr-months .flatpickr-prev-month:hover,
.brz.brz:not(.brz-ed) .flatpickr-months .flatpickr-next-month:hover {
  color: black; }

.brz.brz:not(.brz-ed) .flatpickr-months .flatpickr-prev-month svg,
.brz.brz:not(.brz-ed) .flatpickr-months .flatpickr-next-month svg {
  fill: currentColor; }

.brz.brz:not(.brz-ed) .flatpickr-current-month input.cur-year {
  font-size: 17px;
  font-weight: 700; }

.brz.brz:not(.brz-ed) .flatpickr-current-month .numInputWrapper:hover,
.brz.brz:not(.brz-ed) .flatpickr-current-month span.cur-month:hover {
  background-color: transparent; }

.brz.brz:not(.brz-ed) .flatpickr-weekdays {
  padding: 20px 0 15px;
  height: auto; }

.brz.brz:not(.brz-ed) .flatpickr-weekday {
  color: rgba(0, 0, 0, 0.8); }

.brz.brz:not(.brz-ed) .flatpickr-days {
  width: 286px;
  font-size: 13px;
  font-weight: 700; }

.brz.brz:not(.brz-ed) .flatpickr-day {
  height: 36px;
  max-width: 36px;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.brz.brz:not(.brz-ed) .flatpickr-day.today {
  border-color: #a9a9a9; }

.brz.brz:not(.brz-ed) .flatpickr-day:hover, .brz.brz:not(.brz-ed) .flatpickr-day:focus {
  color: #fff;
  border-color: #a9a9a9;
  background-color: #a9a9a9; }

.brz.brz:not(.brz-ed) .flatpickr-day.selected {
  border-color: rgba(0, 0, 0, 0.9);
  background-color: rgba(0, 0, 0, 0.9); }

.brz.brz:not(.brz-ed) .flatpickr-time input {
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  text-indent: 12px; }

.brz.brz:not(.brz-ed) .flatpickr-time input:hover,
.brz.brz:not(.brz-ed) .flatpickr-time input:focus,
.brz.brz:not(.brz-ed) .flatpickr-time .flatpickr-am-pm:hover,
.brz.brz:not(.brz-ed) .flatpickr-time .flatpickr-am-pm:focus {
  background-color: transparent; }

.brz.brz:not(.brz-ed) .flatpickr-am-pm {
  font-size: 14px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.7);
  width: 32.66%; }

.brz.brz:not(.brz-ed) .flatpickr-am-pm:hover, .brz.brz:not(.brz-ed) .flatpickr-am-pm:focus {
  color: black; }

.brz.brz:not(.brz-ed) .flatpickr-calendar.hasTime .numInputWrapper {
  width: 32.66%; }

.brz.brz:not(.brz-ed) .flatpickr-calendar.hasTime .numInputWrapper span.arrowUp,
.brz.brz:not(.brz-ed) .flatpickr-calendar.hasTime .numInputWrapper span.arrowDown {
  height: 10px;
  top: 50%; }

.brz.brz:not(.brz-ed) .flatpickr-calendar.hasTime .numInputWrapper span.arrowUp {
  margin-top: -10px; }

.brz.brz:not(.brz-ed) .flatpickr-calendar.hasTime .numInputWrapper span.arrowDown {
  margin-bottom: -10px; }

.brz.brz:not(.brz-ed) .brz-g-recaptcha {
  font-size: initial; }

.brz .brz-input__upload {
  position: relative; }
  .brz .brz-input__upload .brz-label {
    font: inherit;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-right: 1.5em;
    cursor: pointer; }
    .brz .brz-input__upload .brz-label .brz-span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .brz .brz-input__upload .brz-label .brz-span:empty:before {
      content: "Hidden Placeholder";
      visibility: hidden; }
  .brz .brz-input__upload .brz-input__upload-delete {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 24px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 1.5em; }
  .brz .brz-input__upload .brz-input:not(.brz-input__upload-placeholder) {
    display: none; }
  .brz .brz-input__upload .brz-button {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    line-height: 1;
    padding: 0.5em;
    position: absolute;
    top: 50%;
    right: 24px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    outline: none !important; }

@media (max-width: 767px) {
  .brz .brz-forms2__select-item__input {
    font-size: 14px;
    padding: 10px 16px; }
  .brz .brz-forms2 .brz-control__select-current {
    padding: 10px 16px; }
  .brz .brz-forms2 .brz-control__select-options .brz-control__select-option {
    padding: 10px 16px;
    font-size: 14px; } }

.brz .brz-story .brz-form {
  width: 100%;
  margin: 0; }
  .brz .brz-story .brz-form .brz-g-recaptcha .grecaptcha-badge {
    position: initial !important;
    margin-top: 10px; }
  .brz .brz-story .brz-form .brz-forms2__item-button {
    padding-bottom: 0; }
    .brz .brz-story .brz-form .brz-forms2__item-button .brz-btn {
      width: 100%; }

.brz .brz-tabs {
  min-width: 0; }
  .brz .brz-tabs--horizontal {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
  .brz .brz-tabs--vertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%; }
  .brz .brz-tabs--vertical.brz-tabs--right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; }
  .brz .brz-tabs__nav {
    overflow: hidden;
    margin: 0;
    padding: 0;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content; }
    .brz .brz-tabs__nav--horizontal {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    .brz .brz-tabs__nav--button {
      padding: 10px 15px;
      cursor: pointer;
      border-style: solid;
      background-clip: padding-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
  .brz .brz-tabs__nav--item {
    position: relative;
    list-style: none;
    margin: 0;
    line-height: unset;
    z-index: var(--zIndex); }
  .brz .brz-tabs .brz-tabs__nav--style-1 .brz-tabs__nav--active:before, .brz .brz-tabs .brz-tabs__nav--style-1 .brz-tabs__nav--active:after {
    content: "";
    position: absolute; }
  .brz .brz-tabs__nav--vertical.brz-tabs__nav--left .brz-tabs__nav--active:before, .brz .brz-tabs__nav--vertical.brz-tabs__nav--left .brz-tabs__nav--active:after {
    height: 100vh;
    right: 0; }
  .brz .brz-tabs__nav--vertical.brz-tabs__nav--left .brz-tabs__nav--active:before {
    bottom: 100%; }
  .brz .brz-tabs__nav--vertical.brz-tabs__nav--right .brz-tabs__nav--active:before, .brz .brz-tabs__nav--vertical.brz-tabs__nav--right .brz-tabs__nav--active:after {
    height: 100vh;
    left: 0; }
  .brz .brz-tabs__nav--vertical.brz-tabs__nav--right .brz-tabs__nav--active:before {
    bottom: 100%; }
  .brz .brz-tabs__nav--horizontal .brz-tabs__nav--active:before, .brz .brz-tabs__nav--horizontal .brz-tabs__nav--active:after {
    width: 100vw;
    height: 2px;
    bottom: 0; }
  .brz .brz-tabs__nav--horizontal .brz-tabs__nav--active:before {
    left: -100vw; }
  .brz .brz-tabs__nav--horizontal .brz-tabs__nav--active:after {
    right: -100vw; }
  .brz .brz-tabs__nav--mobile {
    display: none; }
  .brz .brz-tabs__items {
    display: none;
    border-style: solid;
    background-clip: padding-box;
    width: 100%; }
  .brz .brz-tabs__items--active {
    display: block; }
    .brz .brz-tabs__items--active > .brz-tabs__item--content {
      -webkit-animation-name: fadeIn;
              animation-name: fadeIn;
      -webkit-animation-duration: 0.15s;
              animation-duration: 0.15s;
      -webkit-animation-fill-mode: both;
              animation-fill-mode: both; }
    .brz .brz-tabs__items--active .brz-bg-content:empty {
      min-height: 50px; }
  .brz .brz-tabs__content--vertical {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
  .brz .brz-tabs__content--vertical .brz-tabs__items {
    height: 100%; }
  .brz .brz-tabs__content--vertical.brz-tabs__content--style-1.brz-tabs__content--left .brz-tabs__items.brz-tabs__items--active,
  .brz .brz-tabs__content--vertical.brz-tabs__content--style-1.brz-tabs__content--left .brz-tabs__items.brz-tabs__items--active:hover {
    border-left-style: none; }
  .brz .brz-tabs__content--vertical.brz-tabs__content--right.brz-tabs__content--style-1 .brz-tabs__items.brz-tabs__items--active,
  .brz .brz-tabs__content--vertical.brz-tabs__content--right.brz-tabs__content--style-1 .brz-tabs__items.brz-tabs__items--active:hover {
    border-right-style: none; }
  .brz .brz-tabs__content--horizontal.brz-tabs__content--style-1 .brz-tabs__items.brz-tabs__items--active,
  .brz .brz-tabs__content--horizontal.brz-tabs__content--style-1 .brz-tabs__items.brz-tabs__items--active:hover {
    border-top-style: none; }

@media (max-width: 991px) {
  .brz .brz-tabs__nav {
    margin: 0 !important; }
  .brz .brz-tabs__nav--mobile {
    display: block; }
    .brz .brz-tabs__nav--mobile > .brz-tabs__nav--button {
      border-width: 0 !important;
      -webkit-box-shadow: none !important;
              box-shadow: none !important;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
  .brz .brz-tabs__nav--desktop {
    display: none; }
  .brz .brz-tabs__items {
    display: block; }
    .brz .brz-tabs__items > .brz-tabs__item--content {
      display: none; }
    .brz .brz-tabs__items:not(:last-child) {
      margin-bottom: 3px; }
  .brz .brz-tabs__items--active > .brz-tabs__nav--button {
    border-bottom: solid; }
  .brz .brz-tabs__items--active > .brz-tabs__item--content {
    display: block; }
  .brz .brz-tabs__content--vertical .brz-tabs__items {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  .brz .brz-tabs__content .brz-tabs__items--active {
    border-top-style: solid; }
  .brz .brz-tabs__content.brz-tabs__content--vertical.brz-tabs__content--left .brz-tabs__items.brz-tabs__items--active,
  .brz .brz-tabs__content.brz-tabs__content--vertical.brz-tabs__content--left .brz-tabs__items.brz-tabs__items--active:hover {
    border-left-style: solid; }
  .brz .brz-tabs__content.brz-tabs__content--vertical.brz-tabs__content--right .brz-tabs__items.brz-tabs__items--active,
  .brz .brz-tabs__content.brz-tabs__content--vertical.brz-tabs__content--right .brz-tabs__items.brz-tabs__items--active:hover {
    border-right-style: solid; }
  .brz .brz-tabs__content.brz-tabs__content--horizontal.brz-tabs__content--style-1 .brz-tabs__items.brz-tabs__items--active,
  .brz .brz-tabs__content.brz-tabs__content--horizontal.brz-tabs__content--style-1 .brz-tabs__items.brz-tabs__items--active:hover {
    border-top-style: solid; }
  .brz .brz-tabs__nav--horizontal.brz-tabs__nav.brz-tabs__nav--style-2 {
    display: none; } }

.brz .brz-tabs__nav.brz-tabs__nav--style-3 .brz-tabs__nav--button:before {
  content: ""; }

.brz .brz-tabs__nav.brz-tabs__nav--style-3 .brz-tabs__nav--button {
  border: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.brz .brz-tabs__content--style-3 {
  margin-top: 1px; }

.brz .brz-tabs__content--style-3 .brz-tabs__items {
  border: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.brz .brz-tabs__nav--style-3 .brz-tabs__nav--item:before {
  content: ""; }

.brz .brz-tabs__nav--style-3 .brz-tabs__nav--item:after {
  content: ""; }

.brz .brz-timeline__tab {
  position: relative;
  list-style: none;
  min-width: 280px; }
  .brz .brz-timeline__tab:after {
    content: "";
    position: absolute; }
  .brz .brz-timeline__tab:before {
    content: "";
    position: absolute; }
  .brz .brz-timeline__tab:last-child:after {
    display: none; }

.brz .brz-timeline__nav--title {
  position: relative;
  text-align: center;
  left: -10px;
  min-width: 116px;
  max-width: 116px;
  width: 116px; }

.brz .brz-timeline__nav--icon {
  margin-left: 20px; }

.brz .brz-timeline__content {
  border-style: solid;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 20px 0 rgba(52, 49, 75, 0.1);
          box-shadow: 0 0 20px 0 rgba(52, 49, 75, 0.1);
  position: relative; }

.brz .brz-timeline__content:before {
  content: "";
  position: absolute;
  top: 0;
  width: 15px;
  height: 15px;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg); }

.brz .brz-timeline__nav--mobile {
  display: none; }

.brz .brz-switcher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%; }
  .brz .brz-switcher__nav {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-duration: 300ms;
            transition-duration: 300ms;
    position: relative; }
    .brz .brz-switcher__nav:before {
      content: "";
      position: absolute;
      left: calc(50% - 2px);
      height: calc(100% - 4px);
      width: calc(50% - 2px);
      margin: 2px;
      -webkit-transition-duration: 300ms;
              transition-duration: 300ms; }
    .brz .brz-switcher__nav--item {
      width: 50%;
      height: 100%;
      max-width: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      white-space: nowrap; }
    .brz .brz-switcher__nav--button {
      cursor: pointer;
      background-clip: padding-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .brz .brz-switcher__nav--item--active {
      z-index: 1; }
    .brz .brz-switcher__nav--active:before {
      content: "";
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%); }
    .brz .brz-switcher__nav--text {
      position: relative; }
  .brz .brz-switcher__content--tab {
    display: none;
    width: 100%; }
    .brz .brz-switcher__content--tab--active {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }

.brz .brz-switcher__nav > .brz-switcher__nav--item > span {
  cursor: pointer; }

.brz .brz-switcher__nav2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .brz .brz-switcher__nav2--control {
    height: 47px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-duration: 300ms;
            transition-duration: 300ms; }
  .brz .brz-switcher__nav2--control:before {
    content: "";
    position: relative;
    left: calc(50% + 2px);
    margin: 2px;
    height: calc(100% - 4px);
    width: calc(50% - 5px);
    -webkit-transition-duration: 300ms;
            transition-duration: 300ms; }
  .brz .brz-switcher__nav2--control--active:before {
    content: "";
    -webkit-transform: translateX(calc(-100% - 8px));
            transform: translateX(calc(-100% - 8px)); }
  .brz .brz-switcher__nav2--button {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .brz .brz-switcher__nav2 .brz-switcher__nav2--button {
    margin: 0 20px; }

.brz .brz-switcher--style2 > .brz-switcher__nav2 > span {
  cursor: pointer; }

.brz .brz-table {
  display: table;
  table-layout: fixed;
  margin: 0; }
  .brz .brz-table .brz-table__tr:nth-child(even) .brz-table__td {
    font-size: initial;
    font-style: inherit;
    font-family: inherit; }
  .brz .brz-table .brz-table__tr:nth-child(2n + 1) .brz-table__td {
    font-size: initial;
    font-style: inherit;
    font-family: inherit; }
  .brz .brz-table__th--btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }

.brz .brz-table__th .brz-span {
  margin: auto 0; }

@media (max-width: 991px) {
  .brz .brz-table {
    display: block;
    overflow-x: auto;
    border-collapse: collapse;
    max-width: 100%; }
    .brz .brz-table .brz-rich-text {
      overflow-wrap: normal;
      word-break: normal;
      word-wrap: normal; } }

.brz .brz-accordion {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .brz .brz-accordion__nav {
    cursor: pointer;
    background-clip: padding-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .brz .brz-accordion__nav--icon, .brz .brz-accordion__nav--previewIcon, .brz .brz-accordion__nav--previewIcon--active {
      margin: auto 0; }
  .brz .brz-accordion__item {
    display: block;
    width: 100%; }
    .brz .brz-accordion__item > .brz-accordion__content {
      overflow: hidden;
      height: 0;
      will-change: height, transition-duration;
      background-clip: padding-box;
      border-top-color: transparent !important; }
    .brz .brz-accordion__item-content > * {
      -ms-flex-negative: 0;
          flex-shrink: 0; }
    .brz .brz-accordion__item > .brz-accordion__nav .brz-accordion__nav-title {
      outline: none;
      white-space: pre-wrap;
      overflow: hidden; }
    .brz .brz-accordion__item > .brz-accordion__nav > .brz-accordion--icon-wrapper
> .brz-accordion__nav--previewIcon--active {
      display: none; }
  .brz .brz-accordion__item--active > .brz-accordion__nav
> .brz-accordion--icon-wrapper
> .brz-accordion__nav--previewIcon--active {
    display: block; }
  .brz .brz-accordion__item--active > .brz-accordion__nav
> .brz-accordion--icon-wrapper
> .brz-accordion__nav--previewIcon {
    display: none; }
  .brz .brz-accordion__item--active > .brz-accordion__content {
    height: auto; }
  .brz .brz-accordion__item--active > .brz-accordion__content > .brz-accordion__item-content:empty {
    min-height: 50px; }

.brz .brz-accordion__filter-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.brz .brz-accordion__filter {
  width: 100%;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit;
  padding: 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.brz .brz-accordion__filter--style-2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.brz .brz-accordion__filter__item {
  cursor: pointer; }

.brz .brz-accordion__filter__item:first-child {
  margin-left: 0 !important; }

.brz .brz-accordion__filter__item:last-child {
  margin-right: 0 !important; }

.brz .brz-accordion__hidden {
  display: none; }

.brz .brz-image__gallery {
  width: 100%; }
  .brz .brz-image__gallery-wrapper .brz-image {
    display: block; }
  .brz .brz-image__gallery-item {
    -webkit-transition: none !important;
    transition: none !important; }

.brz .brz-image__gallery--filter-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.brz .brz-image__gallery-filter {
  width: 100%;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0; }

.brz .brz-image__gallery-filter--style-2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.brz .brz-image__gallery-filter__item {
  cursor: pointer; }

.brz .brz-image__gallery-filter__item:first-child {
  margin-left: 0 !important; }

.brz .brz-image__gallery-filter__item:last-child {
  margin-right: 0 !important; }

.brz .mfp-close {
  background-color: transparent !important; }

.brz .mfp-arrow:hover,
.brz .mfp-close:hover {
  background-color: transparent;
  border: 0; }

.brz .brz-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1060;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .brz .brz-popup__close {
    position: absolute;
    z-index: 2;
    top: 36px;
    left: 50px;
    color: #fff;
    font-size: 0;
    cursor: pointer; }
    .brz .brz-popup__close .brz-icon-svg {
      font-size: 26px; }
  .brz .brz-popup > .brz-container__wrap {
    max-height: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .brz .brz-popup .brz-container {
    height: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .brz .brz-popup .brz-container > .brz-row__container {
    max-height: 100%; }
  .brz .brz-popup .brz-container > .brz-row__container > .brz-row {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%; }

.brz .brz-popup__preview {
  z-index: -1;
  visibility: hidden;
  -webkit-transform: translate(-100%);
          transform: translate(-100%);
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) opacity, 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) visibility, 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) z-index;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) opacity, 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) visibility, 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) z-index; }
  .brz .brz-popup__preview.brz-popup--opened {
    z-index: 1070;
    visibility: visible;
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) opacity, 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) visibility, 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) z-index;
    transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) opacity, 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) visibility, 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) z-index; }

.brz .brz-popup2 {
  position: fixed;
  width: 100% !important;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1060;
  max-width: 100% !important; }
  .brz .brz-popup2__close {
    position: absolute;
    z-index: 2;
    color: #fff;
    font-size: 0;
    cursor: pointer; }
    .brz .brz-popup2__close .brz-icon-svg {
      font-size: 26px; }
  .brz .brz-popup2 .brz-container__wrap {
    position: relative;
    margin: 0; }
  .brz .brz-popup2 .brz-container__wrap .brz-container {
    height: 100%; }
  .brz .brz-popup2__inner {
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto; }
    .brz .brz-popup2__inner > .brz-container__wrap {
      max-height: 100%;
      margin: 0;
      padding: 0;
      min-width: 300px; }
  .brz .brz-popup2__vertical-align {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }

.brz .brz-popup2__preview {
  z-index: -1;
  visibility: hidden;
  -webkit-transform: translate(0, -110%);
          transform: translate(0, -110%);
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) opacity;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) opacity; }
  .brz .brz-popup2__preview.brz-popup2--opened {
    z-index: 1070;
    visibility: visible;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) opacity;
    transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) opacity; }

.brz .brz-carousel {
  display: block;
  width: 100%; }
  .brz .brz-carousel__slider:not(.slick-initialized) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .brz .brz-carousel > .slick-slider {
    overflow: hidden; }
    .brz .brz-carousel > .slick-slider .slick-slide {
      height: auto; }
    .brz .brz-carousel > .slick-slider > .slick-list > .slick-track > .slick-slide > div {
      height: 100%; }
      .brz .brz-carousel > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .brz-carousel__item,
      .brz .brz-carousel > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .brz-carousel__item > .brz-columns,
      .brz .brz-carousel > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .brz-columns {
        height: 100%; }
    .brz .brz-carousel > .slick-slider > .brz-slick-slider__arrow {
      opacity: 1; }
    .brz .brz-carousel > .slick-slider > .brz-slick-slider__dots {
      position: relative;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      bottom: 10px; }
    .brz .brz-carousel > .slick-slider .slick-slide {
      outline: none; }
    .brz .brz-carousel > .slick-slider .slick-track {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
  .brz .brz-carousel .brz-rich-text *,
  .brz .brz-carousel .brz-icon-svg {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }

@media (max-width: 767px) {
  .brz.brz:not(.brz-ed) .slick-slider * {
    min-width: 0;
    min-height: 0; } }

.brz .brz-posts {
  width: 100%; }
  .brz .brz-posts__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .brz .brz-posts__filter {
    width: 100%;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: inherit;
        -ms-flex-pack: inherit;
            justify-content: inherit;
    padding: 0;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .brz .brz-posts__filter-wrapper {
      width: 100%;
      grid-column: 1 / -1;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .brz .brz-posts__filter--style-2 {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
    .brz .brz-posts__filter__item {
      cursor: pointer; }
    .brz .brz-posts__filter__item:first-child {
      margin-left: 0 !important; }
    .brz .brz-posts__filter__item:last-child {
      margin-right: 0 !important; }
  .brz .brz-posts__item > .brz-columns {
    height: 100%; }

.brz .brz-posts__pagination ul.page-numbers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }

.brz .brz-posts__pagination ul.page-numbers > li {
  position: relative;
  margin-left: 4px;
  margin-right: 4px; }
  .brz .brz-posts__pagination ul.page-numbers > li:first-child {
    margin-left: 0; }
  .brz .brz-posts__pagination ul.page-numbers > li:last-child {
    margin-right: 0; }

.brz .brz-posts__pagination ul.page-numbers .page-numbers {
  position: relative;
  display: block;
  padding: 6px 10px;
  outline: none;
  color: inherit;
  font-size: 18px;
  line-height: 1;
  text-decoration: none;
  -webkit-transition: 0.2s linear color;
  transition: 0.2s linear color; }
  .brz .brz-posts__pagination ul.page-numbers .page-numbers:hover {
    -webkit-transition: 0.2s linear color;
    transition: 0.2s linear color; }

.brz .fb_iframe_widget {
  min-height: 20px; }

.brz .fb-page,
.brz .fb-page span,
.brz .fb-page span iframe[style],
.brz .fb-post,
.brz .fb-video,
.brz .fb-comments,
.brz .fb-comments span,
.brz .fb-comments span iframe[style] {
  width: 100% !important;
  min-width: 100% !important; }

.brz .brz-fb-page,
.brz .brz-fb-embed,
.brz .brz-fb-group {
  position: relative;
  width: 100%; }
  .brz .brz-fb-page::before,
  .brz .brz-fb-embed::before,
  .brz .brz-fb-group::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    pointer-events: none; }

.brz .brz-fb-group {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.brz .brz-fb-page,
.brz .brz-fb-comments {
  width: 100%; }

.brz .brz-fb-styles-button--small-button {
  height: 20px; }

.brz .brz-fb-styles-button--small-boxed {
  height: 64px; }

.brz .brz-wp__breadcrumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit;
  width: 100%; }
  .brz .brz-wp__breadcrumbs .brz-breadcrumbs {
    width: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    word-break: break-all; }
    .brz .brz-wp__breadcrumbs .brz-breadcrumbs li.brz-li {
      list-style: none;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin: 0; }
      .brz .brz-wp__breadcrumbs .brz-breadcrumbs li.brz-li .brz-a {
        cursor: pointer;
        -webkit-transition-property: color;
        transition-property: color; }
      .brz .brz-wp__breadcrumbs .brz-breadcrumbs li.brz-li .brz-icon-svg {
        margin-top: 1px; }
      .brz .brz-wp__breadcrumbs .brz-breadcrumbs li.brz-li:last-child .brz-a {
        margin-right: 0; }
    .brz .brz-wp__breadcrumbs .brz-breadcrumbs .brz-li:not(:last-child) .brz-a {
      color: inherit; }

.brz .brz-wp__postinfo {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit;
  width: 100%;
  padding: 0;
  margin: 0;
  word-break: break-all; }
  .brz .brz-wp__postinfo__column ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .brz .brz-wp__postinfo__column ul .brz-li.brz-li {
      margin-right: 0; }
      .brz .brz-wp__postinfo__column ul .brz-li.brz-li:first-child {
        margin-top: 0; }
  .brz .brz-wp__postinfo > ul {
    margin: 0;
    padding: 0; }
  .brz .brz-wp__postinfo .brz-li {
    margin-left: 0;
    margin-top: 0;
    list-style: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: default; }
    .brz .brz-wp__postinfo .brz-li:last-child {
      margin-right: 0 !important; }
    .brz .brz-wp__postinfo .brz-li .brz-icon-svg {
      margin-right: 0.5em; }
  .brz .brz-wp__postinfo__disabled-author ul .brz-li:first-child,
  .brz .brz-wp__postinfo__disabled-date ul .brz-li:nth-child(2),
  .brz .brz-wp__postinfo__disabled-time ul .brz-li:nth-child(3),
  .brz .brz-wp__postinfo__disabled-comments ul .brz-li:nth-child(4) {
    display: none; }

.brz .brz-single-post-navigation {
  position: relative;
  width: 100%; }
  .brz .brz-single-post-navigation .brz-span {
    display: block; }
  .brz .brz-single-post-navigation a:focus {
    outline: none; }

.brz .brz-navigation-title,
.brz .brz-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.brz .brz-navigation__prev:nth-child(2n) {
  text-align: end;
  text-decoration: none; }

.brz .brz-single-post-navigation::before {
  content: "";
  position: absolute;
  background: #a5aeb4;
  margin: auto;
  width: 1px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none; }

.brz .brz-navigation__next {
  text-decoration: none; }

.brz .brz-navigation-title__next,
.brz .brz-navigation__next {
  margin-left: auto;
  text-align: right; }

.brz .brz-navigation-title__prev,
.brz .brz-navigation__prev {
  padding-right: 50px; }

.brz .brz-navigation-title__next,
.brz .brz-navigation__next {
  padding-left: 50px; }

.brz .brz-navigation-title__next,
.brz .brz-navigation__next,
.brz .brz-navigation-title__prev,
.brz .brz-navigation__prev {
  max-width: 50%; }

.brz .brz-starrating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
  .brz .brz-starrating-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .brz .brz-starrating-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .brz .brz-starrating-color {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    max-width: 100%;
    overflow: hidden; }
  .brz .brz-starrating-icon-wrap {
    position: relative; }
  .brz .brz-starrating .brz-starrating-container .brz-icon-svg {
    top: 0.1em; }
    .brz .brz-starrating .brz-starrating-container .brz-icon-svg:last-child {
      margin-right: 0 !important; }
  .brz .brz-starrating-color-full {
    width: 100%; }
  .brz .brz-starrating-color-w1 {
    width: 10%; }
  .brz .brz-starrating-color-w2 {
    width: 20%; }
  .brz .brz-starrating-color-w3 {
    width: 30%; }
  .brz .brz-starrating-color-w4 {
    width: 40%; }
  .brz .brz-starrating-color-w5 {
    width: 50%; }
  .brz .brz-starrating-color-w6 {
    width: 60%; }
  .brz .brz-starrating-color-w7 {
    width: 70%; }
  .brz .brz-starrating-color-w8 {
    width: 80%; }
  .brz .brz-starrating-color-w9 {
    width: 90%; }
  .brz .brz-starrating .brz-starrating-style2-container {
    padding: 2px 9px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .brz .brz-starrating .brz-starrating-style2-container .brz-starrating-text {
      -ms-flex-item-align: center;
          align-self: center; }
    .brz .brz-starrating .brz-starrating-style2-container .brz-starrating-icon-wrap {
      -ms-flex-item-align: center;
          align-self: center; }

.brz .brz-lottie-anim {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.brz .brz-lottie__canvas {
  width: 100%; }

.brz .brz-search-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: visible; }
  .brz .brz-search-container--minimal {
    overflow: hidden; }
    .brz .brz-search-container--minimal .brz-search-form {
      padding: 0 15px; }
  .brz .brz-search-container .brz-ed-box__resizer {
    height: unset; }
  .brz .brz-search-container .brz-search-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .brz .brz-search-container .brz-btn {
    margin-left: auto;
    z-index: 1; }
  .brz .brz-search-container .brz-wrapper-clone__wrap {
    margin: 0; }
  .brz .brz-search-container .brz-search {
    background-color: transparent;
    width: 100%;
    padding: 0 15px;
    outline: none;
    border: 0;
    border-radius: 0; }
    .brz .brz-search-container .brz-search:-webkit-autofill {
      -webkit-transition: background-color 500000s ease !important;
      transition: background-color 500000s ease !important; }
    .brz .brz-search-container .brz-search::-webkit-input-placeholder {
      color: inherit; }
    .brz .brz-search-container .brz-search::-moz-placeholder {
      color: inherit; }
    .brz .brz-search-container .brz-search::-ms-input-placeholder {
      color: inherit; }
    .brz .brz-search-container .brz-search::placeholder {
      color: inherit; }

.brz .brz-protected {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .brz .brz-protected-form {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .brz .brz-protected-form__input {
      outline: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .brz .brz-protected-form__input .brz-input {
        width: 100%;
        border: none;
        outline: none;
        background-color: transparent; }
        .brz .brz-protected-form__input .brz-input::-webkit-input-placeholder {
          opacity: 0.5;
          color: inherit; }
        .brz .brz-protected-form__input .brz-input::-moz-placeholder {
          opacity: 0.5;
          color: inherit; }
        .brz .brz-protected-form__input .brz-input::-ms-input-placeholder {
          opacity: 0.5;
          color: inherit; }
        .brz .brz-protected-form__input .brz-input::placeholder {
          opacity: 0.5;
          color: inherit; }
    .brz .brz-protected-form__button {
      -webkit-box-flex: 1;
          -ms-flex: auto;
              flex: auto; }
      .brz .brz-protected-form__button .brz-btn {
        width: 100%; }
  .brz .brz-protected__alert {
    min-height: 55px;
    width: 100%;
    padding: 17px 30px;
    margin-top: 15px;
    font-family: "nunito", "Open Sans", Arial, sans-serif;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 2.5px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    background-color: #f00; }

.brz .brz-comments {
  width: 100%;
  padding: 0;
  margin: 0; }
  .brz .brz-comments .comment-form-rating > label {
    font-weight: 600; }
  .brz .brz-comments-parrent {
    margin-bottom: -20px; }
    .brz .brz-comments-parrent > div {
      width: 100% !important;
      float: none !important;
      padding: 0 !important; }
  .brz .brz-comments .comments-pagination {
    margin-bottom: 20px; }
    .brz .brz-comments .comments-pagination + .brz-comment-respond {
      margin-top: -10px; }
  .brz .brz-comments a {
    text-decoration: none;
    word-break: normal; }
  .brz .brz-comments .brz-comments {
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .brz .brz-comments .brz-comments + .brz-comment-respond {
      margin-top: -20px; }
    .brz .brz-comments .brz-comments .brz-comments-children {
      padding: 0; }
    .brz .brz-comments .brz-comments .brz-comments__name * {
      color: inherit;
      font-size: inherit; }
    .brz .brz-comments .brz-comments .brz-comments__right-side {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-right: 0;
      margin-left: auto;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    .brz .brz-comments .brz-comments .brz-comments__date {
      position: relative; }
      .brz .brz-comments .brz-comments .brz-comments__date * {
        color: inherit;
        font-size: inherit; }
    .brz .brz-comments .brz-comments .brz-comments__reply {
      line-height: inherit; }
      .brz .brz-comments .brz-comments .brz-comments__reply * {
        font-size: inherit; }
      .brz .brz-comments .brz-comments .brz-comments__reply .comment-reply-link {
        background-color: transparent;
        padding: 0; }
    .brz .brz-comments .brz-comments .brz-comments__text p {
      margin: 0; }
    .brz .brz-comments .brz-comments .brz-comments__text,
    .brz .brz-comments .brz-comments .brz-comment-awaiting-moderation {
      width: 100%;
      margin-bottom: 40px;
      position: relative; }
      .brz .brz-comments .brz-comments .brz-comments__text::before,
      .brz .brz-comments .brz-comments .brz-comment-awaiting-moderation::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        background-color: rgba(129, 138, 145, 0.15);
        display: block; }
      .brz .brz-comments .brz-comments .brz-comments__text *,
      .brz .brz-comments .brz-comments .brz-comment-awaiting-moderation * {
        margin: 10px 0;
        color: inherit;
        font-family: inherit;
        font-size: inherit; }
      .brz .brz-comments .brz-comments .brz-comments__text a,
      .brz .brz-comments .brz-comments .brz-comment-awaiting-moderation a {
        color: #3dbfe8; }
    .brz .brz-comments .brz-comments .brz-comments__text {
      padding-bottom: 30px; }
    .brz .brz-comments .brz-comments .brz-comment-awaiting-moderation {
      padding-bottom: 40px; }
  .brz .brz-comments-title {
    margin: 0 0 59px 0;
    color: #03080f;
    font-size: 23px;
    letter-spacing: -0.12px;
    color: #03080f; }
    .brz .brz-comments-title::before {
      display: none; }
  .brz .brz-comments .star-rating {
    margin: 0; }
  .brz .brz-comments__skin-skin1.review .brz-comments__date::before {
    content: "";
    width: 1px;
    height: 77%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #818a91;
    display: block;
    margin: auto; }
  .brz .brz-comments__skin-skin1 .brz-comments__logo .brz-img {
    border-radius: 50%;
    -o-object-fit: cover;
       object-fit: cover; }
  .brz .brz-comments__skin-skin1 .brz-comments__right-date {
    margin-top: -2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .brz .brz-comments__skin-skin1 .brz-comments__right-date .brz-comments__name {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    .brz .brz-comments__skin-skin1 .brz-comments__right-date .brz-comments__date {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      margin-left: auto;
      text-align: right;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding-left: 10px;
      margin-left: 10px; }
      .brz .brz-comments__skin-skin1 .brz-comments__right-date .brz-comments__date * .brz-span {
        position: relative;
        right: -2px; }
    .brz .brz-comments__skin-skin1 .brz-comments__right-date .brz-comments__reply {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      padding-left: 10px;
      margin-left: 10px;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .brz .brz-comments__skin-skin1 .brz-comments__right-date .brz-comments__reply::before {
        content: "";
        width: 1px;
        height: 77%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: #818a91;
        display: block;
        margin: auto; }
    .brz .brz-comments__skin-skin1 .brz-comments__right-date .brz-comment-awaiting-moderation {
      margin-top: 7px !important; }
    .brz .brz-comments__skin-skin1 .brz-comments__right-date .brz-comments__text,
    .brz .brz-comments__skin-skin1 .brz-comments__right-date .brz-comment-awaiting-moderation {
      width: 100%;
      margin-top: 3px; }
      .brz .brz-comments__skin-skin1 .brz-comments__right-date .brz-comments__text::before,
      .brz .brz-comments__skin-skin1 .brz-comments__right-date .brz-comment-awaiting-moderation::before {
        width: 100%; }
  .brz .brz-comments__skin-skin2 .star-rating {
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .brz .brz-comments__skin-skin2 .brz-comments__logo-img {
    width: 67px;
    height: 67px;
    border-radius: 50%;
    -o-object-fit: cover;
       object-fit: cover; }
  .brz .brz-comments__skin-skin2 .brz-comments__right-date {
    margin-top: 8px;
    margin-bottom: 33px;
    padding-bottom: 45px;
    width: calc(100% - 97px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative; }
    .brz .brz-comments__skin-skin2 .brz-comments__right-date::before {
      content: "";
      width: 100%;
      height: 2px;
      background-color: rgba(129, 138, 145, 0.15);
      position: absolute;
      bottom: 0;
      left: 0; }
    .brz .brz-comments__skin-skin2 .brz-comments__right-date .brz-comments__name-date {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      .brz .brz-comments__skin-skin2 .brz-comments__right-date .brz-comments__name-date .brz-comments__name {
        margin-bottom: 6px; }
      .brz .brz-comments__skin-skin2 .brz-comments__right-date .brz-comments__name-date .brz-comments__date {
        width: 100%;
        margin-bottom: 7px; }
        .brz .brz-comments__skin-skin2 .brz-comments__right-date .brz-comments__name-date .brz-comments__date * {
          width: 75px;
          text-align: right;
          margin: auto; }
          .brz .brz-comments__skin-skin2 .brz-comments__right-date .brz-comments__name-date .brz-comments__date * .brz-span {
            position: relative;
            right: -2px; }
    .brz .brz-comments__skin-skin2 .brz-comments__right-date .brz-comments__text,
    .brz .brz-comments__skin-skin2 .brz-comments__right-date .brz-comment-awaiting-moderation {
      margin-bottom: 0 !important;
      padding-left: 20px;
      padding-bottom: 0 !important; }
    .brz .brz-comments__skin-skin2 .brz-comments__right-date .brz-comments__text {
      margin-top: -2px;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
    .brz .brz-comments__skin-skin2 .brz-comments__right-date .brz-comment-awaiting-moderation {
      margin-top: -2px; }
    .brz .brz-comments__skin-skin2 .brz-comments__right-date .brz-comments__reply {
      padding-left: 30px;
      margin-top: 1px;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
  .brz .brz-comments__skin-skin3 .brz-comments__logo {
    margin-right: 25px; }
    .brz .brz-comments__skin-skin3 .brz-comments__logo .brz-img {
      border-radius: 50%;
      -o-object-fit: cover;
         object-fit: cover; }
  .brz .brz-comments__skin-skin3.review .brz-comments__date::after {
    content: "";
    height: 14px;
    width: 1px;
    background-color: #818a91;
    margin: auto 10px; }
  .brz .brz-comments__skin-skin3 .brz-comments__right-date {
    width: calc(100% - 48.5px);
    margin-top: 4px;
    margin-bottom: 15px;
    padding-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .brz .brz-comments__skin-skin3 .brz-comments__right-date::before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: rgba(129, 138, 145, 0.15);
      position: absolute;
      bottom: 0;
      left: 0; }
    .brz .brz-comments__skin-skin3 .brz-comments__right-date .brz-comments__date {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .brz .brz-comments__skin-skin3 .brz-comments__right-date .brz-comments__date::before {
        content: "";
        height: 14px;
        width: 1px;
        background-color: #818a91;
        margin: auto 10px; }
    .brz .brz-comments__skin-skin3 .brz-comments__right-date .brz-comments__text,
    .brz .brz-comments__skin-skin3 .brz-comments__right-date .brz-comment-awaiting-moderation {
      width: 100%; }
      .brz .brz-comments__skin-skin3 .brz-comments__right-date .brz-comments__text .comment-reply-link,
      .brz .brz-comments__skin-skin3 .brz-comments__right-date .brz-comment-awaiting-moderation .comment-reply-link {
        font-family: inherit;
        font-size: inherit; }
      .brz .brz-comments__skin-skin3 .brz-comments__right-date .brz-comments__text *,
      .brz .brz-comments__skin-skin3 .brz-comments__right-date .brz-comment-awaiting-moderation * {
        display: contents; }
    .brz .brz-comments__skin-skin3 .brz-comments__right-date .brz-comments__text {
      margin: 0 !important;
      padding-bottom: 0 !important; }
    .brz .brz-comments__skin-skin3 .brz-comments__right-date .brz-comment-awaiting-moderation {
      margin: 10px 0 0 0 !important;
      padding-bottom: 10px !important; }
  .brz .brz-comments__skin-skin4.review .brz-comments__date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .brz .brz-comments__skin-skin4.review .brz-comments__date::after {
      content: "";
      height: 14px;
      width: 1px;
      background-color: #818a91;
      margin: auto 10px; }
  .brz .brz-comments__skin-skin4 .brz-comments__right-date {
    width: 100% !important;
    padding-bottom: 20px;
    margin-bottom: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: relative; }
    .brz .brz-comments__skin-skin4 .brz-comments__right-date .brz-comments__name-date {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      margin-bottom: 9px; }
      .brz .brz-comments__skin-skin4 .brz-comments__right-date .brz-comments__name-date .brz-comments__left-side {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
      .brz .brz-comments__skin-skin4 .brz-comments__right-date .brz-comments__name-date .brz-comments__name {
        margin-bottom: 5px; }
    .brz .brz-comments__skin-skin4 .brz-comments__right-date .brz-comments__logo .brz-img {
      border-radius: 50%;
      -o-object-fit: cover;
         object-fit: cover; }
    .brz .brz-comments__skin-skin4 .brz-comments__right-date .brz-comments__text,
    .brz .brz-comments__skin-skin4 .brz-comments__right-date .brz-comment-awaiting-moderation {
      width: 100%; }
      .brz .brz-comments__skin-skin4 .brz-comments__right-date .brz-comments__text *,
      .brz .brz-comments__skin-skin4 .brz-comments__right-date .brz-comment-awaiting-moderation * {
        display: contents; }
    .brz .brz-comments__skin-skin4 .brz-comments__right-date .brz-comments__text {
      margin: 0 !important;
      padding: 0 !important; }
    .brz .brz-comments__skin-skin4 .brz-comments__right-date .brz-comment-awaiting-moderation {
      margin: 10px 0 0 0 !important;
      padding-bottom: 10px !important; }
    .brz .brz-comments__skin-skin4 .brz-comments__right-date::before {
      content: "";
      height: 1px;
      width: 28%;
      background-color: rgba(129, 138, 145, 0.15);
      position: absolute;
      left: 0;
      bottom: 0; }
  .brz .brz-comments__skin-skin1 .brz-comments__logo,
  .brz .brz-comments__skin-skin2 .brz-comments__logo {
    margin-right: 10px; }
  .brz .brz-comments__skin-skin1 .brz-comments__logo .brz-img,
  .brz .brz-comments__skin-skin2 .brz-comments__logo .brz-img,
  .brz .brz-comments__skin-skin3 .brz-comments__logo .brz-img,
  .brz .brz-comments__skin-skin4 .brz-comments__logo .brz-img {
    position: static !important;
    margin: 0 !important; }

.brz .brz-logged-in-as {
  margin: 0;
  color: #818a91;
  font-family: "nunito", "Open Sans", Arial, sans-serif;
  font-size: 15px;
  line-height: 28px; }

.brz .comment-notes {
  margin-top: 0;
  margin-bottom: 15px;
  color: #818a91;
  font-family: "nunito", "Open Sans", Arial, sans-serif;
  font-size: 15px;
  line-height: 28px; }

.brz .brz-comment-respond {
  margin-bottom: 20px;
  width: 100%; }
  .brz .brz-comment-respond .brz-comment-reply-title {
    margin: 12px 0;
    color: #03080f;
    font-family: "nunito", "Open Sans", Arial, sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 24px; }
    .brz .brz-comment-respond .brz-comment-reply-title #cancel-comment-reply-link {
      padding-left: 5px;
      font-family: "nunito", "Open Sans", Arial, sans-serif;
      font-size: 14px; }
  .brz .brz-comment-respond .brz--comment__form-reply-body .comment-form-rating .stars {
    font-family: star; }
    .brz .brz-comment-respond .brz--comment__form-reply-body .comment-form-rating .stars a {
      position: relative;
      height: 1em;
      width: 1em;
      text-indent: -999em;
      display: inline-block;
      text-decoration: none;
      -webkit-box-shadow: none;
              box-shadow: none; }
    .brz .brz-comment-respond .brz--comment__form-reply-body .comment-form-rating .stars a::before {
      font-family: star;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1em;
      height: 1em;
      line-height: 1;
      content: "\73";
      text-indent: 0; }
    .brz .brz-comment-respond .brz--comment__form-reply-body .comment-form-rating .stars:hover a::before {
      content: "\53"; }
    .brz .brz-comment-respond .brz--comment__form-reply-body .comment-form-rating .stars.selected a::before {
      content: "\53"; }
    .brz .brz-comment-respond .brz--comment__form-reply-body .comment-form-rating .stars.selected a.active ~ a::before {
      content: "\73"; }
    .brz .brz-comment-respond .brz--comment__form-reply-body .comment-form-rating .stars a:hover ~ a::before {
      content: "\73"; }
  .brz .brz-comment-respond textarea[name="comment"] {
    height: 160px;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    resize: none;
    outline: none; }
  .brz .brz-comment-respond input[name="submit"] {
    border: 0;
    border-radius: 3px;
    padding: 1em 2em; }

.brz .brz-comment-form-comment {
  width: 100%;
  color: #03080f;
  font-family: "nunito", "Open Sans", Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: 24px; }
  .brz .brz-comment-form-comment label {
    margin-bottom: 19px;
    display: block; }

.brz .brz-form-submit {
  width: 100%; }

.brz ul.brz-comments ul.brz-comments {
  width: 80%;
  margin-left: auto; }

.brz .brz-comment-form-author,
.brz .brz-comment-form-email,
.brz .brz-comment-form-url {
  width: 32%;
  margin-top: 5px;
  margin-bottom: 7px;
  font-family: "nunito", "Open Sans", Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .brz .brz-comment-form-author .required,
  .brz .brz-comment-form-email .required,
  .brz .brz-comment-form-url .required {
    font-size: 18px;
    margin-left: 5px; }
  .brz .brz-comment-form-author input[type="text"],
  .brz .brz-comment-form-email input[type="text"],
  .brz .brz-comment-form-url input[type="text"] {
    outline: none;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    margin-top: 18px;
    font-size: 17px; }
    .brz .brz-comment-form-author input[type="text"]:focus,
    .brz .brz-comment-form-email input[type="text"]:focus,
    .brz .brz-comment-form-url input[type="text"]:focus {
      border: 1px solid #d4d4d4; }

.brz .comment-form-cookies-consent {
  width: 100%;
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .brz .comment-form-cookies-consent input[type="checkbox"] {
    height: 18px;
    width: 18px;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    margin-right: 15px;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .brz .comment-form-cookies-consent label {
    color: #818a91;
    font-family: "nunito", "Open Sans", Arial, sans-serif;
    font-size: 15px;
    line-height: 20px; }

.brz .brz--comment__form-reply-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.brz .brz-form-submit {
  margin-bottom: 0; }

@media only screen and (max-width: 768px) {
  .brz .brz-comment-awaiting-moderation {
    padding-bottom: 20px; }
  .brz .brz-comments li.brz-comments .brz-comments__text {
    padding-bottom: 10px; }
  .brz .brz-comments li.brz-comments .brz-comment-awaiting-moderation {
    padding-bottom: 20px; }
  .brz .brz-comments__skin-skin1.comment .brz-comments__date {
    margin-left: 0;
    padding-left: 0; }
  .brz .brz-comments__skin-skin1 .brz-comments__right-date {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .brz .brz-comments__skin-skin1 .brz-comments__right-date .brz-comments__name {
      width: 100%; }
    .brz .brz-comments__skin-skin1 .brz-comments__right-date .brz-comment-awaiting-moderation {
      margin-top: 15px !important; }
  .brz .brz-comments__skin-skin2 .brz-comments__right-date {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .brz .brz-comments__skin-skin2 .brz-comments__right-date .brz-comments__name-date .brz-comments__date * {
      width: auto; }
      .brz .brz-comments__skin-skin2 .brz-comments__right-date .brz-comments__name-date .brz-comments__date * .brz-span {
        position: static;
        display: inline; }
  .brz .brz-comments__skin-skin2 .brz-comments__text,
  .brz .brz-comments__skin-skin2 .brz-comment-awaiting-moderation {
    width: calc(100% - 65px) !important;
    padding: 40px 0 0 0 !important; } }

@media only screen and (max-width: 480px) {
  .brz .brz-comment-form-author,
  .brz .brz-comment-form-email,
  .brz .brz-comment-form-url {
    width: 100%; } }

.brz .brz-image {
  position: relative;
  display: inherit;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit;
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  width: 100%;
  overflow: hidden; }
  .brz .brz-image .brz-img {
    max-width: 100%; }
    .brz .brz-image .brz-img-svg {
      width: 100%;
      height: 100%; }
  .brz .brz-image .brz-a,
  .brz .brz-image .brz-picture {
    outline: none; }
  .brz .brz-image .brz-shortcode__placeholder {
    top: 0;
    position: absolute; }

.brz:not(.brz-ed) .brz-image .brz-a,
.brz:not(.brz-ed) .brz-image .brz-picture {
  width: 100%;
  height: auto; }

.brz:not(.brz-ed) .brz-image .brz-img {
  top: 0;
  left: 0;
  height: 100%; }
  .brz:not(.brz-ed) .brz-image .brz-img-svg:not(.brz-img__original) {
    position: absolute; }

.brz:not(.brz-ed) .brz-image__lightbox * {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.brz:not(.brz-ed) .brz-image picture {
  border-radius: inherit; }

.brz:not(.brz-ed) .brz-image:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  border-radius: inherit;
  -webkit-transform: scale(1.01);
          transform: scale(1.01); }

.brz:not(.brz-ed) .brz-image--story .brz-shortcode__placeholder .brz-icon-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.brz .mfp-bg {
  z-index: 1100; }

.brz .mfp-wrap {
  z-index: 1101; }

.brz .mfp-figure figcaption {
  margin: 0; }

.brz .mfp-figure:after,
.brz .mfp-iframe-scaler iframe {
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.35);
          box-shadow: 0 0 40px rgba(0, 0, 0, 0.35); }

.brz .brz-menu-simple .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  list-style: none;
  margin: 0 auto;
  padding: 0; }
  .brz .brz-menu-simple .menu .menu-item {
    position: relative;
    text-align: left;
    list-style: none; }
    .brz .brz-menu-simple .menu .menu-item a span {
      display: block; }
    .brz .brz-menu-simple .menu .menu-item:hover > .sub-menu {
      display: block; }
  .brz .brz-menu-simple .menu .sub-menu {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px 0;
    text-align: center;
    text-transform: none;
    list-style: none;
    margin: 0 !important;
    display: none;
    width: 280px;
    background-color: #33344b;
    z-index: 110; }
    .brz .brz-menu-simple .menu .sub-menu .menu-item {
      display: block;
      margin: 0;
      padding: 0; }
    .brz .brz-menu-simple .menu .sub-menu .sub-menu {
      left: 100%; }
    .brz .brz-menu-simple .menu .sub-menu a {
      display: block;
      line-height: 1.2em;
      padding: 10px 12%;
      color: #fcfcfc; }
      .brz .brz-menu-simple .menu .sub-menu a:hover {
        background: rgba(36, 36, 53, 0.94); }
  .brz .brz-menu-simple .menu > .menu-item {
    display: inline-block;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    padding-top: 5px;
    padding-bottom: 5px; }
    .brz .brz-menu-simple .menu > .menu-item > .sub-menu {
      top: 100%; }
  .brz .brz-menu-simple .menu > ul {
    margin: 0 !important; }
    .brz .brz-menu-simple .menu > ul .page_item:not(:first-child) {
      margin-left: 15px; }

.brz .brz-menu-simple__toggle .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }

.brz .brz-menu-simple__icon {
  display: none;
  padding: 15px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0; }
  .brz .brz-menu-simple__icon--bars {
    display: block;
    height: 2px;
    position: relative;
    -webkit-transition: background 0.2s ease-out;
    transition: background 0.2s ease-out;
    width: 18px; }
    .brz .brz-menu-simple__icon--bars:before, .brz .brz-menu-simple__icon--bars:after {
      background-color: currentColor;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      width: 100%; }
    .brz .brz-menu-simple__icon--bars:before {
      top: 5px; }
    .brz .brz-menu-simple__icon--bars:after {
      top: -5px; }

.brz .brz-menu-simple .brz-input {
  display: none; }
  .brz .brz-menu-simple .brz-input:checked ~ div .menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-height: 100%; }
  .brz .brz-menu-simple .brz-input:checked ~ .brz-menu-simple__icon .brz-menu-simple__icon--bars {
    background: transparent; }
  .brz .brz-menu-simple .brz-input:checked ~ .brz-menu-simple__icon .brz-menu-simple__icon--bars:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .brz .brz-menu-simple .brz-input:checked ~ .brz-menu-simple__icon .brz-menu-simple__icon--bars:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .brz .brz-menu-simple .brz-input:checked ~ .brz-menu-simple__icon:not(.steps) .brz-menu-simple__icon--bars:before,
  .brz .brz-menu-simple .brz-input:checked ~ .brz-menu-simple__icon:not(.steps) .brz-menu-simple__icon--bars:after {
    top: 0; }

.brz .brz-menu-simple--cloud a {
  text-decoration: none; }

.brz .brz-menu__ul {
  margin: 0;
  padding: 0;
  font-family: inherit; }

.brz .brz-menu__item {
  list-style: none; }

@media (max-width: 767px) {
  .brz:not(.brz-ed) .brz-menu-simple {
    width: auto;
    text-align: center; }
    .brz:not(.brz-ed) .brz-menu-simple__toggle--mobile .menu {
      display: none;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column nowrap;
              flex-flow: column nowrap;
      max-height: 0;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-transition: max-height 0.2s ease-out;
      transition: max-height 0.2s ease-out; }
      .brz:not(.brz-ed) .brz-menu-simple__toggle--mobile .menu .sub-menu {
        position: relative;
        display: block;
        width: 100%;
        left: 15px;
        padding: 0;
        background-color: transparent; }
        .brz:not(.brz-ed) .brz-menu-simple__toggle--mobile .menu .sub-menu .menu-item a {
          padding: 5px; }
          .brz:not(.brz-ed) .brz-menu-simple__toggle--mobile .menu .sub-menu .menu-item a:hover {
            background-color: transparent; }
        .brz:not(.brz-ed) .brz-menu-simple__toggle--mobile .menu .sub-menu .sub-menu {
          display: block;
          left: 15px; }
      .brz:not(.brz-ed) .brz-menu-simple__toggle--mobile .menu > .menu-item {
        padding: 0; }
        .brz:not(.brz-ed) .brz-menu-simple__toggle--mobile .menu > .menu-item > .sub-menu {
          top: 100%;
          padding: 5px 0; }
    .brz:not(.brz-ed) .brz-menu-simple__toggle--mobile .brz-menu-simple__icon {
      display: inline-block; } }

@media (min-width: 768px) and (max-width: 991px) {
  .brz:not(.brz-ed) .brz-menu-simple {
    width: auto;
    text-align: center; }
    .brz:not(.brz-ed) .brz-menu-simple__toggle--tablet .menu {
      display: none;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column nowrap;
              flex-flow: column nowrap;
      max-height: 0;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-transition: max-height 0.2s ease-out;
      transition: max-height 0.2s ease-out; }
      .brz:not(.brz-ed) .brz-menu-simple__toggle--tablet .menu .sub-menu {
        position: relative;
        display: block;
        width: 100%;
        left: 15px;
        padding: 0;
        background-color: transparent; }
        .brz:not(.brz-ed) .brz-menu-simple__toggle--tablet .menu .sub-menu .menu-item a {
          padding: 5px; }
          .brz:not(.brz-ed) .brz-menu-simple__toggle--tablet .menu .sub-menu .menu-item a:hover {
            background-color: transparent; }
        .brz:not(.brz-ed) .brz-menu-simple__toggle--tablet .menu .sub-menu .sub-menu {
          display: block;
          left: 15px; }
      .brz:not(.brz-ed) .brz-menu-simple__toggle--tablet .menu > .menu-item {
        padding: 0; }
        .brz:not(.brz-ed) .brz-menu-simple__toggle--tablet .menu > .menu-item > .sub-menu {
          top: 100%;
          padding: 5px 0; }
    .brz:not(.brz-ed) .brz-menu-simple__toggle--tablet .brz-menu-simple__icon {
      display: inline-block; } }

.brz:not(.brz-ed) .brz-login .brz-login-form,
.brz:not(.brz-ed) .brz-login .brz-login__authorized {
  display: none; }

.brz .brz-login {
  width: 100%; }
  .brz .brz-login__authorized * {
    display: inline; }
  .brz .brz-login__authorized p {
    margin: 0; }
  .brz .brz-login__authorized span {
    cursor: pointer; }
  .brz .brz-login__authorized a {
    text-decoration: none; }
  .brz .brz-login span.psw {
    float: right;
    padding-top: 16px; }
  .brz .brz-login .brz-input {
    width: 100%;
    height: auto;
    max-width: 100%;
    min-height: auto;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    line-height: inherit;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .brz .brz-login .brz-input::-webkit-input-placeholder {
      color: inherit;
      opacity: 0.5; }
    .brz .brz-login .brz-input::-moz-placeholder {
      color: inherit;
      opacity: 0.5; }
    .brz .brz-login .brz-input::-ms-input-placeholder {
      color: inherit;
      opacity: 0.5; }
    .brz .brz-login .brz-input::placeholder {
      color: inherit;
      opacity: 0.5; }
  .brz .brz-login__item {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .brz .brz-login__item-button .brz-login__item {
    width: 100%;
    overflow: hidden; }
  .brz .brz-login__item-button .brz-btn {
    outline: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0; }
  .brz .brz-login__item-button .brz-icon-svg {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0; }
  .brz .brz-login__alert {
    min-height: 55px;
    width: 100%;
    padding: 17px 30px;
    margin-top: 15px;
    word-break: break-word;
    font-family: "nunito", "Open Sans", Arial, sans-serif;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 2.5px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff; }
    .brz .brz-login__alert--success {
      background-color: #15d053; }
    .brz .brz-login__alert--error {
      background-color: #f00; }
  .brz .brz-login-form {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    margin-top: -0 !important; }
    .brz .brz-login-form .brz-login__field .brz-login__field-empty.brz-input {
      border-color: #f00; }
  .brz .brz-login .brz-control__check-group-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .brz .brz-login .brz-control__check-group-option .brz-control__check-group-icon {
      margin-right: 10px; }
  .brz .brz-login .brz-login__field-label {
    display: block; }
  .brz .brz-login .brz-login-form__field {
    padding-top: 0 !important; }
    .brz .brz-login .brz-login-form__field a {
      text-decoration: none; }
  .brz .brz-login .brz-login-form__field-lost-password,
  .brz .brz-login .brz-login-form__field-register-link,
  .brz .brz-login .brz-login-form__field-login-link {
    width: 100%;
    cursor: pointer; }
  .brz .brz-login .brz-login-form__field-remember-off.brz-login-form__field-Remember,
  .brz .brz-login .brz-login-form__field-registerInfo-off {
    padding: 0 !important; }

.brz .brz-shape {
  position: relative;
  width: 100%;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  vertical-align: middle; }
  .brz .brz-shape .brz-ed-border,
  .brz .brz-shape .brz-d-xs-flex {
    height: 100%; }
  .brz .brz-shape .brz-ed-box__resizer {
    height: unset; }
  .brz .brz-shape::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    pointer-events: none; }

.brz .brz-shopify-quantity {
  width: 20%;
  line-height: 1.8; }
  .brz .brz-shopify-quantity > .brz-input {
    outline: none;
    letter-spacing: inherit;
    font-weight: inherit;
    color: inherit;
    border: none;
    background-color: inherit;
    text-align: center;
    border-radius: inherit;
    width: 100%;
    line-height: inherit; }

.brz .brz-shopify-add-to-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.brz .brz-filters__main {
  width: 100%; }

.brz .brz-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .brz .brz-filters .brz-filters__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .brz .brz-filters .brz-filters__option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .brz .brz-filters .brz-filters__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .brz .brz-filters .brz-filters__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .brz .brz-filters .brz-filters__checkbox-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .brz .brz-filters .brz-filters__checkbox-option .brz-label,
  .brz .brz-filters .brz-filters__radio-option .brz-label {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    line-height: inherit; }
  .brz .brz-filters .brz-filters__checkbox-option-name {
    margin-left: 10px; }
  .brz .brz-filters .brz-filters__radio--style-2 .brz-control__radio-check,
  .brz .brz-filters .brz-filters__radio--style-3 .brz-control__radio-check,
  .brz .brz-filters .brz-filters__radio--style-4 .brz-control__radio-check {
    display: none !important; }
  .brz .brz-filters .brz-filters__checkbox--style-2 .brz-filters__checkbox-option {
    -ms-flex-preferred-size: unset;
        flex-basis: unset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .brz .brz-filters .brz-filters__checkbox--style-2 .brz-filters__checkbox-option .brz-filters__checkbox-option-name {
      margin-left: 0 !important; }
  .brz .brz-filters .brz-control__radio-option.brz-filters__radio-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .brz .brz-filters .brz-filters__radio--style-2 .brz-filters__radio-option {
    -ms-flex-preferred-size: unset;
        flex-basis: unset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .brz .brz-filters .brz-filters__radio--style-2 .brz-filters__radio-option .brz-filters__radio-option-name {
      margin-left: 0 !important; }
  .brz .brz-filters .brz-filters__radio--style-2 .brz-control__radio-check {
    -ms-flex-preferred-size: unset;
        flex-basis: unset; }
  .brz .brz-filters .brz-filters__radio--style-2 .brz-filters__radio-option-name {
    margin: 0;
    -ms-flex-preferred-size: unset;
        flex-basis: unset; }
  .brz .brz-filters .brz-control__radio-check {
    -ms-flex-preferred-size: 1em;
        flex-basis: 1em;
    border-radius: 100%; }
  .brz .brz-filters .brz-filters__range-option {
    background-color: #3dbfe8;
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px; }
  .brz .brz-filters .brz-filters__date-option,
  .brz .brz-filters .brz-filters__select-option,
  .brz .brz-filters .brz-filters__search-option {
    outline: none;
    padding: 14px 24px; }
  .brz .brz-filters.brz-filters__select .brz-filters__option {
    display: block; }
  .brz .brz-filters .brz-filters__select-option {
    width: 100%;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .brz .brz-filters .brz-filters__select-option .brz-filters__select {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1; }
    .brz .brz-filters .brz-filters__select-option .brz-filters__select--icon {
      font-size: 14px; }
  .brz .brz-filters .brz-filters__select__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    border: 0; }
    .brz .brz-filters .brz-filters__select__item .brz-filters__select__icon {
      -ms-flex-preferred-size: 52px;
          flex-basis: 52px;
      text-align: center;
      font-size: 13px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      cursor: pointer;
      opacity: 0.7;
      -webkit-transition: 0.2s linear opacity;
      transition: 0.2s linear opacity; }
  .brz .brz-filters .brz-filters__select__dropdown {
    position: absolute;
    width: 100%;
    border-top: none !important;
    z-index: 1040; }
  .brz .brz-filters .brz-filters__select__input {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-family: inherit;
    font-weight: inherit; }
    .brz .brz-filters .brz-filters__select__input .brz-input.brz-input__select {
      cursor: text;
      outline: 0;
      background: inherit;
      border-width: 0;
      height: 100%;
      width: 100%;
      color: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      line-height: inherit; }
  .brz .brz-filters .brz-filters__search-option {
    width: 100%;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .brz .brz-filters .brz-filters__search-option .brz-filters__search {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      letter-spacing: inherit;
      line-height: inherit;
      background-color: inherit;
      color: inherit;
      padding: 0;
      outline: none;
      border: none; }
    .brz .brz-filters .brz-filters__search-option .brz-filters__search--icon {
      font-size: 14px; }
  .brz .brz-filters .brz-filters__date--wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .brz .brz-filters .brz-filters__date--wrapper .brz-filters__date-option::-webkit-input-placeholder {
      color: inherit; }
    .brz .brz-filters .brz-filters__date--wrapper .brz-filters__date-option::-moz-placeholder {
      color: inherit; }
    .brz .brz-filters .brz-filters__date--wrapper .brz-filters__date-option::-ms-input-placeholder {
      color: inherit; }
    .brz .brz-filters .brz-filters__date--wrapper .brz-filters__date-option::placeholder {
      color: inherit; }
    .brz .brz-filters .brz-filters__date--wrapper .brz-filters__date--label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
  .brz .brz-filters .brz-filters__rating {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .brz .brz-filters .brz-filters__rating .brz-filters__option {
      width: auto; }
    .brz .brz-filters .brz-filters__rating .brz-filters__rating-icon {
      width: auto !important; }
  .brz .brz-filters .brz-filters__counter {
    margin-left: 5px; }
  .brz .brz-filters .brz-filters__btn-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .brz .brz-filters .brz-filters__btn-wrapper .brz-filters__apply {
      outline: none; }
    .brz .brz-filters .brz-filters__btn-wrapper .brz-filters__apply:hover {
      text-decoration: none; }
  .brz .brz-filters .brz-filters__tags {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .brz .brz-filters .brz-filters__active-filters {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: auto; }
    .brz .brz-filters .brz-filters__active-filters .brz-filters__tag {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      min-width: 20px; }
      .brz .brz-filters .brz-filters__active-filters .brz-filters__tag .brz-filters__tag__text {
        cursor: pointer; }
      .brz .brz-filters .brz-filters__active-filters .brz-filters__tag .brz-filters__tag__close-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        cursor: pointer; }
  .brz .brz-filters .brz-filters__checkbox--style-4 .brz-filters__option .brz-filters__checkbox-option {
    background-size: cover; }
  .brz .brz-filters .brz-filters__radio--style-4 .brz-filters__option .brz-filters__radio-option {
    background-size: cover; }
  .brz .brz-filters .brz-filters__checkbox-color,
  .brz .brz-filters .brz-filters__radio-color {
    width: 20px;
    height: 20px; }
  .brz .brz-filters .brz-filters__range.brz-filters__option {
    display: block;
    width: 100%; }
  .brz .brz-filters .brz-filters__range__text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .brz .brz-filters .brz-filters__range__slider--active {
    width: 70%;
    position: absolute;
    left: 15%; }
  .brz .brz-filters .brz-filters__range--wrapper {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .brz .brz-filters .brz-filters__range__slider {
    width: 100%;
    -webkit-box-sizing: content-box;
            box-sizing: content-box; }
  .brz .brz-filters .brz-filters__range__slider-handle {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    position: absolute;
    border-radius: 100%;
    top: 0;
    cursor: pointer; }
  .brz .brz-filters .brz-filters__range__slider--start {
    left: 14%; }
  .brz .brz-filters .brz-filters__range__slider--end {
    left: 84%; }
  .brz .brz-filters .brz-filters__range--slider {
    position: relative;
    z-index: 1;
    -webkit-box-sizing: content-box;
            box-sizing: content-box; }
  .brz .brz-filters .brz-filters__range--slider > .brz-filters__range--track {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0; }
  .brz .brz-filters .brz-filters__range--slider > .brz-filters__range--range {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0; }
  .brz .brz-filters .brz-filters__range--slider > .brz-filters__range--thumb {
    position: absolute;
    z-index: 3;
    border-radius: 50%;
    -webkit-box-sizing: content-box;
            box-sizing: content-box; }
  .brz .brz-filters input[type="range"] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    opacity: 0;
    padding: 0; }
  .brz .brz-filters input[type="range"]::-webkit-slider-thumb {
    pointer-events: all;
    border-radius: 0;
    -webkit-appearance: none; }

.brz.brz:not(.brz-ed) .flatpickr-calendar {
  font-family: "Lato", sans-serif;
  width: 306px; }

.brz.brz:not(.brz-ed) .flatpickr-calendar.hasTime {
  width: 150px; }

.brz.brz:not(.brz-ed) .flatpickr-calendar.open {
  max-height: 600px; }

.brz.brz:not(.brz-ed) .flatpickr-calendar .dayContainer {
  width: 286px;
  min-width: 286px;
  max-width: 286px; }

.brz.brz:not(.brz-ed) .flatpickr-calendar .numInputWrapper:hover {
  background-color: transparent; }

.brz.brz:not(.brz-ed) .flatpickr-months {
  padding: 10px 10px 0; }

.brz.brz:not(.brz-ed) .flatpickr-innerContainer {
  padding: 0 10px 10px; }

.brz.brz:not(.brz-ed) .flatpickr-next-month, .brz.brz:not(.brz-ed) .flatpickr-prev-month {
  top: 7px; }

.brz.brz:not(.brz-ed) .flatpickr-next-month {
  right: 10px; }

.brz.brz:not(.brz-ed) .flatpickr-prev-month {
  left: 10px; }

.brz.brz:not(.brz-ed) .flatpickr-months .flatpickr-prev-month,
.brz.brz:not(.brz-ed) .flatpickr-months .flatpickr-next-month {
  color: rgba(0, 0, 0, 0.5); }

.brz.brz:not(.brz-ed) .flatpickr-months .flatpickr-prev-month:hover,
.brz.brz:not(.brz-ed) .flatpickr-months .flatpickr-next-month:hover {
  color: black; }

.brz.brz:not(.brz-ed) .flatpickr-months .flatpickr-prev-month svg,
.brz.brz:not(.brz-ed) .flatpickr-months .flatpickr-next-month svg {
  fill: currentColor; }

.brz.brz:not(.brz-ed) .flatpickr-current-month input.cur-year {
  font-size: 17px;
  font-weight: 700; }

.brz.brz:not(.brz-ed) .flatpickr-current-month .numInputWrapper:hover,
.brz.brz:not(.brz-ed) .flatpickr-current-month span.cur-month:hover {
  background-color: transparent; }

.brz.brz:not(.brz-ed) .flatpickr-weekdays {
  padding: 20px 0 15px;
  height: auto; }

.brz.brz:not(.brz-ed) .flatpickr-weekday {
  color: rgba(0, 0, 0, 0.8); }

.brz.brz:not(.brz-ed) .flatpickr-days {
  width: 286px;
  font-size: 13px;
  font-weight: 700; }

.brz.brz:not(.brz-ed) .flatpickr-day {
  height: 36px;
  max-width: 36px;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.brz.brz:not(.brz-ed) .flatpickr-day.today {
  border-color: #a9a9a9; }

.brz.brz:not(.brz-ed) .flatpickr-day:hover, .brz.brz:not(.brz-ed) .flatpickr-day:focus {
  color: #fff;
  border-color: #a9a9a9;
  background-color: #a9a9a9; }

.brz.brz:not(.brz-ed) .flatpickr-day.selected {
  border-color: rgba(0, 0, 0, 0.9);
  background-color: rgba(0, 0, 0, 0.9); }

.brz.brz:not(.brz-ed) .flatpickr-time input {
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  text-indent: 12px; }

.brz.brz:not(.brz-ed) .flatpickr-time input:hover,
.brz.brz:not(.brz-ed) .flatpickr-time input:focus,
.brz.brz:not(.brz-ed) .flatpickr-time .flatpickr-am-pm:hover,
.brz.brz:not(.brz-ed) .flatpickr-time .flatpickr-am-pm:focus {
  background-color: transparent; }

.brz.brz:not(.brz-ed) .flatpickr-am-pm {
  font-size: 14px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.7);
  width: 32.66%; }

.brz.brz:not(.brz-ed) .flatpickr-am-pm:hover, .brz.brz:not(.brz-ed) .flatpickr-am-pm:focus {
  color: black; }

.brz.brz:not(.brz-ed) .flatpickr-calendar.hasTime .numInputWrapper {
  width: 32.66%; }

.brz.brz:not(.brz-ed) .flatpickr-calendar.hasTime .numInputWrapper span.arrowUp,
.brz.brz:not(.brz-ed) .flatpickr-calendar.hasTime .numInputWrapper span.arrowDown {
  height: 10px;
  top: 50%; }

.brz.brz:not(.brz-ed) .flatpickr-calendar.hasTime .numInputWrapper span.arrowUp {
  margin-top: -10px; }

.brz.brz:not(.brz-ed) .flatpickr-calendar.hasTime .numInputWrapper span.arrowDown {
  margin-bottom: -10px; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-dropdown {
  background-color: #fff;
  border: none;
  border-radius: 0; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-results__option {
  margin: 0;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
  background-color: inherit; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2 .selection, .brz.brz:not(.brz-ed) .brz-filters__select .select2-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-container, .brz.brz:not(.brz-ed) .brz-filters__select .select2-selection {
  outline: none; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-selection--multiple, .brz.brz:not(.brz-ed) .brz-filters__select .select2-selection--single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }
  .brz.brz:not(.brz-ed) .brz-filters__select .select2-selection--multiple .select2-selection__rendered, .brz.brz:not(.brz-ed) .brz-filters__select .select2-selection--single .select2-selection__rendered {
    color: inherit;
    line-height: inherit !important;
    padding: 0;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-selection--multiple .select2-selection__rendered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -2.5px; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-selection--multiple .select2-selection__choice {
  margin: 2.5px;
  float: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: none;
  padding: 0 11px; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-selection--multiple .select2-selection__choice__remove {
  display: block;
  font-size: inherit;
  font-weight: normal;
  color: inherit;
  margin-right: 0;
  margin-left: 8px; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-selection__arrow {
  top: 50%;
  right: 18px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-selection__clear {
  margin-right: 14px; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-search__field {
  font: inherit;
  color: inherit;
  margin: 0; }
  .brz.brz:not(.brz-ed) .brz-filters__select .select2-search__field::-webkit-input-placeholder {
    color: inherit; }
  .brz.brz:not(.brz-ed) .brz-filters__select .select2-search__field::-moz-placeholder {
    color: inherit; }
  .brz.brz:not(.brz-ed) .brz-filters__select .select2-search__field::-ms-input-placeholder {
    color: inherit; }
  .brz.brz:not(.brz-ed) .brz-filters__select .select2-search__field::placeholder {
    color: inherit; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-container .select2-search--inline {
  margin: 2.5px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  float: none; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-container--default .select2-results > .select2-results__options {
  padding: 0;
  max-height: initial; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: inherit;
  color: inherit; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: inherit; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-container--default .select2-selection--single .select2-selection__placeholder:empty:before {
  content: "Fake content";
  visibility: hidden; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: currentColor transparent transparent; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow
b {
  border-color: transparent transparent currentColor; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-container--open .select2-dropdown {
  top: 1px;
  max-height: 200px;
  overflow: hidden; }

.brz.brz:not(.brz-ed) .brz-filters__select .select2-dropdown--below {
  background-color: transparent; }

.brz.brz:not(.brz-ed) .brz-filters__select .ps__thumb-y {
  background-color: rgba(0, 0, 0, 0.2); }

.brz.brz:not(.brz-ed) .brz-filters__select .ps .ps__rail-x:hover,
.brz.brz:not(.brz-ed) .brz-filters__select .ps .ps__rail-y:hover,
.brz.brz:not(.brz-ed) .brz-filters__select .ps .ps__rail-x:focus,
.brz.brz:not(.brz-ed) .brz-filters__select .ps .ps__rail-y:focus,
.brz.brz:not(.brz-ed) .brz-filters__select .ps .ps__rail-x.ps--clicking,
.brz.brz:not(.brz-ed) .brz-filters__select .ps .ps__rail-y.ps--clicking {
  background-color: transparent; }

.brz.brz:not(.brz-ed) .brz-filters__select .ps__rail-y:hover > .ps__thumb-y,
.brz.brz:not(.brz-ed) .brz-filters__select .ps__rail-y:focus > .ps__thumb-y,
.brz.brz:not(.brz-ed) .brz-filters__select .ps__rail-y.ps--clicking .ps__thumb-y {
  width: 6px; }

.brz.brz:not(.brz-ed) .brz-filters__select .ps__rail-x,
.brz.brz:not(.brz-ed) .brz-filters__select .ps__rail-y,
.brz.brz:not(.brz-ed) .brz-filters__select .ps:hover > .ps__rail-x,
.brz.brz:not(.brz-ed) .brz-filters__select .ps:hover > .ps__rail-y,
.brz.brz:not(.brz-ed) .brz-filters__select .ps--focus > .ps__rail-x,
.brz.brz:not(.brz-ed) .brz-filters__select .ps--focus > .ps__rail-y,
.brz.brz:not(.brz-ed) .brz-filters__select .ps--scrolling-x > .ps__rail-x,
.brz.brz:not(.brz-ed) .brz-filters__select .ps--scrolling-y > .ps__rail-y {
  opacity: 1; }

.brz .brz-reset-psw {
  width: 100%; }
  .brz .brz-reset-psw .brz-input {
    width: 100%;
    height: auto;
    max-width: 100%;
    min-height: auto;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    line-height: inherit;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .brz .brz-reset-psw .brz-input::-webkit-input-placeholder {
      color: inherit;
      opacity: 0.5; }
    .brz .brz-reset-psw .brz-input::-moz-placeholder {
      color: inherit;
      opacity: 0.5; }
    .brz .brz-reset-psw .brz-input::-ms-input-placeholder {
      color: inherit;
      opacity: 0.5; }
    .brz .brz-reset-psw .brz-input::placeholder {
      color: inherit;
      opacity: 0.5; }
  .brz .brz-reset-psw__item {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
    .brz .brz-reset-psw__item-button .brz-btn {
      outline: none;
      width: 100%; }
    .brz .brz-reset-psw__item-button .brz-icon-svg {
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0; }
  .brz .brz-reset-psw-form {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    margin-top: -0 !important; }
    .brz .brz-reset-psw-form__field {
      padding-top: 0 !important; }
      .brz .brz-reset-psw-form__field a {
        text-decoration: none; }
    .brz .brz-reset-psw-form .brz-reset-psw__field-label {
      display: block; }
    .brz .brz-reset-psw-form .brz-reset-psw__field-empty.brz-input {
      border-color: #f00; }
  .brz .brz-reset-psw .brz-reset-psw-form .brz-reset-psw-form__field .brz-reset-psw__field-empty.brz-input {
    border-color: #f00; }
  .brz .brz-reset-psw__alert {
    min-height: 55px;
    width: 100%;
    padding: 17px 30px;
    margin-top: 15px;
    font-family: "nunito", "Open Sans", Arial, sans-serif;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 2.5px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    background-color: #f00; }

.brz .brz-wp-post-content {
  width: 100%; }
  .brz .brz-wp-post-content > div > :first-child {
    margin-top: 0; }
  .brz .brz-wp-post-content > div > :last-child {
    margin-bottom: 0; }
  .brz .brz-wp-post-content > div > * {
    margin-bottom: 1em; }

.brz .brz-rich-text .brz-tp__dc-block-st1 {
  width: 100%;
  white-space: initial; }
  .brz .brz-rich-text .brz-tp__dc-block-st1 > :first-child {
    margin-top: 0; }
  .brz .brz-rich-text .brz-tp__dc-block-st1 > :last-child {
    margin-bottom: 0; }
  .brz .brz-rich-text .brz-tp__dc-block-st1 > * {
    margin-bottom: 1em; }

.brz .brz-wp-post-excerpt {
  width: 100%; }
  .brz .brz-wp-post-excerpt-content {
    margin: 0; }

.brz .brz-wp-title {
  width: 100%; }
  .brz .brz-wp-title-content {
    margin: 0; }

.brz .brz-woo-stock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit;
  width: 100%; }
  .brz .brz-woo-stock .stock {
    margin: 0; }

.brz .brz-woosku {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit; }

.brz .brz-wooproductmeta {
  width: 100%;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .brz .brz-wooproductmeta > div > .brz-metas {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .brz .brz-wooproductmeta__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative; }
    .brz .brz-wooproductmeta__container.brz-wooproductmeta__container:first-child > .brz-wooproductmeta__item.brz-wooproductmeta__item {
      padding-top: 0; }
    .brz .brz-wooproductmeta__container:last-child > .brz-wooproductmeta__item.brz-wooproductmeta__item {
      padding-bottom: 0; }
  .brz .brz-wooproductmeta__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .brz .brz-wooproductmeta__item .brz-a {
      color: inherit !important; }
      .brz .brz-wooproductmeta__item .brz-a:hover {
        text-decoration: underline; }
    .brz .brz-wooproductmeta__item-value:not(:first-child) .brz-a {
      padding-left: 5px; }
  .brz .brz-wooproductmeta-table {
    border-collapse: collapse; }
    .brz .brz-wooproductmeta-table > div > .brz-metas {
      display: table; }
      .brz .brz-wooproductmeta-table > div > .brz-metas .brz-wooproductmeta__container {
        display: table-row; }
      .brz .brz-wooproductmeta-table > div > .brz-metas .brz-wooproductmeta__item {
        display: table-cell;
        vertical-align: middle; }

.brz .brz-woo-price {
  min-height: -webkit-fit-content !important;
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit;
  width: 100%; }
  .brz .brz-woo-price > div {
    font-size: 0; }
    .brz .brz-woo-price > div > .brz-price-none {
      margin: 0;
      font-size: 16px; }
    .brz .brz-woo-price > div > p.price {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin: 0; }
      .brz .brz-woo-price > div > p.price del {
        opacity: 1 !important; }
        .brz .brz-woo-price > div > p.price del .amount {
          display: block; }
      .brz .brz-woo-price > div > p.price ins {
        background: transparent;
        text-decoration: none; }
        .brz .brz-woo-price > div > p.price ins .amount {
          display: block; }

.brz .brz-woo-attributes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .brz .brz-woo-attributes table {
    border: 0; }
  .brz .brz-woo-attributes h2 {
    margin: 0; }
  .brz .brz-woo-attributes .woocommerce-product-attributes {
    width: 100%;
    margin: 0; }
    .brz .brz-woo-attributes .woocommerce-product-attributes tr {
      border-width: 0;
      background-color: transparent; }
  .brz .brz-woo-attributes .woocommerce-product-attributes-item__label,
  .brz .brz-woo-attributes .woocommerce-product-attributes-item__value {
    width: 50%;
    padding: 0;
    font-style: normal; }
    .brz .brz-woo-attributes .woocommerce-product-attributes-item__label p,
    .brz .brz-woo-attributes .woocommerce-product-attributes-item__value p {
      margin: 0;
      padding: 0; }

.brz .brz-woocart__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit; }
  .brz .brz-woocart__wrapper--opened .brz-woocart__background {
    opacity: 1;
    pointer-events: auto; }
  .brz .brz-woocart__wrapper--opened .brz-woocart__sidebar {
    opacity: 1;
    pointer-events: auto; }
  .brz .brz-woocart__wrapper .brz-woocart__dc {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: inherit;
        -ms-flex-pack: inherit;
            justify-content: inherit; }

.brz .brz-woocart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 15px; }
  .brz .brz-woocart,
  .brz .brz-woocart__dc .brz-cart {
    font-size: initial; }
  .brz .brz-woocart__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    z-index: 1060;
    cursor: auto; }
  .brz .brz-woocart__parent {
    cursor: pointer; }
    .brz .brz-woocart__parent.brz-woocart--bubble .brz-woocart__icon:before {
      position: absolute;
      min-width: 1.6em;
      height: 1.6em;
      line-height: 1.5em;
      top: -0.7em;
      right: -1em;
      border-radius: 100%;
      text-align: center;
      font-size: 10px;
      z-index: 1; }
  .brz .brz-woocart__icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative; }
  .brz .brz-woocart__sidebar {
    max-width: 100%;
    max-height: 100vh;
    position: fixed;
    z-index: 1060;
    padding: 40px 15px 15px;
    opacity: 0;
    overflow: auto;
    pointer-events: none;
    cursor: auto;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out; }
    .brz .brz-woocart__sidebar-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 20px 0; }
      .brz .brz-woocart__sidebar-item:first-child {
        padding-top: 0; }
      .brz .brz-woocart__sidebar-item:not(:first-child) {
        border-top: 1px solid #000; }
    .brz .brz-woocart__sidebar__product-info {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding-left: 15px; }
    .brz .brz-woocart__sidebar-remove {
      margin-top: auto;
      -ms-flex-negative: 0;
          flex-shrink: 0; }
    .brz .brz-woocart__sidebar-image__block {
      position: relative;
      width: 80px;
      height: 80px;
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      .brz .brz-woocart__sidebar-image__block * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
      .brz .brz-woocart__sidebar-image__block img {
        -o-object-fit: cover;
           object-fit: cover; }
    .brz .brz-woocart__sidebar-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer; }
      .brz .brz-woocart__sidebar-close:before, .brz .brz-woocart__sidebar-close:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #000;
        top: 0;
        left: 0;
        right: 0;
        margin: auto; }
      .brz .brz-woocart__sidebar-close:before {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
      .brz .brz-woocart__sidebar-close:after {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
    .brz .brz-woocart__sidebar-subtotal {
      margin-bottom: 20px;
      padding: 15px 0;
      border-bottom: 1px solid #000; }
      .brz .brz-woocart__sidebar-subtotal:not(:nth-child(2)) {
        border-top: 1px solid #000; }
    .brz .brz-woocart__sidebar-buttons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
    .brz .brz-woocart__sidebar-button {
      padding: 10px 15px;
      text-align: center; }
      .brz .brz-woocart__sidebar-button:last-child {
        margin-bottom: 0; }

.brz.brz-woocart--opened .brz-root__container,
.brz.brz-woocart--opened .brz-root__container > .brz-section,
.brz.brz-woocart--opened .brz-root__container .brz-section__header > .brz-section__menu-item {
  z-index: auto; }

.brz .brz-woo-rating {
  width: 100%;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .brz .brz-woo-rating.brz-disabled-rating-text .woocommerce-review-link {
    display: none; }
  .brz .brz-woo-rating .woocommerce-product-rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0; }
    .brz .brz-woo-rating .woocommerce-product-rating .star-rating {
      margin: 0; }

.brz .brz-woo-gallery .woocommerce-product-gallery {
  margin: 0;
  font-size: 1.3rem;
  float: none !important;
  width: 100% !important;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .brz .brz-woo-gallery .woocommerce-product-gallery img {
    width: 100%; }
  .brz .brz-woo-gallery .woocommerce-product-gallery .flex-viewport {
    margin-bottom: 0;
    width: 100%; }
  .brz .brz-woo-gallery .woocommerce-product-gallery .woocommerce-product-gallery__wrapper {
    overflow: hidden; }
  .brz .brz-woo-gallery .woocommerce-product-gallery .flex-control-thumbs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0; }
    .brz .brz-woo-gallery .woocommerce-product-gallery .flex-control-thumbs li {
      overflow: hidden; }

.brz .brz-woo-gallery.brz-woo-gallery__thumbsTB-1 .flex-control-thumbs li:nth-child(1n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsTB-2 .flex-control-thumbs li:nth-child(2n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsTB-3 .flex-control-thumbs li:nth-child(3n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsTB-4 .flex-control-thumbs li:nth-child(4n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsTB-5 .flex-control-thumbs li:nth-child(5n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsTB-6 .flex-control-thumbs li:nth-child(6n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsTB-7 .flex-control-thumbs li:nth-child(7n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsTB-8 .flex-control-thumbs li:nth-child(8n), .brz .brz-woo-gallery.brz-woo-gallery__thumbsRL-1 .flex-control-thumbs li:nth-child(1n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsRL-2 .flex-control-thumbs li:nth-child(2n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsRL-3 .flex-control-thumbs li:nth-child(3n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsRL-4 .flex-control-thumbs li:nth-child(4n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsRL-5 .flex-control-thumbs li:nth-child(5n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsRL-6 .flex-control-thumbs li:nth-child(6n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsRL-7 .flex-control-thumbs li:nth-child(7n),
.brz .brz-woo-gallery.brz-woo-gallery__thumbsRL-8 .flex-control-thumbs li:nth-child(8n) {
  margin-right: 0; }

.brz .brz-woo-gallery__style-top .woocommerce-product-gallery {
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse; }
  .brz .brz-woo-gallery__style-top .woocommerce-product-gallery .flex-control-thumbs {
    width: 100%; }

.brz .brz-woo-gallery__style-bottom .woocommerce-product-gallery {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .brz .brz-woo-gallery__style-bottom .woocommerce-product-gallery .flex-control-thumbs {
    width: 100%; }

.brz .brz-woo-gallery__style-left .woocommerce-product-gallery {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  .brz .brz-woo-gallery__style-left .woocommerce-product-gallery .flex-control-thumbs {
    height: 100%; }

.brz .brz-woo-gallery__style-right .woocommerce-product-gallery {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }
  .brz .brz-woo-gallery__style-right .woocommerce-product-gallery .flex-control-thumbs {
    height: 100%; }

.brz .brz-woo-add-to-cart {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit; }
  .brz .brz-woo-add-to-cart .hidden {
    display: none; }
  .brz .brz-woo-add-to-cart > div:not(.brz-shortcode__placeholder) {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: inherit;
        -ms-flex-pack: inherit;
            justify-content: inherit; }
  .brz .brz-woo-add-to-cart form.cart > .quantity {
    float: none; }
  .brz .brz-woo-add-to-cart form:not(.variations_form) {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; }
  .brz .brz-woo-add-to-cart form.grouped_form {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
    -webkit-box-pack: inherit;
        -ms-flex-pack: inherit;
            justify-content: inherit; }
    .brz .brz-woo-add-to-cart form.grouped_form .woocommerce-grouped-product-list {
      margin-top: 0; }
      .brz .brz-woo-add-to-cart form.grouped_form .woocommerce-grouped-product-list-item td {
        padding: 0.7em 1em; }
      .brz .brz-woo-add-to-cart form.grouped_form .woocommerce-grouped-product-list-item__label a {
        text-decoration: unset;
        color: inherit; }
  .brz .brz-woo-add-to-cart form.variations_form {
    width: 100%;
    -webkit-box-pack: inherit;
        -ms-flex-pack: inherit;
            justify-content: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .brz .brz-woo-add-to-cart form.variations_form table {
      margin-top: 0; }
    .brz .brz-woo-add-to-cart form.variations_form select {
      background-color: inherit; }
    .brz .brz-woo-add-to-cart form.variations_form table.variations td {
      vertical-align: middle; }
      .brz .brz-woo-add-to-cart form.variations_form table.variations td .reset-variations {
        margin-left: 15px; }
    .brz .brz-woo-add-to-cart form.variations_form .single_variation_wrap {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: inherit;
          -ms-flex-pack: inherit;
              justify-content: inherit; }
      .brz .brz-woo-add-to-cart form.variations_form .single_variation_wrap .single_variation {
        width: 100%;
        margin: 0; }
        .brz .brz-woo-add-to-cart form.variations_form .single_variation_wrap .single_variation > div {
          margin-bottom: 20px; }
    .brz .brz-woo-add-to-cart form.variations_form .variations_button {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex; }
  .brz .brz-woo-add-to-cart .woocommerce-variation-price {
    margin-bottom: 20px; }
  .brz .brz-woo-add-to-cart * {
    -webkit-transition-property: color, background-color, border;
    transition-property: color, background-color, border; }
  .brz .brz-woo-add-to-cart input[type="number"].input-text {
    width: 100%;
    min-height: 100%;
    padding: 0;
    text-align: center;
    outline: none;
    height: auto; }
  .brz .brz-woo-add-to-cart .single_add_to_cart_button {
    margin: 0;
    width: auto;
    min-width: auto;
    min-height: auto;
    max-width: none;
    max-height: none; }

.brz .brz-wooexcerpt {
  width: 100%; }
  .brz .brz-wooexcerpt .woocommerce-product-details__short-description * {
    -webkit-transition-property: color;
    transition-property: color; }
    .brz .brz-wooexcerpt .woocommerce-product-details__short-description *:last-child {
      margin: 0; }
  .brz .brz-wooexcerpt .woocommerce-product-details__short-description ul,
  .brz .brz-wooexcerpt .woocommerce-product-details__short-description ol {
    padding-left: 20px; }
  .brz .brz-wooexcerpt .woocommerce-product-details__short-description > *:not(:last-child) {
    margin-bottom: 0.9rem; }

.brz .brz-animated {
  opacity: 0;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards; }
  .brz .brz-animated:not(.brz-animate) {
    -webkit-animation-name: none;
            animation-name: none; }
  .brz .brz-animated.brz-animate-opacity {
    opacity: 1; }

.brz .admin-bar .wp-env .visual-sidebar {
  top: 32px;
  left: 0; }

.brz .admin-bar .wp-env .visual-wrap-block-wrap {
  margin-left: 0;
  padding-bottom: 0; }

.brz .brz-wp__sidebar {
  width: 100%; }
  .brz .brz-wp__sidebar .brz-shortcode__placeholder {
    width: 100%;
    min-height: 300px; }

.brz .brz-wp-shortcode {
  width: 100%;
  min-height: 20px; }
  .brz .brz-wp-shortcode .woocommerce:empty {
    width: 100%;
    height: 15px; }
  .brz .brz-wp-shortcode .woocommerce-product-gallery {
    opacity: 1 !important; }
  .brz .brz-wp-shortcode .brz-shortcode__placeholder {
    width: 100%; }

.brz .brz-menu__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit;
  width: 100%; }
  .brz .brz-menu__container .brz-mm-menu__icon:hover {
    cursor: pointer; }

.brz .brz-menu__ul {
  padding: 0;
  margin: 0;
  font-family: inherit; }

.brz .brz-menu:not(.brz-mm-menu) {
  position: relative;
  width: 100%;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit; }

.brz .brz-menu .brz-menu__dropdown {
  margin: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1; }
  .brz .brz-menu .brz-menu__dropdown:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .brz .brz-menu .brz-menu__dropdown .brz-menu__item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .brz .brz-menu .brz-menu__dropdown .brz-menu__item:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit; }
  .brz .brz-menu .brz-menu__dropdown .brz-menu__item-dropdown > .brz-a {
    position: relative; }
    .brz .brz-menu .brz-menu__dropdown .brz-menu__item-dropdown > .brz-a:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 23px;
      bottom: 0;
      left: auto;
      width: 7px;
      height: 7px;
      border: 2px solid #fff;
      border-right-style: none;
      border-bottom-style: none;
      margin: auto;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
      -webkit-box-sizing: content-box;
              box-sizing: content-box;
      -webkit-transition: border-color 0.2s linear;
      transition: border-color 0.2s linear; }

.brz .brz-menu .brz-menu__item {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .brz .brz-menu .brz-menu__item > .brz-a {
    color: inherit;
    font-weight: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    font-size: inherit;
    display: inherit;
    -webkit-box-align: inherit;
        -ms-flex-align: inherit;
            align-items: inherit;
    text-decoration: none; }
    .brz .brz-menu .brz-menu__item > .brz-a:hover {
      text-decoration: none; }

.brz .brz-menu .brz-menu__item-dropdown {
  position: relative; }
  .brz .brz-menu .brz-menu__item-dropdown .brz-menu__item {
    padding: 0;
    -webkit-transition: background-color 0.2s linear, border-bottom-color 0.2s linear;
    transition: background-color 0.2s linear, border-bottom-color 0.2s linear; }
    .brz .brz-menu .brz-menu__item-dropdown .brz-menu__item .brz-a {
      width: 100%;
      padding: 15px 35px 15px 15px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word; }

.brz .brz-menu.brz-menu__mmenu:not(.brz-mm-menu) {
  display: none; }

.brz .brz-menu.brz-mm-menu {
  text-transform: initial; }
  .brz .brz-menu.brz-mm-menu.brz-mm-menu_opened {
    display: block; }
  .brz .brz-menu.brz-mm-menu .brz-mm-navbar a:not(.brz-btn),
  .brz .brz-menu.brz-mm-menu .brz-mm-navbar > * {
    color: inherit; }
  .brz .brz-menu.brz-mm-menu .brz-mm-navbar:not(.brz-mm-listitem) {
    height: 44px;
    background-color: transparent; }
  .brz .brz-menu.brz-mm-menu .brz-menu__ul > .brz-mm-navbar {
    height: 44px;
    border-bottom-width: inherit;
    background-color: transparent; }
  .brz .brz-menu.brz-mm-menu .brz-menu__ul .brz-mm-listitem_opened > .brz-mm-panel {
    background-color: rgba(255, 255, 255, 0.05); }
  .brz .brz-menu.brz-mm-menu .brz-menu__ul--has-dropdown .brz-mm-listitem_opened > .brz-mm-panel {
    background-color: transparent; }
  .brz .brz-menu.brz-mm-menu .brz-mm-menu__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: none;
            transform: none;
    padding: 0;
    position: relative;
    left: auto;
    top: auto;
    visibility: visible;
    opacity: 1;
    width: auto; }
    .brz .brz-menu.brz-mm-menu .brz-mm-menu__item.brz-mm-listitem_vertical > .brz-mm-panel {
      padding: 0 10px; }
    .brz .brz-menu.brz-mm-menu .brz-mm-menu__item.brz-mm-menu__item-mega-menu > .brz-mm-panel {
      padding: 0;
      overflow: visible; }
    .brz .brz-menu.brz-mm-menu .brz-mm-menu__item.brz-mm-listitem_opened > .brz-mm-listitem__text {
      position: relative; }
      .brz .brz-menu.brz-mm-menu .brz-mm-menu__item.brz-mm-listitem_opened > .brz-mm-listitem__text:after {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid;
        border-color: inherit; }
    .brz .brz-menu.brz-mm-menu .brz-mm-menu__item .brz-mm-listitem__text {
      color: inherit;
      font-weight: inherit;
      font-family: inherit;
      letter-spacing: inherit;
      line-height: inherit;
      font-size: inherit;
      border-color: inherit;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 10px 20px; }
    .brz .brz-menu.brz-mm-menu .brz-mm-menu__item.brz-mm-menu__item-empty .brz-mm-btn {
      display: none; }
    .brz .brz-menu.brz-mm-menu .brz-mm-menu__item .brz-mm-btn_next {
      right: 0;
      left: auto;
      background: none;
      padding: 0;
      min-width: 50px;
      border: 0;
      color: inherit;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .brz .brz-menu.brz-mm-menu .brz-mm-menu__item .brz-mm-btn_next:after {
        position: relative;
        left: auto;
        right: auto;
        border-color: inherit;
        width: 7px;
        height: 7px; }

.brz .brz-menu .brz-mm-navbar__title {
  line-height: 20px;
  font-size: 13px;
  padding: 12px 20px; }
  .brz .brz-menu .brz-mm-navbar__title:focus, .brz .brz-menu .brz-mm-navbar__title:active {
    outline: none; }

.brz .brz-menu--has-dropdown.brz-mm-menu .brz-mm-navbar {
  padding: 0; }
  .brz .brz-menu--has-dropdown.brz-mm-menu .brz-mm-navbar__title {
    padding: 12px 50px 12px 20px; }

.brz .brz-menu--has-dropdown.brz-mm-menu .brz-mm-menu__item > .brz-mm-listitem__text {
  padding-right: 50px; }

.brz .brz-menu .brz-a {
  width: 100%; }

.brz .brz-menu .brz-a,
.brz .brz-menu .brz-mm-btn_next {
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear; }
  .brz .brz-menu .brz-a:focus, .brz .brz-menu .brz-a:active, .brz .brz-menu .brz-a:hover,
  .brz .brz-menu .brz-mm-btn_next:focus,
  .brz .brz-menu .brz-mm-btn_next:active,
  .brz .brz-menu .brz-mm-btn_next:hover {
    outline: none; }

.brz .brz-menu__error {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  border-radius: 3px;
  background-color: #ececec;
  font-family: "nunito", "Open Sans", Arial, sans-serif;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }
  .brz .brz-menu__error .brz-a {
    font-weight: 700;
    margin-right: 5px;
    cursor: pointer; }

.brz .brz-menu.brz-mm-menu_opened ~ .brz-mm-wrapper__blocker {
  -webkit-transition-delay: 0s;
          transition-delay: 0s; }

.brz .brz-menu__item__content,
.brz .brz-menu__sub-menu__item__content,
.brz .brz-mm-menu__item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.brz .brz-mega-menu__portal {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 1065;
  -webkit-transition: 0.2s linear opacity;
  transition: 0.2s linear opacity; }
  .brz .brz-mega-menu__portal--opened {
    opacity: 1;
    visibility: visible;
    -webkit-transition: 0.2s linear opacity;
    transition: 0.2s linear opacity; }
  .brz .brz-mega-menu__portal[data-popper-placement^="right"], .brz .brz-mega-menu__portal[data-popper-placement^="left"] {
    padding-top: 0;
    padding-bottom: 0; }
  .brz .brz-mega-menu__portal[data-popper-placement^="right"] {
    padding-right: 0; }
  .brz .brz-mega-menu__portal[data-popper-placement^="left"] {
    padding-left: 0; }
  .brz .brz-mega-menu__portal[data-popper-placement^="top"], .brz .brz-mega-menu__portal[data-popper-placement^="bottom"] {
    padding-left: 0;
    padding-right: 0; }
  .brz .brz-mega-menu__portal[data-popper-placement^="top"] {
    padding-top: 0; }
  .brz .brz-mega-menu__portal[data-popper-placement^="bottom"] {
    padding-bottom: 0; }

.brz .brz-menu__dropdown[data-popper-placement^="top"]:before {
  bottom: calc(var(--offset) * -1); }

.brz .brz-menu__dropdown[data-popper-placement^="bottom"]:before {
  top: calc(var(--offset) * -1); }

.brz .brz-menu__dropdown[data-popper-placement^="left"]:before {
  right: calc(var(--offset) * -1); }

.brz .brz-menu__dropdown[data-popper-placement^="right"]:before {
  left: calc(var(--offset) * -1); }

.brz .brz-mega-menu__dropdown {
  width: 100%; }

.brz .brz-mega-menu {
  position: relative; }
  .brz .brz-mega-menu > .brz-bg {
    overflow: hidden; }
  .brz .brz-mega-menu > .brz-container {
    position: relative; }

.brz .brz-mega-menu__mmenu {
  overflow: visible;
  padding: 0; }

.brz:not(.brz-ed) .brz-menu__mmenu:not(.brz-mm-menu_opened) {
  display: none; }

.brz:not(.brz-ed) .brz-menu .brz-menu__item:hover > .brz-menu__sub-menu,
.brz:not(.brz-ed) .brz-menu .brz-menu__item:hover > .brz-mega-menu__portal {
  visibility: visible;
  opacity: 1;
  z-index: 1065;
  -webkit-transition: 0.2s z-index linear, 0.2s opacity linear;
  transition: 0.2s z-index linear, 0.2s opacity linear; }

.brz:not(.brz-ed) .brz-menu:not(.brz-menu--has-dropdown) .brz-mm-navbar__title {
  padding-left: 0;
  padding-right: 0; }

.brz:not(.brz-ed) .brz-menu .brz-mm-panel_has-navbar {
  padding-top: 44px; }

.brz:not(.brz-ed) .brz-mega-menu__portal {
  z-index: 1071; }

.brz .brz-root__container.brz-mm-page {
  position: relative;
  background: inherit; }

.brz .brz-root__container.brz-mm-slideout {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease; }

.brz .brz-mm-menu_position-bottom.brz-mm-menu_opened,
.brz .brz-mm-menu_position-front.brz-mm-menu_opened,
.brz .brz-mm-menu_position-top.brz-mm-menu_opened {
  z-index: 4; }

.brz .brz-mm-menu_position-bottom.brz-mm-menu_opened ~ .brz-mm-wrapper__blocker,
.brz .brz-mm-menu_position-front.brz-mm-menu_opened ~ .brz-mm-wrapper__blocker,
.brz .brz-mm-menu_position-top.brz-mm-menu_opened ~ .brz-mm-wrapper__blocker {
  z-index: 3; }

.brz.brz-mm-wrapper:not(.brz-mm-wrapper_blocking) {
  overflow: visible; }

.brz.brz-mm-wrapper_opened .brz-root__container.brz-mm-page {
  z-index: auto; }

.brz.brz-mm-wrapper_opened:not(.brz-ed) .brz-root__container > .brz-section:not(.brz-section__header) {
  z-index: 1; }

.brz.brz-mm-wrapper_opened .brz-popup,
.brz.brz-mm-wrapper_opened .brz-popup2,
.brz.brz-mm-wrapper_opened .brz-section__header,
.brz.brz-mm-wrapper_opened .brz-mega-menu__portal {
  z-index: 2; }
