/*-------------------------
    Fonts
---------------------------*/
@font-face {
    font-family: 'Nunito Bold';
    src: url('../fonts/Nunito-Bold.woff2') format('woff2'),
        url('../fonts/Nunito-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Black';
    src: url('../fonts/Nunito-Black.woff2') format('woff2'),
        url('../fonts/Nunito-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito ExtraBold';
    src: url('../fonts/Nunito-ExtraBold.woff2') format('woff2'),
        url('../fonts/Nunito-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Regular';
    src: url('../fonts/Nunito-Regular.woff2') format('woff2'),
        url('../fonts/Nunito-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito ExtraLight';
    src: url('../fonts/Nunito-ExtraLight.woff2') format('woff2'),
        url('../fonts/Nunito-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Light';
    src: url('../fonts/Nunito-Light.woff2') format('woff2'),
        url('../fonts/Nunito-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito SemiBold';
    src: url('../fonts/Nunito-SemiBold.woff2') format('woff2'),
        url('../fonts/Nunito-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Medium';
    src: url('../fonts/Nunito-Medium.woff2') format('woff2'),
        url('../fonts/Nunito-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.white-space-normal{
    white-space: normal!important;
}

.productDetailModal  .modal-dialog {
    margin-top: 60px!important;
}
 

.moreInfoModal  .modal-dialog {
    margin-top: 60px!important;
}
.productDetailModal h3{
    font-size: 18px;
    color: #545454;
    margin-bottom: 0;
}

.firearm-home{
    color: rgb(255, 255, 255); font-size: 46px; font-weight: 400;
}

.productDetailModal  .modal-header{
    background-color: #FAFAFA;
    padding: 20px;
    border-bottom: 1px solid #E3E3E3;
}


.productDetailModal  .modal-body{
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
}

.moreInfoModal  .modal-header{
    background-color: #FAFAFA;
    padding: 20px;
    border-bottom: 1px solid #E3E3E3;
}


.moreInfoModal  .modal-body{
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
}


 

 .modal-content .modal-close {
    display: block;
    position: absolute;
    right: -12px;
    top: -10px;
    cursor: pointer;
    font-size: 16px;
    z-index: 1000;
    background-color: #454545;
    color: #FFF;
    border-radius: 100px;
    width: 25px;
    height: 25px;
    text-align: center;
}

.brz .css-dwoi7t, .brz [data-css-dwoi7t] {color: rgba(249,157,0,1);border-color: rgba(35,157,219,0);background-color: rgba(189,225,244,0);background-image: none;border-width: 0;border-style: solid;width: 90px;height: 90px;font-size: 90px;padding: 0px;border-radius: 0;stroke-width: 3;transition: all .5s ease-in-out;transition-property: background,border-radius,color,border-color,box-shadow;-webkit-transition: all .5s ease-in-out;-moz-transition: all .5s ease-in-out;-webkit-transition-property: background,border-radius,color,border-color,box-shadow;-moz-transition-property: background,border-radius,color,border-color,box-shadow;}
@media (min-width: 991px) {.brz .css-dwoi7t:hover, .brz [data-css-dwoi7t]:hover {color: rgba(249,157,0,1);border-color: rgba(249,157,0,1);background-color: rgba(189,225,244,0);background-image: none;}}
@media (max-width: 991px) {.brz .css-dwoi7t, .brz [data-css-dwoi7t] {width: 90px;height: 90px;font-size: 90px;padding: 0px;border-radius: 0px;stroke-width: 3;}}
@media (max-width: 767px) {.brz .css-dwoi7t, .brz [data-css-dwoi7t] {width: 90px;height: 90px;font-size: 90px;padding: 0px;border-radius: 0px;stroke-width: 3;}}
.brz .css-ysl5hk, .brz [data-css-ysl5hk] {color: rgba(249,157,0,1);border-color: rgba(35,157,219,0);background-color: rgba(189,225,244,0);background-image: none;border-width: 0;border-style: solid;width: 90px;height: 90px;font-size: 90px;padding: 0px;border-radius: 0;stroke-width: 3;transition: all .5s ease-in-out;transition-property: background,border-radius,color,border-color,box-shadow;-webkit-transition: all .5s ease-in-out;-moz-transition: all .5s ease-in-out;-webkit-transition-property: background,border-radius,color,border-color,box-shadow;-moz-transition-property: background,border-radius,color,border-color,box-shadow;}
@media (min-width: 991px) {.brz .css-ysl5hk:hover, .brz [data-css-ysl5hk]:hover {color: rgba(249,157,0,1);border-color: rgba(35,157,219,0);background-color: rgba(189,225,244,0);background-image: none;}}
@media (max-width: 991px) {.brz .css-ysl5hk, .brz [data-css-ysl5hk] {width: 90px;height: 90px;font-size: 90px;padding: 0px;border-radius: 0px;stroke-width: 3;}}
@media (max-width: 767px) {.brz .css-ysl5hk, .brz [data-css-ysl5hk] {width: 90px;height: 90px;font-size: 90px;padding: 0px;border-radius: 0px;stroke-width: 3;}}
.brz .css-1ifvui7, .brz [data-css-1ifvui7] {color: rgba(249,157,0,1);border-color: rgba(255,255,255,1);background-color: rgba(255,255,255,1);background-image: none;border-width: 3px;border-style: solid;width: 128px;height: 128px;font-size: 48px;padding: 37px;border-radius: 64px;stroke-width: 0;transition: all .5s ease-in-out;transition-property: background,border-radius,color,border-color,box-shadow;-webkit-transition: all .5s ease-in-out;-moz-transition: all .5s ease-in-out;-webkit-transition-property: background,border-radius,color,border-color,box-shadow;-moz-transition-property: background,border-radius,color,border-color,box-shadow;}
@media (min-width: 991px) {.brz .css-1ifvui7:hover, .brz [data-css-1ifvui7]:hover {color: rgba(255,255,255,.8);border-color: rgba(234,246,227,1);background-color: rgba(249,157,0,1);background-image: none;}}
@media (max-width: 991px) {.brz .css-1ifvui7, .brz [data-css-1ifvui7] {width: 128px;height: 128px;font-size: 48px;padding: 37px;border-radius: 64px;stroke-width: 0;}}
@media (max-width: 767px) {.brz .css-1ifvui7, .brz [data-css-1ifvui7] {width: 128px;height: 128px;font-size: 48px;padding: 37px;border-radius: 64px;stroke-width: 0;}}
.brz .css-1v865o6, .brz [data-css-1v865o6] {color: rgba(249,157,0,1);border-color: rgba(35,157,219,0);background-color: rgba(253,253,253,1);background-image: none;border-width: 0;border-style: solid;width: 40px;height: 40px;font-size: 20px;padding: 10px;border-radius: 23px;stroke-width: 0;transition: all .5s ease-in-out;transition-property: background,border-radius,color,border-color,box-shadow;-webkit-transition: all .5s ease-in-out;-moz-transition: all .5s ease-in-out;-webkit-transition-property: background,border-radius,color,border-color,box-shadow;-moz-transition-property: background,border-radius,color,border-color,box-shadow;}
@media (min-width: 991px) {.brz .css-1v865o6:hover, .brz [data-css-1v865o6]:hover {color: rgba(255,255,255,.8);border-color: rgba(96,180,32,1);background-color: rgba(96,180,32,1);background-image: none;}}
@media (max-width: 991px) {.brz .css-1v865o6, .brz [data-css-1v865o6] {width: 40px;height: 40px;font-size: 20px;padding: 10px;border-radius: 23px;stroke-width: 0;}}
    
.brz .brz-cp-color1, .brz .brz-bcp-color1 {color: #f99d00;}
.brz .brz-cp-color2, .brz .brz-bcp-color2 {color: #26232a;}
.brz .brz-cp-color3, .brz .brz-bcp-color3 {color: #60b420;}
.brz .brz-cp-color4, .brz .brz-bcp-color4 {color: #fdfdfd;}
.brz .brz-cp-color5, .brz .brz-bcp-color5 {color: #9e47da;}
.brz .brz-cp-color6, .brz .brz-bcp-color6 {color: #eaf6e3;}
.brz .brz-cp-color7, .brz .brz-bcp-color7 {color: #6f6d70;}
.brz .brz-cp-color8, .brz .brz-bcp-color8 {color: #fff;}

.brz .brz-css-vugti {z-index: auto;margin: 0;}
.brz .brz-css-vugti.brz-section .brz-section__content {min-height: auto;display: flex;}
.brz .brz-css-vugti .brz-container {justify-content: center;}
.brz .brz-css-vugti > .slick-slider > .brz-slick-slider__dots {color: rgba(0,0,0,1);}
.brz .brz-css-vugti > .slick-slider > .brz-slick-slider__arrow {color: rgba(0,0,0,.7);}
@media (min-width:991px) {.brz .brz-css-vugti {display: block;}}
@media (min-width:991px) {.brz .brz-css-vugti > .slick-slider > .brz-slick-slider__arrow:hover {color: rgba(0,0,0,1);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-vugti {z-index: auto;margin: 0;}
.brz .brz-css-vugti.brz-section .brz-section__content {min-height: auto;display: flex;}
.brz .brz-css-vugti .brz-container {justify-content: center;}
.brz .brz-css-vugti > .slick-slider > .brz-slick-slider__dots {color: rgba(0,0,0,1);}
.brz .brz-css-vugti > .slick-slider > .brz-slick-slider__arrow {color: rgba(0,0,0,.7);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-vugti {display: block;}}
@media (max-width:767px) {.brz .brz-css-vugti {z-index: auto;margin: 0;}
.brz .brz-css-vugti.brz-section .brz-section__content {min-height: auto;display: flex;}
.brz .brz-css-vugti .brz-container {justify-content: center;}
.brz .brz-css-vugti > .slick-slider > .brz-slick-slider__dots {color: rgba(0,0,0,1);}
.brz .brz-css-vugti > .slick-slider > .brz-slick-slider__arrow {color: rgba(0,0,0,.7);}}
@media (max-width:767px) {.brz .brz-css-vugti {display: block;}}
.brz .brz-css-mwhob {padding: 75px 0px 75px 0px;}
.brz .brz-css-mwhob > .brz-bg {border: 0px solid rgba(102,115,141,0);border-radius: 0px;mix-blend-mode: normal;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-image {background-image: none;display: block;background-size: cover;background-repeat: no-repeat;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-image:after {content: "";background-image: none;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);background-image: none;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-map {display: none;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-video {display: none;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-shape__top {background-image: none;background-size: 100% 100px;height: 100px;transform: rotateX(0deg) rotateY(0deg);z-index: auto;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-shape__bottom {background-image: none;background-size: 100% 100px;height: 100px;transform: rotateX(-180deg) rotateY(-180deg);z-index: auto;}
@media (min-width:991px) {.brz .brz-css-mwhob > .brz-bg > .brz-bg-image {background-attachment: scroll;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-mwhob {padding: 50px 15px 50px 15px;}
.brz .brz-css-mwhob > .brz-bg {border: 0px solid rgba(102,115,141,0);border-radius: 0px;mix-blend-mode: normal;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-image {background-image: none;display: block;background-size: cover;background-repeat: no-repeat;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-image:after {content: "";background-image: none;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);background-image: none;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-map {display: none;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-video {display: none;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-shape__top {background-image: none;background-size: 100% 100px;height: 100px;transform: rotateX(0deg) rotateY(0deg);z-index: auto;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-shape__bottom {background-image: none;background-size: 100% 100px;height: 100px;transform: rotateX(-180deg) rotateY(-180deg);z-index: auto;}}
@media (max-width:767px) {.brz .brz-css-mwhob {padding: 25px 15px 25px 15px;}
.brz .brz-css-mwhob > .brz-bg {border: 0px solid rgba(102,115,141,0);border-radius: 0px;mix-blend-mode: normal;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-image {background-image: none;display: block;background-size: cover;background-repeat: no-repeat;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-image:after {content: "";background-image: none;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);background-image: none;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-map {display: none;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-video {display: none;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-shape__top {background-image: none;background-size: 100% 100px;height: 100px;transform: rotateX(0deg) rotateY(0deg);z-index: auto;}
.brz .brz-css-mwhob > .brz-bg > .brz-bg-shape__bottom {background-image: none;background-size: 100% 100px;height: 100px;transform: rotateX(-180deg) rotateY(-180deg);z-index: auto;}}
.brz .brz-css-pbhem {border: 0px solid transparent;}
@media (min-width:991px) {.brz .brz-css-pbhem {max-width: calc(1 * var(--brz-section-container-max-width,1170px));}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-pbhem {border: 0px solid transparent;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-pbhem {max-width: 100%;}}
@media (max-width:767px) {.brz .brz-css-pbhem {border: 0px solid transparent;}}
@media (max-width:767px) {.brz .brz-css-pbhem {max-width: 100%;}}
.brz .brz-css-fgwvm {margin: 0;z-index: auto;align-items: flex-start;}
.brz .brz-css-fgwvm > .brz-bg {border: 0px solid rgba(102,115,141,0);border-radius: 0px;max-width: 100%;mix-blend-mode: normal;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-image {background-image: none;display: block;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-image:after {content: "";background-image: none;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);background-image: none;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-map {display: none;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-video {display: none;}
.brz .brz-css-fgwvm > .brz-row {border: 0px solid transparent;}
@media (min-width:991px) {.brz .brz-css-fgwvm {min-height: auto;display: flex;}
.brz .brz-css-fgwvm > .brz-bg {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-image {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-color {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-video {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-fgwvm > .brz-row {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-fgwvm {margin: 0;z-index: auto;align-items: flex-start;}
.brz .brz-css-fgwvm > .brz-bg {border: 0px solid rgba(102,115,141,0);border-radius: 0px;max-width: 100%;mix-blend-mode: normal;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-image {background-image: none;display: block;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-image:after {content: "";background-image: none;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);background-image: none;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-map {display: none;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-video {display: none;}
.brz .brz-css-fgwvm > .brz-row {border: 0px solid transparent;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-fgwvm {min-height: auto;display: flex;}
.brz .brz-css-fgwvm > .brz-bg {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-image {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-color {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-video {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-fgwvm > .brz-row {flex-direction: row;flex-wrap: wrap;justify-content: flex-start;transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}}
@media (max-width:767px) {.brz .brz-css-fgwvm {margin: 0;z-index: auto;align-items: flex-start;}
.brz .brz-css-fgwvm > .brz-bg {border: 0px solid rgba(102,115,141,0);border-radius: 0px;max-width: 100%;mix-blend-mode: normal;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-image {background-image: none;display: block;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-image:after {content: "";background-image: none;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);background-image: none;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-map {display: none;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-video {display: none;}
.brz .brz-css-fgwvm > .brz-row {border: 0px solid transparent;}}
@media (max-width:767px) {.brz .brz-css-fgwvm {min-height: auto;display: flex;}
.brz .brz-css-fgwvm > .brz-bg {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-image {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-color {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-fgwvm > .brz-bg > .brz-bg-video {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-fgwvm > .brz-row {flex-direction: row;flex-wrap: wrap;justify-content: flex-start;transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}}
.brz .brz-css-vxblm {padding: 10px;max-width: 100%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-vxblm {padding: 0;max-width: 100%;}}
@media (max-width:767px) {.brz .brz-css-vxblm {padding: 0;max-width: 100%;}}
.brz .brz-css-dynog {z-index: auto;flex: 1 1 50%;max-width: 50%;justify-content: flex-start;}
.brz .brz-css-dynog > .brz-bg {border: 0px solid rgba(102,115,141,0);border-radius: 0px;margin: 0;mix-blend-mode: normal;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-image {background-image: none;display: block;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-image:after {content: "";background-image: none;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);background-image: none;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-map {display: none;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-video {display: none;}
@media (min-width:991px) {.brz .brz-css-dynog > .brz-bg {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-image {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-color {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-dynog {z-index: auto;flex: 1 1 50%;max-width: 50%;justify-content: flex-start;}
.brz .brz-css-dynog > .brz-bg {border: 0px solid rgba(102,115,141,0);border-radius: 0px;margin: 0;mix-blend-mode: normal;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-image {background-image: none;display: block;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-image:after {content: "";background-image: none;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);background-image: none;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-map {display: none;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-video {display: none;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-dynog > .brz-bg {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-image {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-color {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}}
@media (max-width:767px) {.brz .brz-css-dynog {z-index: auto;flex: 1 1 100%;max-width: 100%;justify-content: flex-start;}
.brz .brz-css-dynog > .brz-bg {border: 0px solid rgba(102,115,141,0);border-radius: 0px;margin: 10px 0px 10px 0px;mix-blend-mode: normal;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-image {background-image: none;display: block;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-image:after {content: "";background-image: none;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);background-image: none;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-map {display: none;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-video {display: none;}}
@media (max-width:767px) {.brz .brz-css-dynog > .brz-bg {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-image {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}
.brz .brz-css-dynog > .brz-bg > .brz-bg-color {transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}}
.brz .brz-css-ecksy {z-index: auto;margin: 0;border: 0px solid transparent;padding: 5px 15px 5px 15px;min-height: auto;}
@media (min-width:991px) {.brz .brz-css-ecksy {display: flex;transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ecksy {z-index: auto;margin: 0;border: 0px solid transparent;padding: 5px 15px 5px 15px;min-height: auto;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ecksy {display: flex;transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}}
@media (max-width:767px) {.brz .brz-css-ecksy {z-index: auto;margin: 10px 0px 10px 0px;border: 0px solid transparent;padding: 0;min-height: auto;}}
@media (max-width:767px) {.brz .brz-css-ecksy {display: flex;transition-duration: .5s;transition-property: filter,box-shadow,background,border-radius,border-color;}}
.brz .brz-css-ouobs {padding: 0;margin: 10px 0px 10px 0px;justify-content: center;position: relative;}
@media (min-width:991px) {.brz .brz-css-ouobs {display: flex;z-index: auto;position: relative;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ouobs {padding: 0;margin: 10px 0px 10px 0px;justify-content: center;position: relative;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ouobs {display: flex;z-index: auto;position: relative;}}
@media (max-width:767px) {.brz .brz-css-ouobs {padding: 0;margin: 10px 0px 10px 0px;justify-content: center;position: relative;}}
@media (max-width:767px) {.brz .brz-css-ouobs {display: flex;z-index: auto;position: relative;}}
.brz .brz-css-kfgvz {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-kfgvz:after {background-color: rgba(255,255,255,0);background-image: none;}
@media (min-width:991px) {.brz .brz-css-kfgvz {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-kfgvz:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-kfgvz .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-kfgvz {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-kfgvz:after {background-color: rgba(255,255,255,0);background-image: none;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-kfgvz {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-kfgvz:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-kfgvz .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
@media (max-width:767px) {.brz .brz-css-kfgvz {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-kfgvz:after {background-color: rgba(255,255,255,0);background-image: none;}}
@media (max-width:767px) {.brz .brz-css-kfgvz {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-kfgvz:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-kfgvz .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
.brz .brz-css-bwlsg {padding-top: 59.0055%;}
.brz .brz-css-bwlsg > .brz-img {position: absolute;width: 100%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-bwlsg {padding-top: 59.001%;}
.brz .brz-css-bwlsg > .brz-img {position: absolute;width: 100%;}}
@media (max-width:767px) {.brz .brz-css-bwlsg {padding-top: 59.0078%;}
.brz .brz-css-bwlsg > .brz-img {position: absolute;width: 100%;}}
.brz .brz-css-rmcic {width: 100%;mix-blend-mode: normal;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-rmcic {width: 100%;mix-blend-mode: normal;}}
@media (max-width:767px) {.brz .brz-css-rmcic {width: 100%;mix-blend-mode: normal;}}
.brz .brz-css-tjzpo {height: 50px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-tjzpo {height: 50px;}}
@media (max-width:767px) {.brz .brz-css-tjzpo {height: 50px;}}
.brz .brz-css-pjcne .brz-form {margin: -0px -7.5px -15px -7.5px;}
.brz .brz-css-pjcne .brz-forms2__item {padding: 0px 7.5px 15px 7.5px;}
.brz .brz-css-pjcne .brz-forms2__item-button {margin-right: auto;margin-left: 0;max-width: 100%;flex-basis: 100%;}
.brz .brz-css-pjcne .brz-forms2-story .brz-btn:before {content: "";padding-top: 15%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-pjcne .brz-form {margin: -0px -7.5px -15px -7.5px;}
.brz .brz-css-pjcne .brz-forms2__item {padding: 0px 7.5px 15px 7.5px;}
.brz .brz-css-pjcne .brz-forms2__item-button {margin-right: auto;margin-left: 0;max-width: 100%;flex-basis: 100%;}
.brz .brz-css-pjcne .brz-forms2-story .brz-btn:before {content: "";padding-top: 15%;}}
@media (max-width:767px) {.brz .brz-css-pjcne .brz-form {margin: -0px -7.5px -15px -7.5px;}
.brz .brz-css-pjcne .brz-forms2__item {padding: 0px 7.5px 15px 7.5px;}
.brz .brz-css-pjcne .brz-forms2__item-button {margin-right: auto;margin-left: 0;max-width: 100%;flex-basis: 100%;}
.brz .brz-css-pjcne .brz-forms2-story .brz-btn:before {content: "";padding-top: 15%;}}
.brz .brz-css-opplj {color: rgba(115,119,127,.7); font-family: 'Nunito Regular';font-size: 18px;font-weight: 400;letter-spacing: 0px;}
.brz .brz-css-opplj .brz-forms2__field-label {color: rgba(115,119,127,.7); font-family: 'Nunito Regular';font-size: 16px;font-weight: 400;letter-spacing: 0px;text-align: left;line-height: 1.5;}
.brz .brz-css-opplj .brz-forms2__field-label {padding: 0px 0px 5px 0px;}
.brz .brz-css-opplj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {color: rgba(115,119,127,.7);background-color: rgba(255,255,255,1);border: 1px solid rgba(220,222,225,1);border-radius: 0px;min-height: 57px;}
.brz .brz-css-opplj.brz-forms2__item--error .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {border-color: #f00;}
.brz .brz-css-opplj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox):not(.brz-forms2__field-select) {padding: 14px 24px 14px 24px;}
.brz .brz-css-opplj .brz-forms2__field-paragraph {line-height: 1.5;}
.brz .brz-css-opplj .brz-forms2__radio {color: rgba(115,119,127,.7); font-family: 'Nunito Regular';font-size: 16px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
.brz .brz-css-opplj .brz-forms2__checkbox {color: rgba(115,119,127,.7); font-family: 'Nunito Regular';font-size: 16px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
.brz .brz-css-opplj .brz-forms2__select-item__input {color: rgba(115,119,127,.7);}
.brz .brz-css-opplj .form-alert { font-family: 'Nunito Regular';}
@media (min-width:991px) {.brz .brz-css-opplj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {transition-duration: .5s;}
.brz .brz-css-opplj .brz-forms2__radio {transition-duration: .5s;}
.brz .brz-css-opplj .brz-forms2__checkbox {transition-duration: .5s;}
.brz .brz-css-opplj .brz-forms2__select-item__input {transition-duration: .5s;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-opplj {color: rgba(115,119,127,.7); font-family: 'Nunito Regular';font-size: 14px;font-weight: 400;letter-spacing: 0px;}
.brz .brz-css-opplj .brz-forms2__field-label {color: rgba(115,119,127,.7); font-family: 'Nunito Regular';font-size: 14px;font-weight: 400;letter-spacing: 0px;text-align: left;line-height: 1.5;}
.brz .brz-css-opplj .brz-forms2__field-label {padding: 0px 0px 5px 0px;}
.brz .brz-css-opplj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {color: rgba(115,119,127,.7);background-color: rgba(255,255,255,1);border: 1px solid rgba(220,222,225,1);border-radius: 0px;min-height: 51px;}
.brz .brz-css-opplj.brz-forms2__item--error .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {border-color: #f00;}
.brz .brz-css-opplj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox):not(.brz-forms2__field-select) {padding: 14px 24px 14px 24px;}
.brz .brz-css-opplj .brz-forms2__field-paragraph {line-height: 1.5;}
.brz .brz-css-opplj .brz-forms2__radio {color: rgba(115,119,127,.7); font-family: 'Nunito Regular';font-size: 14px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
.brz .brz-css-opplj .brz-forms2__checkbox {color: rgba(115,119,127,.7); font-family: 'Nunito Regular';font-size: 14px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
.brz .brz-css-opplj .brz-forms2__select-item__input {color: rgba(115,119,127,.7);}
.brz .brz-css-opplj .form-alert { font-family: 'Nunito Regular';}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-opplj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {transition-duration: .5s;}
.brz .brz-css-opplj .brz-forms2__radio {transition-duration: .5s;}
.brz .brz-css-opplj .brz-forms2__checkbox {transition-duration: .5s;}
.brz .brz-css-opplj .brz-forms2__select-item__input {transition-duration: .5s;}}
@media (max-width:767px) {.brz .brz-css-opplj {color: rgba(115,119,127,.7); font-family: 'Nunito Regular';font-size: 14px;font-weight: 400;letter-spacing: 0px;}
.brz .brz-css-opplj .brz-forms2__field-label {color: rgba(115,119,127,.7); font-family: 'Nunito Regular';font-size: 14px;font-weight: 400;letter-spacing: 0px;text-align: left;line-height: 1.5;}
.brz .brz-css-opplj .brz-forms2__field-label {padding: 0px 0px 5px 0px;}
.brz .brz-css-opplj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {color: rgba(115,119,127,.7);background-color: rgba(255,255,255,1);border: 1px solid rgba(220,222,225,1);border-radius: 0px;min-height: 43px;}
.brz .brz-css-opplj.brz-forms2__item--error .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {border-color: #f00;}
.brz .brz-css-opplj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox):not(.brz-forms2__field-select) {padding: 10px 20px 10px 20px;}
.brz .brz-css-opplj .brz-forms2__field-paragraph {line-height: 1.5;}
.brz .brz-css-opplj .brz-forms2__radio {color: rgba(115,119,127,.7); font-family: 'Nunito Regular';font-size: 14px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
.brz .brz-css-opplj .brz-forms2__checkbox {color: rgba(115,119,127,.7); font-family: 'Nunito Regular';font-size: 14px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
.brz .brz-css-opplj .brz-forms2__select-item__input {color: rgba(115,119,127,.7);}
.brz .brz-css-opplj .form-alert { font-family: 'Nunito Regular';}}
@media (max-width:767px) {.brz .brz-css-opplj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {transition-duration: .5s;}
.brz .brz-css-opplj .brz-forms2__radio {transition-duration: .5s;}
.brz .brz-css-opplj .brz-forms2__checkbox {transition-duration: .5s;}
.brz .brz-css-opplj .brz-forms2__select-item__input {transition-duration: .5s;}}
.brz .brz-css-jqqlb .select2-results__options { font-family: 'Nunito Regular';font-size: 18px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;color: rgba(115,119,127,.7);background-color: rgba(255,255,255,1);border-radius: 0px;}
.brz .brz-css-jqqlb .select2-results__option {border: 1px solid rgba(220,222,225,1);}
.brz .brz-css-jqqlb .select2-selection--single {padding: 14px 24px 14px 24px;}
.brz .brz-css-jqqlb .select2-selection--multiple {padding: 14px 24px 14px 24px;}
.brz .brz-css-jqqlb .select2-selection--multiple .select2-selection__choice {background-color: rgba(255,255,255,.2);}
@media (min-width:991px) {.brz .brz-css-jqqlb .select2-selection--multiple .select2-selection__choice {transition-duration: .5s;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-jqqlb .select2-results__options { font-family: 'Nunito Regular';font-size: 14px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;color: rgba(115,119,127,.7);background-color: rgba(255,255,255,1);border-radius: 0px;}
.brz .brz-css-jqqlb .select2-results__option {border: 1px solid rgba(220,222,225,1);}
.brz .brz-css-jqqlb .select2-selection--single {padding: 14px 24px 14px 24px;}
.brz .brz-css-jqqlb .select2-selection--multiple {padding: 14px 24px 14px 24px;}
.brz .brz-css-jqqlb .select2-selection--multiple .select2-selection__choice {background-color: rgba(255,255,255,.2);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-jqqlb .select2-selection--multiple .select2-selection__choice {transition-duration: .5s;}}
@media (max-width:767px) {.brz .brz-css-jqqlb .select2-results__options { font-family: 'Nunito Regular';font-size: 14px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;color: rgba(115,119,127,.7);background-color: rgba(255,255,255,1);border-radius: 0px;}
.brz .brz-css-jqqlb .select2-results__option {border: 1px solid rgba(220,222,225,1);}
.brz .brz-css-jqqlb .select2-selection--single {padding: 10px 20px 10px 20px;}
.brz .brz-css-jqqlb .select2-selection--multiple {padding: 10px 20px 10px 20px;}
.brz .brz-css-jqqlb .select2-selection--multiple .select2-selection__choice {background-color: rgba(255,255,255,.2);}}
@media (max-width:767px) {.brz .brz-css-jqqlb .select2-selection--multiple .select2-selection__choice {transition-duration: .5s;}}
.brz .brz-css-kxrqo {max-width: 100%;flex-basis: 100%;}
.brz .brz-css-kxrqo .brz-textarea {height: auto;}
.brz .brz-css-kxrqo .brz-forms2__checkbox-option {flex-basis: 100%;}
.brz .brz-css-kxrqo .brz-forms2__radio-option {flex-basis: 100%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-kxrqo {max-width: 100%;flex-basis: 100%;}
.brz .brz-css-kxrqo .brz-textarea {height: auto;}
.brz .brz-css-kxrqo .brz-forms2__checkbox-option {flex-basis: 100%;}
.brz .brz-css-kxrqo .brz-forms2__radio-option {flex-basis: 100%;}}
@media (max-width:767px) {.brz .brz-css-kxrqo {max-width: 100%;flex-basis: 100%;}
.brz .brz-css-kxrqo .brz-textarea {height: auto;}
.brz .brz-css-kxrqo .brz-forms2__checkbox-option {flex-basis: 100%;}
.brz .brz-css-kxrqo .brz-forms2__radio-option {flex-basis: 100%;}}
.brz .brz-css-wmnit.brz-btn {display: flex;align-items: center;justify-content: center;font-family: 'Nunito Regular';font-weight: 700;font-size: 15px;line-height: 1.6;letter-spacing: .5px;color: rgba(255,255,255,1);border: 2px solid rgba(96,180,32,0);border-radius: 2px;background-color: rgba(96,180,32,1);background-image: none;padding: 14px 42px 14px 42px;flex-flow: row-reverse nowrap;}
.brz .brz-css-wmnit.brz-btn.brz-btn-submit {color: rgba(255,255,255,1);background-color: rgba(96,180,32,1);}
.brz .brz-css-wmnit .brz-btn--story-container {border: 2px solid rgba(96,180,32,0);flex-flow: row-reverse nowrap;border-radius: 2px;}
@media (min-width:991px) {.brz .brz-css-wmnit.brz-btn {transition-duration: .5s;transition-property: color,box-shadow,background,border-color;}}
@media (min-width:991px) {.brz .brz-css-wmnit.brz-btn:hover {background-color: rgba(96,180,32,.8);}
.brz .brz-css-wmnit.brz-btn.brz-btn-submit:hover {background-color: rgba(96,180,32,.8);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-wmnit.brz-btn {display: flex;align-items: center;justify-content: center;font-family: 'Nunito Regular';font-weight: 700;font-size: 15px;line-height: 1.6;letter-spacing: .5px;color: rgba(255,255,255,1);border: 2px solid rgba(96,180,32,0);border-radius: 2px;background-color: rgba(96,180,32,1);background-image: none;padding: 11px 26px 11px 26px;flex-flow: row-reverse nowrap;}
.brz .brz-css-wmnit.brz-btn.brz-btn-submit {color: rgba(255,255,255,1);background-color: rgba(96,180,32,1);}
.brz .brz-css-wmnit .brz-btn--story-container {border: 2px solid rgba(96,180,32,0);flex-flow: row-reverse nowrap;border-radius: 2px;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-wmnit.brz-btn {transition-duration: .5s;transition-property: color,box-shadow,background,border-color;}}
@media (max-width:767px) {.brz .brz-css-wmnit.brz-btn {display: flex;align-items: center;justify-content: center;font-family: 'Nunito Regular';font-weight: 700;font-size: 15px;line-height: 1.6;letter-spacing: .5px;color: rgba(255,255,255,1);border: 2px solid rgba(96,180,32,0);border-radius: 2px;background-color: rgba(96,180,32,1);background-image: none;padding: 11px 26px 11px 26px;flex-flow: row-reverse nowrap;}
.brz .brz-css-wmnit.brz-btn.brz-btn-submit {color: rgba(255,255,255,1);background-color: rgba(96,180,32,1);}
.brz .brz-css-wmnit .brz-btn--story-container {border: 2px solid rgba(96,180,32,0);flex-flow: row-reverse nowrap;border-radius: 2px;}}
@media (max-width:767px) {.brz .brz-css-wmnit.brz-btn {transition-duration: .5s;transition-property: color,box-shadow,background,border-color;}}
.brz .brz-css-vtush {z-index: auto;position: relative;margin: 10px 0px 10px 0px;}
@media (min-width:991px) {.brz .brz-css-vtush {display: flex;position: relative;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-vtush {z-index: auto;position: relative;margin: 10px 0px 10px 0px;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-vtush {display: flex;position: relative;}}
@media (max-width:767px) {.brz .brz-css-vtush {z-index: auto;position: relative;margin: 10px 0px 10px 0px;}}
@media (max-width:767px) {.brz .brz-css-vtush {display: flex;position: relative;}}
.brz .brz-css-dvibp {justify-content: center;padding: 0;margin: 0px -5px -20px -5px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-dvibp {justify-content: center;padding: 0;margin: 0px -5px -20px -5px;}}
@media (max-width:767px) {.brz .brz-css-dvibp {justify-content: center;padding: 0;margin: 0px -5px -20px -5px;}}
.brz .brz-css-advch {padding: 0px 5px 20px 5px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-advch {padding: 0px 5px 20px 5px;}}
@media (max-width:767px) {.brz .brz-css-advch {padding: 0px 5px 20px 5px;}}
.brz .brz-css-meunc > .brz-bg > .brz-bg-image {background-image: none;}
.brz .brz-css-meunc > .brz-bg > .brz-bg-image:after {content: "";background-image: none;}
.brz .brz-css-meunc > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);background-image: none;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-meunc > .brz-bg > .brz-bg-image {background-image: none;}
.brz .brz-css-meunc > .brz-bg > .brz-bg-image:after {content: "";background-image: none;}
.brz .brz-css-meunc > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);background-image: none;}}
@media (max-width:767px) {.brz .brz-css-meunc > .brz-bg > .brz-bg-image {background-image: none;}
.brz .brz-css-meunc > .brz-bg > .brz-bg-image:after {content: "";background-image: none;}
.brz .brz-css-meunc > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);background-image: none;}}
.brz .brz-css-xfwdq {justify-content: center;align-items: center;}
.brz .brz-css-xfwdq > .brz-container__wrap {width: 1170px;}
.brz .brz-css-xfwdq > .brz-container__wrap .brz-popup2__close {display: block;top: -36px;right: -36px;left: auto;bottom: auto;color: rgba(255,255,255,1);}
.brz .brz-css-xfwdq > .brz-container__wrap .brz-popup2__close .brz-icon-svg {font-size: 16px;padding: 0px;background-color: rgba(0,0,0,0);border-radius: 0px;}
.brz .brz-css-xfwdq .brz-container > .brz-row__container {min-height: auto;align-items: center;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-xfwdq {justify-content: center;align-items: center;}
.brz .brz-css-xfwdq > .brz-container__wrap {width: 1170px;}
.brz .brz-css-xfwdq > .brz-container__wrap .brz-popup2__close {display: block;top: -36px;right: -36px;left: auto;bottom: auto;color: rgba(255,255,255,1);}
.brz .brz-css-xfwdq > .brz-container__wrap .brz-popup2__close .brz-icon-svg {font-size: 16px;padding: 0px;background-color: rgba(0,0,0,0);border-radius: 0px;}
.brz .brz-css-xfwdq .brz-container > .brz-row__container {min-height: auto;align-items: center;}}
@media (max-width:767px) {.brz .brz-css-xfwdq {justify-content: center;align-items: center;}
.brz .brz-css-xfwdq > .brz-container__wrap {width: 1170px;}
.brz .brz-css-xfwdq > .brz-container__wrap .brz-popup2__close {display: block;top: -36px;right: -36px;left: auto;bottom: auto;color: rgba(255,255,255,1);}
.brz .brz-css-xfwdq > .brz-container__wrap .brz-popup2__close .brz-icon-svg {font-size: 16px;padding: 0px;background-color: rgba(0,0,0,0);border-radius: 0px;}
.brz .brz-css-xfwdq .brz-container > .brz-row__container {min-height: auto;align-items: center;}}
.brz .brz-css-msorw {animation-name: none;animation-duration: 1000ms;animation-delay: 1000ms;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-msorw {animation-name: none;animation-duration: 1000ms;animation-delay: 1000ms;}}
@media (max-width:767px) {.brz .brz-css-msorw {animation-name: none;animation-duration: 1000ms;animation-delay: 1000ms;}}
.brz .brz-css-ghcwa {flex-direction: row;}
.brz .brz-css-ghcwa .brz-icon__container {margin-left: auto;margin-right: 20px;align-items: flex-start;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ghcwa {flex-direction: row;}
.brz .brz-css-ghcwa .brz-icon__container {margin-left: auto;margin-right: 20px;align-items: flex-start;}}
@media (max-width:767px) {.brz .brz-css-ghcwa {flex-direction: row;}
.brz .brz-css-ghcwa .brz-icon__container {margin-left: auto;margin-right: 20px;align-items: flex-start;}}
.brz .brz-css-rhcmt {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-rhcmt:after {background-color: rgba(255,255,255,0);background-image: none;}
@media (min-width:991px) {.brz .brz-css-rhcmt {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-rhcmt:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-rhcmt .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-rhcmt {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-rhcmt:after {background-color: rgba(255,255,255,0);background-image: none;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-rhcmt {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-rhcmt:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-rhcmt .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
@media (max-width:767px) {.brz .brz-css-rhcmt {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-rhcmt:after {background-color: rgba(255,255,255,0);background-image: none;}}
@media (max-width:767px) {.brz .brz-css-rhcmt {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-rhcmt:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-rhcmt .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
.brz .brz-css-lphms {padding-top: 40%;}
.brz .brz-css-lphms > .brz-img {position: absolute;width: 100%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-lphms {padding-top: 40%;}
.brz .brz-css-lphms > .brz-img {position: absolute;width: 100%;}}
@media (max-width:767px) {.brz .brz-css-lphms {padding-top: 40%;}
.brz .brz-css-lphms > .brz-img {position: absolute;width: 100%;}}
.brz .brz-css-soywu {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-soywu:after {background-color: rgba(255,255,255,0);background-image: none;}
@media (min-width:991px) {.brz .brz-css-soywu {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-soywu:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-soywu .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-soywu {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-soywu:after {background-color: rgba(255,255,255,0);background-image: none;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-soywu {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-soywu:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-soywu .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
@media (max-width:767px) {.brz .brz-css-soywu {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-soywu:after {background-color: rgba(255,255,255,0);background-image: none;}}
@media (max-width:767px) {.brz .brz-css-soywu {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-soywu:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-soywu .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
.brz .brz-css-ocwye {padding-top: 40%;}
.brz .brz-css-ocwye > .brz-img {position: absolute;width: 100%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ocwye {padding-top: 40%;}
.brz .brz-css-ocwye > .brz-img {position: absolute;width: 100%;}}
@media (max-width:767px) {.brz .brz-css-ocwye {padding-top: 40%;}
.brz .brz-css-ocwye > .brz-img {position: absolute;width: 100%;}}
.brz .brz-css-mgkxv {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-mgkxv:after {background-color: rgba(255,255,255,0);background-image: none;}
@media (min-width:991px) {.brz .brz-css-mgkxv {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-mgkxv:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-mgkxv .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-mgkxv {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-mgkxv:after {background-color: rgba(255,255,255,0);background-image: none;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-mgkxv {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-mgkxv:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-mgkxv .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
@media (max-width:767px) {.brz .brz-css-mgkxv {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-mgkxv:after {background-color: rgba(255,255,255,0);background-image: none;}}
@media (max-width:767px) {.brz .brz-css-mgkxv {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-mgkxv:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-mgkxv .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
.brz .brz-css-ehkqs {padding-top: 40%;}
.brz .brz-css-ehkqs > .brz-img {position: absolute;width: 100%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ehkqs {padding-top: 40%;}
.brz .brz-css-ehkqs > .brz-img {position: absolute;width: 100%;}}
@media (max-width:767px) {.brz .brz-css-ehkqs {padding-top: 40%;}
.brz .brz-css-ehkqs > .brz-img {position: absolute;width: 100%;}}
.brz .brz-css-ezdrk {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-ezdrk:after {background-color: rgba(255,255,255,0);background-image: none;}
@media (min-width:991px) {.brz .brz-css-ezdrk {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-ezdrk:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-ezdrk .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ezdrk {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-ezdrk:after {background-color: rgba(255,255,255,0);background-image: none;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ezdrk {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-ezdrk:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-ezdrk .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
@media (max-width:767px) {.brz .brz-css-ezdrk {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-ezdrk:after {background-color: rgba(255,255,255,0);background-image: none;}}
@media (max-width:767px) {.brz .brz-css-ezdrk {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-ezdrk:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-ezdrk .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
.brz .brz-css-epnxr {padding-top: 40%;}
.brz .brz-css-epnxr > .brz-img {position: absolute;width: 100%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-epnxr {padding-top: 40%;}
.brz .brz-css-epnxr > .brz-img {position: absolute;width: 100%;}}
@media (max-width:767px) {.brz .brz-css-epnxr {padding-top: 40%;}
.brz .brz-css-epnxr > .brz-img {position: absolute;width: 100%;}}
.brz .brz-css-sleox {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-sleox:after {background-color: rgba(255,255,255,0);background-image: none;}
@media (min-width:991px) {.brz .brz-css-sleox {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-sleox:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-sleox .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-sleox {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-sleox:after {background-color: rgba(255,255,255,0);background-image: none;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-sleox {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-sleox:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-sleox .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
@media (max-width:767px) {.brz .brz-css-sleox {max-width: 100%;height: auto;border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-sleox:after {background-color: rgba(255,255,255,0);background-image: none;}}
@media (max-width:767px) {.brz .brz-css-sleox {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-sleox:after {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}
.brz .brz-css-sleox .brz-picture {transition-duration: .5s;transition-property: border,box-shadow,filter,background-color;}}
.brz .brz-css-ecexl {padding-top: 75%;}
.brz .brz-css-ecexl > .brz-img {position: absolute;width: 100%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ecexl {padding-top: 75%;}
.brz .brz-css-ecexl > .brz-img {position: absolute;width: 100%;}}
@media (max-width:767px) {.brz .brz-css-ecexl {padding-top: 75%;}
.brz .brz-css-ecexl > .brz-img {position: absolute;width: 100%;}}
.brz .brz-css-twejo {padding: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-twejo {padding: 0;}}
@media (max-width:767px) {.brz .brz-css-twejo {padding: 0;}}
.brz .brz-css-uiadn {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-uiadn {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-uiadn {margin: 0;}}
.brz .brz-css-fzjkd {z-index: 100;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-fzjkd {z-index: 100;}}
@media (max-width:767px) {.brz .brz-css-fzjkd {z-index: 100;}}
.brz .brz-css-sqsrr {padding: 0px 0px 15px 0px;}
.brz .brz-css-sqsrr > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,1);}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-sqsrr {padding: 15px;}
.brz .brz-css-sqsrr > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,1);}}
@media (max-width:767px) {.brz .brz-css-sqsrr {padding: 15px;}
.brz .brz-css-sqsrr > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,1);}}
.brz .brz-css-xqjbh {flex: 1 1 9.1%;max-width: 9.1%;}
.brz .brz-css-xqjbh > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,1);}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-xqjbh {flex: 1 1 17.2%;max-width: 17.2%;}
.brz .brz-css-xqjbh > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,1);}}
@media (max-width:767px) {.brz .brz-css-xqjbh {flex: 1 1 17.8%;max-width: 17.8%;}
.brz .brz-css-xqjbh > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,1);}}
.brz .brz-css-jugzp {padding: 5px 15px 5px 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-jugzp {padding: 5px 15px 5px 15px;}}
@media (max-width:767px) {.brz .brz-css-jugzp {padding: 0;}}
.brz .brz-css-xzspy {padding: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-xzspy {padding: 0;}}
@media (max-width:767px) {.brz .brz-css-xzspy {padding: 6px;}}
.brz .brz-css-qdygj {flex: 1 1 90.9%;max-width: 90.9%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-qdygj {flex: 1 1 17.2%;max-width: 17.2%;}}
@media (max-width:767px) {.brz .brz-css-qdygj {flex: 1 1 58.5%;max-width: 58.5%;}}
.brz .brz-css-ryvlv {padding: 5px 15px 5px 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ryvlv {padding: 5px 15px 5px 15px;}}
@media (max-width:767px) {.brz .brz-css-ryvlv {padding: 0;}}
.brz .brz-css-xbqfe {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 44px;line-height: 1.2;font-weight: 400;letter-spacing: -1.5px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-xbqfe {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 44px;line-height: 1.2;font-weight: 400;letter-spacing: -1.5px;}}
@media (max-width:767px) {.brz .brz-css-xbqfe {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 35px;line-height: 1.2;font-weight: 400;letter-spacing: -1.5px;}}
.brz .brz-css-vlrgl {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.3;font-weight: 600;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-vlrgl {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.3;font-weight: 600;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-vlrgl {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 14px;line-height: 1.3;font-weight: 600;letter-spacing: 0px;}}
.brz .brz-css-prtrw {margin: -7% 0px 0px 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-prtrw {margin: -13% 0px 0px 0px;}}
@media (max-width:767px) {.brz .brz-css-prtrw {margin: -27% 0px 0px 0px;}}
.brz .brz-css-vrink {padding: 42px 0px 355px 0px;}
.brz .brz-css-vrink > .brz-bg > .brz-bg-image {background-image: url("./img/48a39528e334bbc6fa7d135286eb34b9.jpg");background-position: 51% 69%;}
.brz .brz-css-vrink > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/48a39528e334bbc6fa7d135286eb34b9.jpg");}
.brz .brz-css-vrink > .brz-bg > .brz-bg-color {background-color: rgba(213,127,4,.22);}
.brz .brz-css-vrink > .brz-bg > .brz-bg-shape__bottom {background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTkyMCAyNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+PHBhdGggZD0iTTE5MjAgMEgwdjE0NC40MTlMNjQwIDI0MGw2NDAtMTg5LjE5NyA2NDAgOTMuNjE2eiIgZmlsbD0icmdiYSgyNTUsIDI1NSwgMjU1LCAxKSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");background-size: 100% 229px;height: 229px;transform: rotateX(-180deg) rotateY(0deg);}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-vrink {padding: 15px 15px 180px 15px;}
.brz .brz-css-vrink > .brz-bg > .brz-bg-image {background-image: url("./img/48a39528e334bbc6fa7d135286eb34b9.jpg");background-position: 51% 69%;}
.brz .brz-css-vrink > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/48a39528e334bbc6fa7d135286eb34b9.jpg");}
.brz .brz-css-vrink > .brz-bg > .brz-bg-color {background-color: rgba(213,127,4,.22);}
.brz .brz-css-vrink > .brz-bg > .brz-bg-shape__bottom {background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTkyMCAyNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+PHBhdGggZD0iTTE5MjAgMEgwdjE0NC40MTlMNjQwIDI0MGw2NDAtMTg5LjE5NyA2NDAgOTMuNjE2eiIgZmlsbD0icmdiYSgyNTUsIDI1NSwgMjU1LCAxKSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");background-size: 100% 112px;height: 112px;transform: rotateX(-180deg) rotateY(0deg);}}
@media (max-width:767px) {.brz .brz-css-vrink {padding: 15px 15px 165px 15px;}
.brz .brz-css-vrink > .brz-bg > .brz-bg-image {background-image: url("./img/48a39528e334bbc6fa7d135286eb34b9.jpg");background-position: 51% 69%;}
.brz .brz-css-vrink > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/48a39528e334bbc6fa7d135286eb34b9.jpg");}
.brz .brz-css-vrink > .brz-bg > .brz-bg-color {background-color: rgba(213,127,4,.22);}
.brz .brz-css-vrink > .brz-bg > .brz-bg-shape__bottom {background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTkyMCAyNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+PHBhdGggZD0iTTE5MjAgMEgwdjE0NC40MTlMNjQwIDI0MGw2NDAtMTg5LjE5NyA2NDAgOTMuNjE2eiIgZmlsbD0icmdiYSgyNTUsIDI1NSwgMjU1LCAxKSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");background-size: 100% 100px;height: 100px;transform: rotateX(-180deg) rotateY(0deg);}}
.brz .brz-css-rxrjv {height: 200px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-rxrjv {height: 200px;}}
@media (max-width:767px) {.brz .brz-css-rxrjv {height: 200px;}}
.brz .brz-css-lilxl {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-lilxl {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-lilxl {margin: 0;}}
.brz .brz-css-bvtte {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 46px;line-height: 1.3;font-weight: 400;letter-spacing: -1.5px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-bvtte {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 40px;line-height: 1.3;font-weight: 400;letter-spacing: -1px;}}
@media (max-width:767px) {.brz .brz-css-bvtte {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 34px;line-height: 1.3;font-weight: 400;letter-spacing: -1px;}}
.brz .brz-css-ntuzi {height: 25px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ntuzi {height: 25px;}}
@media (max-width:767px) {.brz .brz-css-ntuzi {height: 25px;}}
.brz .brz-css-ejyul {margin: 0px 34% 0px 34%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ejyul {margin: 0px 20% 0px 20%;}}
@media (max-width:767px) {.brz .brz-css-ejyul {margin: 0;}}
.brz .brz-css-zezkp .brz-form {margin: -0px -5px -10px -5px;}
.brz .brz-css-zezkp .brz-forms2__item {padding: 0px 5px 10px 5px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-zezkp .brz-form {margin: -0px -5px -10px -5px;}
.brz .brz-css-zezkp .brz-forms2__item {padding: 0px 5px 10px 5px;}}
@media (max-width:767px) {.brz .brz-css-zezkp .brz-form {margin: -0px -5px -10px -5px;}
.brz .brz-css-zezkp .brz-forms2__item {padding: 0px 5px 10px 5px;}}
.brz .brz-css-vevfj {color: rgba(115,119,127,1);font-family: 'Nunito Regular';font-size: 16px;}
.brz .brz-css-vevfj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {color: rgba(115,119,127,1);border-radius: 15px;min-height: 60.4px;}
.brz .brz-css-vevfj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox):not(.brz-forms2__field-select) {padding: 14px 24px 14px 24px;}
.brz .brz-css-vevfj .brz-forms2__field-paragraph {line-height: 1.9;}
.brz .brz-css-vevfj .brz-forms2__select-item__input {color: rgba(115,119,127,1);}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-vevfj {color: rgba(115,119,127,1); font-family: 'Nunito Regular';font-size: 14px;}
.brz .brz-css-vevfj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {color: rgba(115,119,127,1);border-radius: 15px;min-height: 63px;}
.brz .brz-css-vevfj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox):not(.brz-forms2__field-select) {padding: 20px 30px 20px 30px;}
.brz .brz-css-vevfj .brz-forms2__field-paragraph {line-height: 1.5;}
.brz .brz-css-vevfj .brz-forms2__select-item__input {color: rgba(115,119,127,1);}}
@media (max-width:767px) {.brz .brz-css-vevfj {color: rgba(115,119,127,1); font-family: 'Nunito Regular';font-size: 14px;}
.brz .brz-css-vevfj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {color: rgba(115,119,127,1);border-radius: 15px;min-height: 63px;}
.brz .brz-css-vevfj .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox):not(.brz-forms2__field-select) {padding: 20px 30px 20px 30px;}
.brz .brz-css-vevfj .brz-forms2__field-paragraph {line-height: 1.5;}
.brz .brz-css-vevfj .brz-forms2__select-item__input {color: rgba(115,119,127,1);}}
.brz .brz-css-uftty .select2-results__options {font-family: 'Nunito Regular';font-size: 16px;line-height: 1.9;}
.brz .brz-css-uftty .select2-selection--single {padding: 14px 24px 14px 24px;}
.brz .brz-css-uftty .select2-selection--multiple {padding: 14px 24px 14px 24px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-uftty .select2-results__options { font-family: 'Nunito Regular';font-size: 14px;line-height: 1.5;}
.brz .brz-css-uftty .select2-selection--single {padding: 20px 30px 20px 30px;}
.brz .brz-css-uftty .select2-selection--multiple {padding: 20px 30px 20px 30px;}}
@media (max-width:767px) {.brz .brz-css-uftty .select2-results__options { font-family: 'Nunito Regular';font-size: 14px;line-height: 1.5;}
.brz .brz-css-uftty .select2-selection--single {padding: 20px 30px 20px 30px;}
.brz .brz-css-uftty .select2-selection--multiple {padding: 20px 30px 20px 30px;}}
.brz .brz-css-rhvdx.brz-btn { font-family: 'Nunito Regular';letter-spacing: 0px;color: rgba(253,253,253,1);border: 2px solid rgba(253,253,253,1);border-radius: 15px;background-color: rgba(249,157,0,1);padding: 20px 44px 20px 44px;}
.brz .brz-css-rhvdx.brz-btn.brz-btn-submit {color: rgba(253,253,253,1);background-color: rgba(249,157,0,1);}
.brz .brz-css-rhvdx .brz-btn--story-container {border: 2px solid rgba(253,253,253,1);border-radius: 15px;}
@media (min-width:991px) {.brz .brz-css-rhvdx.brz-btn:hover {color: rgba(249,157,0,1);border: 2px solid rgba(249,157,0,.8);background-color: rgba(253,253,253,1);}
.brz .brz-css-rhvdx.brz-btn.brz-btn-submit:hover {color: rgba(249,157,0,1);background-color: rgba(253,253,253,1);}
.brz .brz-css-rhvdx:hover .brz-btn--story-container {border: 2px solid rgba(249,157,0,.8);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-rhvdx.brz-btn { font-family: 'Nunito Regular';letter-spacing: .5px;color: rgba(253,253,253,1);border: 2px solid rgba(253,253,253,1);border-radius: 0px;background-color: rgba(249,157,0,1);padding: 19px 44px 19px 44px;}
.brz .brz-css-rhvdx.brz-btn.brz-btn-submit {color: rgba(253,253,253,1);background-color: rgba(249,157,0,1);}
.brz .brz-css-rhvdx .brz-btn--story-container {border: 2px solid rgba(253,253,253,1);border-radius: 0px;}}
@media (max-width:767px) {.brz .brz-css-rhvdx.brz-btn { font-family: 'Nunito Regular';letter-spacing: .5px;color: rgba(253,253,253,1);border: 2px solid rgba(253,253,253,1);border-radius: 0px;background-color: rgba(249,157,0,1);padding: 19px 44px 19px 44px;}
.brz .brz-css-rhvdx.brz-btn.brz-btn-submit {color: rgba(253,253,253,1);background-color: rgba(249,157,0,1);}
.brz .brz-css-rhvdx .brz-btn--story-container {border: 2px solid rgba(253,253,253,1);border-radius: 0px;}}
.brz .brz-css-ivbln {    padding: 60px 0 30px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ivbln {padding: 25px 15px 75px 15px;}}
@media (max-width:767px) {.brz .brz-css-ivbln {padding: 15px 15px 25px 15px;}}
.brz .brz-css-fvqem {margin: 10px 0px 10px 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-fvqem {margin: 10px 0px 10px 0px;}}
@media (max-width:767px) {.brz .brz-css-fvqem {margin: 10px 25px 10px 25px;}}
.brz .brz-css-vfcvz {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 36px;line-height: 1.4;font-weight: 400;letter-spacing: -1px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-vfcvz {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 35px;line-height: 1.3;font-weight: 400;letter-spacing: -.5px;}}
@media (max-width:767px) {.brz .brz-css-vfcvz {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 30px;line-height: 1.3;font-weight: 400;letter-spacing: -.5px;}}
.brz .brz-css-xbtpa {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.9;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-xbtpa {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-xbtpa {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-aypdy {display: none;}}
.brz .brz-css-rpoxr {height: 50px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-rpoxr {height: 50px;}}
@media (max-width:767px) {.brz .brz-css-rpoxr {height: 18px;}}
.brz .brz-css-ipgkx {flex: 1 1 25%;max-width: 25%;}
.brz .brz-css-ipgkx > .brz-bg {border: 4px solid rgba(255,255,255,.1);margin: 0;}
@media (min-width:991px) {.brz .brz-css-ipgkx:hover > .brz-bg {border: 4px solid rgba(249,157,0,1);box-shadow: 0px 0px 60px 10px rgba(0,0,0,.1);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ipgkx {flex: 1 1 25%;max-width: 25%;}
.brz .brz-css-ipgkx > .brz-bg {border: 4px solid rgba(255,255,255,.1);margin: 0;}}
@media (max-width:767px) {.brz .brz-css-ipgkx {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-ipgkx > .brz-bg {border: 4px solid rgba(255,255,255,.1);margin: 10px;}}
.brz .brz-css-jphha {margin: 0;border: 4px solid transparent;   padding: 40px 40px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-jphha {margin: 0;border: 4px solid transparent;padding: 40px 20px 40px 20px;}}
@media (max-width:767px) {.brz .brz-css-jphha {margin: 10px;border: 4px solid transparent;padding: 50px 30px 50px 30px;}}
.brz .brz-css-ycxjx {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ycxjx {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-ycxjx {margin: 0;}}
.brz .brz-css-mirra {height: 35px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-mirra {height: 35px;}}
@media (max-width:767px) {.brz .brz-css-mirra {height: 17px;}}
.brz .brz-css-twuja {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-twuja {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-twuja {margin: 0;}}
.brz .brz-css-xppci {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-xppci {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-xppci {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 21px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-ndodb {height: 20px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ndodb {height: 20px;}}
@media (max-width:767px) {.brz .brz-css-ndodb {height: 20px;}}
.brz .brz-css-pysll {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-pysll {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-pysll {margin: 0;}}
.brz .brz-css-ntxgj {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.9;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ntxgj {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-ntxgj {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-yagrx {flex: 1 1 25%;max-width: 25%;}
.brz .brz-css-yagrx > .brz-bg {border: 4px solid rgba(255,255,255,.1);margin: 0;}
@media (min-width:991px) {.brz .brz-css-yagrx:hover > .brz-bg {border: 4px solid rgba(249,157,0,1);box-shadow: 0px 0px 60px 10px rgba(0,0,0,.1);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-yagrx {flex: 1 1 25%;max-width: 25%;}
.brz .brz-css-yagrx > .brz-bg {border: 4px solid rgba(255,255,255,.1);margin: 0;}}
@media (max-width:767px) {.brz .brz-css-yagrx {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-yagrx > .brz-bg {border: 4px solid rgba(255,255,255,.1);margin: 10px;}}
.brz .brz-css-cqjvs {margin: 0;border: 4px solid transparent; padding: 40px 40px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-cqjvs {margin: 0;border: 4px solid transparent;padding: 40px 20px 40px 20px;}}
@media (max-width:767px) {.brz .brz-css-cqjvs {margin: 10px;border: 4px solid transparent;padding: 50px 30px 50px 30px;}}
.brz .brz-css-bqqkh {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-bqqkh {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-bqqkh {margin: 0;}}
.brz .brz-css-olqnc {height: 35px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-olqnc {height: 35px;}}
@media (max-width:767px) {.brz .brz-css-olqnc {height: 23px;}}
.brz .brz-css-kaqgm {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-kaqgm {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-kaqgm {margin: 0;}}
.brz .brz-css-xlbmo {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-xlbmo {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-xlbmo {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 21px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-emife {height: 20px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-emife {height: 20px;}}
@media (max-width:767px) {.brz .brz-css-emife {height: 20px;}}
.brz .brz-css-wprhk {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-wprhk {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-wprhk {margin: 0;}}
.brz .brz-css-qyser {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.9;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-qyser {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-qyser {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-pesyj {flex: 1 1 25%;max-width: 25%;}
.brz .brz-css-pesyj > .brz-bg {border: 4px solid rgba(255,255,255,.1);margin: 0;}
@media (min-width:991px) {.brz .brz-css-pesyj:hover > .brz-bg {border: 4px solid rgba(249,157,0,1);box-shadow: 0px 0px 60px 10px rgba(0,0,0,.1);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-pesyj {flex: 1 1 25%;max-width: 25%;}
.brz .brz-css-pesyj > .brz-bg {border: 4px solid rgba(255,255,255,.1);margin: 0;}}
@media (max-width:767px) {.brz .brz-css-pesyj {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-pesyj > .brz-bg {border: 4px solid rgba(255,255,255,.1);margin: 10px;}}
.brz .brz-css-yupou {margin: 0;border: 4px solid transparent; padding: 40px 40px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-yupou {margin: 0;border: 4px solid transparent;padding: 40px 20px 40px 20px;}}
@media (max-width:767px) {.brz .brz-css-yupou {margin: 10px;border: 4px solid transparent;padding: 50px 30px 50px 30px;}}
.brz .brz-css-cvkyk {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-cvkyk {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-cvkyk {margin: 0;}}
.brz .brz-css-iyfrv {height: 35px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-iyfrv {height: 35px;}}
@media (max-width:767px) {.brz .brz-css-iyfrv {height: 10px;}}
.brz .brz-css-vcwyk {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-vcwyk {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-vcwyk {margin: 0;}}
.brz .brz-css-pzhuw {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-pzhuw {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-pzhuw {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 21px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-rjslv {height: 20px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-rjslv {height: 20px;}}
@media (max-width:767px) {.brz .brz-css-rjslv {height: 20px;}}
.brz .brz-css-ikgmn {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ikgmn {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-ikgmn {margin: 0;}}
.brz .brz-css-glogz {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.9;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-glogz {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-glogz {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-tcfoa {flex: 1 1 25%;max-width: 25%;}
.brz .brz-css-tcfoa > .brz-bg {border: 4px solid rgba(255,255,255,.1);margin: 0;}
@media (min-width:991px) {.brz .brz-css-tcfoa:hover > .brz-bg {border: 4px solid rgba(249,157,0,1);box-shadow: 0px 0px 60px 10px rgba(0,0,0,.1);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-tcfoa {flex: 1 1 25%;max-width: 25%;}
.brz .brz-css-tcfoa > .brz-bg {border: 4px solid rgba(255,255,255,.1);margin: 0;}}
@media (max-width:767px) {.brz .brz-css-tcfoa {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-tcfoa > .brz-bg {border: 4px solid rgba(255,255,255,.1);margin: 10px;}}
.brz .brz-css-ygpwl {margin: 0;border: 4px solid transparent;padding: 60px 40px 60px 40px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ygpwl {margin: 0;border: 4px solid transparent;padding: 40px 20px 40px 20px;}}
@media (max-width:767px) {.brz .brz-css-ygpwl {margin: 10px;border: 4px solid transparent;padding: 50px 30px 50px 30px;}}
.brz .brz-css-sgsbu {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-sgsbu {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-sgsbu {margin: 0;}}
.brz .brz-css-ctujb {height: 35px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ctujb {height: 35px;}}
@media (max-width:767px) {.brz .brz-css-ctujb {height: 19px;}}
.brz .brz-css-krlfo {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-krlfo {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-krlfo {margin: 0;}}
.brz .brz-css-uqkzo {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-uqkzo {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-uqkzo {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 21px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-ugkdq {height: 20px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ugkdq {height: 20px;}}
@media (max-width:767px) {.brz .brz-css-ugkdq {height: 20px;}}
.brz .brz-css-uofkk {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-uofkk {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-uofkk {margin: 0;}}
.brz .brz-css-qwukr {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.9;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-qwukr {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-qwukr {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-wgbhj > .slick-slider > .brz-slick-slider__dots {color: rgba(38,35,42,1);}
.brz .brz-css-wgbhj > .slick-slider > .brz-slick-slider__arrow {color: rgba(38,35,42,1);}
@media (min-width:991px) {.brz .brz-css-wgbhj > .slick-slider > .brz-slick-slider__dots:hover {color: rgba(249,157,0,1);}
.brz .brz-css-wgbhj > .slick-slider > .brz-slick-slider__arrow:hover {color: rgba(249,157,0,1);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-wgbhj > .slick-slider > .brz-slick-slider__dots {color: rgba(38,35,42,1);}
.brz .brz-css-wgbhj > .slick-slider > .brz-slick-slider__arrow {color: rgba(38,35,42,1);}}
@media (max-width:767px) {.brz .brz-css-wgbhj > .slick-slider > .brz-slick-slider__dots {color: rgba(38,35,42,1);}
.brz .brz-css-wgbhj > .slick-slider > .brz-slick-slider__arrow {color: rgba(38,35,42,1);}}
.brz .brz-css-lqqoc {padding: 120px 0px 190px 0px;}
.brz .brz-css-lqqoc > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,1);}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-lqqoc {padding: 75px 100px 90px 100px;}
.brz .brz-css-lqqoc > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,1);}}
@media (max-width:767px) {.brz .brz-css-lqqoc {padding: 75px 15px 75px 15px;}
.brz .brz-css-lqqoc > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,1);}}
.brz .brz-css-ssxft {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ssxft {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-ssxft {margin: 0;}}
.brz .brz-css-cqaan {height: 29px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-cqaan {height: 29px;}}
@media (max-width:767px) {.brz .brz-css-cqaan {height: 29px;}}
.brz .brz-css-axsvl {padding: 0;margin: 0px 10% 0px 10%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-axsvl {padding: 0;margin: 0;}}
@media (max-width:767px) {.brz .brz-css-axsvl {padding: 0px 60px 0px 60px;margin: 0;}}
.brz .brz-css-vsxux {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 28px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-vsxux {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 27px;line-height: 1.3;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-vsxux {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.3;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-wnplv {height: 40px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-wnplv {height: 40px;}}
@media (max-width:767px) {.brz .brz-css-wnplv {height: 40px;}}
.brz .brz-css-zxbka {margin: 0px 10% 0px 10%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-zxbka {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-zxbka {margin: 0;}}
.brz .brz-css-zzrzf {color: rgba(255,255,255,1);margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-zzrzf {color: rgba(255,255,255,1);margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-zzrzf {color: rgba(255,255,255,1);margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 21px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-rmeou {padding: 94px 0px 110px 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-rmeou {padding: 75px 15px 75px 15px;}}
@media (max-width:767px) {.brz .brz-css-rmeou {padding: 75px 25px 75px 25px;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-apbhh > .brz-row {flex-direction: row;flex-wrap: wrap;justify-content: flex-start;}}
@media (max-width:767px) {.brz .brz-css-apbhh > .brz-row {flex-direction: row-reverse;flex-wrap: wrap-reverse;justify-content: flex-end;}}
.brz .brz-css-tyfxj {flex: 1 1 53.8%;max-width: 53.8%;}
.brz .brz-css-tyfxj > .brz-bg {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-tyfxj {flex: 1 1 53.8%;max-width: 53.8%;}
.brz .brz-css-tyfxj > .brz-bg {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-tyfxj {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-tyfxj > .brz-bg {margin: 0;}}
.brz .brz-css-rvmvz {margin: 0;padding: 5px 20% 5px 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-rvmvz {margin: 0;padding: 5px 20px 5px 10px;}}
@media (max-width:767px) {.brz .brz-css-rvmvz {margin: 0;padding: 30px 0px 0px 0px;}}
.brz .brz-css-pavzy {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-pavzy {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-pavzy {margin: 0;}}
.brz .brz-css-lgbtq {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 30px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-lgbtq {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 30px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-lgbtq {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 30px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-kwyun {height: 35px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-kwyun {height: 35px;}}
@media (max-width:767px) {.brz .brz-css-kwyun {height: 35px;}}
.brz .brz-css-zlpmo {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-zlpmo {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-zlpmo {margin: 0;}}
.brz .brz-css-jofjs {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.9;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-jofjs {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-jofjs {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-ojsck {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.9;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ojsck {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-ojsck {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-lgcdt {height: 24px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-lgcdt {height: 24px;}}
@media (max-width:767px) {.brz .brz-css-lgcdt {height: 24px;}}
.brz .brz-css-pwraz {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-pwraz {margin: 10px 0px 10px 0px;}}
@media (max-width:767px) {.brz .brz-css-pwraz {margin: 0;}}
.brz .brz-css-wdmnj {justify-content: flex-start;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-wdmnj {justify-content: flex-start;}}
@media (max-width:767px) {.brz .brz-css-wdmnj {justify-content: center;}}
.brz .brz-css-edsng.brz-btn {border: 2px solid rgba(253,253,253,1);border-radius: 15px;background-color: rgba(249,157,0,1);padding: 24px 60px 24px 60px;}
.brz .brz-css-edsng.brz-btn.brz-btn-submit {background-color: rgba(249,157,0,1);}
.brz .brz-css-edsng .brz-btn--story-container {border: 2px solid rgba(253,253,253,1);border-radius: 15px;}
@media (min-width:991px) {.brz .brz-css-edsng.brz-btn:hover {color: rgba(249,157,0,1);border: 2px solid rgba(249,157,0,.8);background-color: rgba(253,253,253,1);}
.brz .brz-css-edsng.brz-btn.brz-btn-submit:hover {color: rgba(249,157,0,1);background-color: rgba(253,253,253,1);}
.brz .brz-css-edsng:hover .brz-btn--story-container {border: 2px solid rgba(249,157,0,.8);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-edsng.brz-btn {border: 2px solid rgba(253,253,253,1);border-radius: 0px;background-color: rgba(249,157,0,1);padding: 22px 60px 22px 60px;}
.brz .brz-css-edsng.brz-btn.brz-btn-submit {background-color: rgba(249,157,0,1);}
.brz .brz-css-edsng .brz-btn--story-container {border: 2px solid rgba(253,253,253,1);border-radius: 0px;}}
@media (max-width:767px) {.brz .brz-css-edsng.brz-btn {border: 2px solid rgba(253,253,253,1);border-radius: 0px;background-color: rgba(249,157,0,1);padding: 22px 60px 22px 60px;}
.brz .brz-css-edsng.brz-btn.brz-btn-submit {background-color: rgba(249,157,0,1);}
.brz .brz-css-edsng .brz-btn--story-container {border: 2px solid rgba(253,253,253,1);border-radius: 0px;}}
.brz .brz-css-amcde > .brz-bg > .brz-bg-color {background-color: rgba(234,246,227,0);}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-amcde > .brz-bg > .brz-bg-color {background-color: rgba(234,246,227,0);}}
@media (max-width:767px) {.brz .brz-css-amcde > .brz-bg > .brz-bg-color {background-color: rgba(234,246,227,0);}}
.brz .brz-css-eygeh > .brz-container__wrap {width: 600px;}
.brz .brz-css-eygeh > .brz-container__wrap .brz-popup2__close {top: 20px;right: 20px;left: auto;bottom: auto;}
.brz .brz-css-eygeh > .brz-container__wrap .brz-popup2__close .brz-icon-svg {font-size: 20px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-eygeh > .brz-container__wrap {width: 600px;}
.brz .brz-css-eygeh > .brz-container__wrap .brz-popup2__close {top: 20px;right: 20px;left: auto;bottom: auto;}
.brz .brz-css-eygeh > .brz-container__wrap .brz-popup2__close .brz-icon-svg {font-size: 20px;}}
@media (max-width:767px) {.brz .brz-css-eygeh > .brz-container__wrap {width: 90%;}
.brz .brz-css-eygeh > .brz-container__wrap .brz-popup2__close {top: 20px;right: 20px;left: auto;bottom: auto;}
.brz .brz-css-eygeh > .brz-container__wrap .brz-popup2__close .brz-icon-svg {font-size: 20px;}}
.brz .brz-css-fqixq > .brz-bg {border-radius: 6px;box-shadow: 0px 20px 20px -10px rgba(0,0,0,.37);}
.brz .brz-css-fqixq > .brz-bg > .brz-bg-image {background-image: url("./img/1d7740e10dc0f997c93adf3b5110557b.jpg");background-position: 46% 51%;}
.brz .brz-css-fqixq > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/1d7740e10dc0f997c93adf3b5110557b.jpg");}
.brz .brz-css-fqixq > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,.3);}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-fqixq > .brz-bg {border-radius: 8px;box-shadow: 0px 20px 20px -10px rgba(0,0,0,.37);}
.brz .brz-css-fqixq > .brz-bg > .brz-bg-image {background-image: url("./img/1d7740e10dc0f997c93adf3b5110557b.jpg");background-position: 46% 51%;}
.brz .brz-css-fqixq > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/1d7740e10dc0f997c93adf3b5110557b.jpg");}
.brz .brz-css-fqixq > .brz-bg > .brz-bg-color {background-color: rgba(44,47,64,0);}}
@media (max-width:767px) {.brz .brz-css-fqixq > .brz-bg {border-radius: 6px;box-shadow: 0px 20px 20px -10px rgba(0,0,0,.37);}
.brz .brz-css-fqixq > .brz-bg > .brz-bg-image {background-image: url("./img/1d7740e10dc0f997c93adf3b5110557b.jpg");background-position: 46% 51%;}
.brz .brz-css-fqixq > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/1d7740e10dc0f997c93adf3b5110557b.jpg");}
.brz .brz-css-fqixq > .brz-bg > .brz-bg-color {background-color: rgba(44,47,64,1);}}
.brz .brz-css-hfuyj {animation-name: zoomInUp;animation-delay: 0ms;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-hfuyj {animation-name: zoomInUp;animation-delay: 0ms;}}
@media (max-width:767px) {.brz .brz-css-hfuyj {animation-name: zoomInUp;animation-delay: 0ms;}}
.brz .brz-css-zhrwi {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-zhrwi > .brz-bg {border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-zhrwi > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-zhrwi {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-zhrwi > .brz-bg {border: 1px solid rgba(102,115,141,.14);border-radius: 6px;}
.brz .brz-css-zhrwi > .brz-bg > .brz-bg-color {background-color: rgba(44,47,64,1);}}
@media (max-width:767px) {.brz .brz-css-zhrwi {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-zhrwi > .brz-bg {border: 0px solid rgba(102,115,141,0);border-radius: 0px;}
.brz .brz-css-zhrwi > .brz-bg > .brz-bg-color {background-color: rgba(0,0,0,0);}}
.brz .brz-css-uwgwl {border: 0px solid transparent;padding: 30px 15px 30px 15px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-uwgwl {border: 1px solid transparent;padding: 5px 15px 5px 15px;}}
@media (max-width:767px) {.brz .brz-css-uwgwl {border: 0px solid transparent;padding: 0px 30px 0px 30px;}}
.brz .brz-css-cqavq {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.7;font-weight: 700;letter-spacing: 1px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-cqavq {margin-top: 19px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.7;font-weight: 700;letter-spacing: 1px;}}
@media (max-width:767px) {.brz .brz-css-cqavq {margin-top: 10px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.7;font-weight: 700;letter-spacing: 1px;}}
.brz .brz-css-aiiad {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-aiiad {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-aiiad {margin: 10px 0px 10px 0px;}}
.brz .brz-css-nthmz {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 97px;line-height: 1.1;font-weight: 700;letter-spacing: 2px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-nthmz {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 76px;line-height: 1.2;font-weight: 700;letter-spacing: 2px;}}
@media (max-width:767px) {.brz .brz-css-nthmz {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 62px;line-height: 1.1;font-weight: 700;letter-spacing: 2px;}}
.brz .brz-css-fkdah {margin: 10px 0px 10px 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-fkdah {margin: 10px 0px 10px 0px;}}
@media (max-width:767px) {.brz .brz-css-fkdah {margin: 0;}}
.brz .brz-css-dkcwt {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 18px;line-height: 1.7;font-weight: 700;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-dkcwt {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 21px;line-height: 1.7;font-weight: 700;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-dkcwt {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 19px;line-height: 1.7;font-weight: 700;letter-spacing: 0px;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-jltpr {display: none;}}
@media (max-width:767px) {.brz .brz-css-jltpr {display: none;}}
.brz .brz-css-yigcx {height: 10px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-yigcx {height: 10px;}}
@media (max-width:767px) {.brz .brz-css-yigcx {height: 10px;}}
.brz .brz-css-befxa {padding: 0px 67px 0px 67px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-befxa {padding: 0px 51px 0px 51px;}}
@media (max-width:767px) {.brz .brz-css-befxa {padding: 0;}}
.brz .brz-css-yeefk .brz-forms2__item-button {margin-left: auto;margin-right: auto;max-width: 62%;flex-basis: 62%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-yeefk .brz-forms2__item-button {margin-left: auto;margin-right: auto;max-width: 61%;flex-basis: 61%;}}
@media (max-width:767px) {.brz .brz-css-yeefk .brz-forms2__item-button {margin-left: auto;margin-right: auto;max-width: 80%;flex-basis: 80%;}}
.brz .brz-css-wuinx {color: rgba(157,165,176,.7);font-family: 'Nunito Regular';font-size: 18px;font-weight: 700;}
.brz .brz-css-wuinx .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {color: rgba(157,165,176,.7);border-radius: 4px;min-height: 51.6px;}
.brz .brz-css-wuinx .brz-forms2__field-paragraph {line-height: 1.2;}
.brz .brz-css-wuinx .brz-forms2__select-item__input {color: rgba(157,165,176,.7);}
.brz .brz-css-wuinx .form-alert {font-family: 'Nunito Regular';}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-wuinx {color: rgba(157,165,176,.7);font-family: 'Nunito Regular';font-size: 17px;font-weight: 400;}
.brz .brz-css-wuinx .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {color: rgba(157,165,176,.7);border-radius: 4px;min-height: 55.5px;}
.brz .brz-css-wuinx .brz-forms2__field-paragraph {line-height: 1.5;}
.brz .brz-css-wuinx .brz-forms2__select-item__input {color: rgba(157,165,176,.7);}
.brz .brz-css-wuinx .form-alert {font-family: 'Nunito Regular';}}
@media (max-width:767px) {.brz .brz-css-wuinx {color: rgba(157,165,176,.7);font-family: 'Nunito Regular';font-size: 16px;font-weight: 400;}
.brz .brz-css-wuinx .brz-forms2__field:not(.brz-forms2__radio):not(.brz-forms2__checkbox) {color: rgba(157,165,176,.7);border-radius: 4px;min-height: 46px;}
.brz .brz-css-wuinx .brz-forms2__field-paragraph {line-height: 1.5;}
.brz .brz-css-wuinx .brz-forms2__select-item__input {color: rgba(157,165,176,.7);}
.brz .brz-css-wuinx .form-alert {font-family: 'Nunito Regular';}}
.brz .brz-css-xnilt .select2-results__options {font-family: 'Nunito Regular';font-size: 18px;line-height: 1.2;font-weight: 700;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-xnilt .select2-results__options {font-family: 'Nunito Regular';font-size: 17px;line-height: 1.5;font-weight: 400;}}
@media (max-width:767px) {.brz .brz-css-xnilt .select2-results__options {font-family: 'Nunito Regular';font-size: 16px;line-height: 1.5;font-weight: 400;}}
.brz .brz-css-jaruz.brz-btn {font-family: 'Nunito Regular';font-size: 14px;line-height: 1.8;letter-spacing: 2px;color: rgba(253,253,253,1);border: 2px solid rgba(253,253,253,1);border-radius: 15px;background-color: rgba(249,157,0,1);}
.brz .brz-css-jaruz.brz-btn.brz-btn-submit {color: rgba(253,253,253,1);background-color: rgba(249,157,0,1);}
.brz .brz-css-jaruz .brz-btn--story-container {border: 2px solid rgba(253,253,253,1);border-radius: 15px;}
@media (min-width:991px) {.brz .brz-css-jaruz.brz-btn:hover {color: rgba(249,157,0,1);border: 2px solid rgba(249,157,0,.8);background-color: rgba(253,253,253,.8);}
.brz .brz-css-jaruz.brz-btn.brz-btn-submit:hover {color: rgba(249,157,0,1);background-color: rgba(253,253,253,.8);}
.brz .brz-css-jaruz:hover .brz-btn--story-container {border: 2px solid rgba(249,157,0,.8);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-jaruz.brz-btn {font-family: 'Nunito Regular';font-size: 16px;line-height: 2.2;letter-spacing: 3px;color: rgba(253,253,253,1);border: 2px solid rgba(253,253,253,1);border-radius: 15px;background-color: rgba(249,157,0,1);}
.brz .brz-css-jaruz.brz-btn.brz-btn-submit {color: rgba(253,253,253,1);background-color: rgba(249,157,0,1);}
.brz .brz-css-jaruz .brz-btn--story-container {border: 2px solid rgba(253,253,253,1);border-radius: 15px;}}
@media (max-width:767px) {.brz .brz-css-jaruz.brz-btn {font-family: 'Nunito Regular';font-size: 15px;line-height: 2.2;letter-spacing: 3px;color: rgba(253,253,253,1);border: 2px solid rgba(253,253,253,1);border-radius: 3px;background-color: rgba(249,157,0,1);}
.brz .brz-css-jaruz.brz-btn.brz-btn-submit {color: rgba(253,253,253,1);background-color: rgba(249,157,0,1);}
.brz .brz-css-jaruz .brz-btn--story-container {border: 2px solid rgba(253,253,253,1);border-radius: 3px;}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-dynvo {display: none;}}
@media (max-width:767px) {.brz .brz-css-dynvo {display: none;}}
.brz .brz-css-sevcr {height: 10px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-sevcr {height: 10px;}}
@media (max-width:767px) {.brz .brz-css-sevcr {height: 30px;}}
.brz .brz-css-iunzn {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 17px;line-height: 1.7;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-iunzn {margin-top: 0px !important;margin-bottom: 17px !important;text-align: center;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.7;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-iunzn {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 17px;line-height: 1.7;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-cxskg {flex: 1 1 46.2%;max-width: 46.2%;}
.brz .brz-css-cxskg > .brz-bg {margin: -35% 0% 0px 0px;}
.brz .brz-css-cxskg > .brz-bg > .brz-bg-image {background-image: url("./img/8e9df360bc788f172a9993a111d5fd4a.jpg");filter: brightness(100%) hue-rotate(360deg) saturate(100%) contrast(100%);background-position: 52% 43%;}
.brz .brz-css-cxskg > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/8e9df360bc788f172a9993a111d5fd4a.jpg");}
.brz .brz-css-cxskg > .brz-bg > .brz-bg-map {filter: brightness(100%) hue-rotate(360deg) saturate(100%) contrast(100%);}
.brz .brz-css-cxskg > .brz-bg > .brz-bg-video {filter: brightness(100%) hue-rotate(360deg) saturate(100%) contrast(100%);}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-cxskg {flex: 1 1 46.2%;max-width: 46.2%;}
.brz .brz-css-cxskg > .brz-bg {margin: -30% 0px 0px 0px;}
.brz .brz-css-cxskg > .brz-bg > .brz-bg-image {background-image: url("./img/8e9df360bc788f172a9993a111d5fd4a.jpg");filter: brightness(100%) hue-rotate(360deg) saturate(100%) contrast(100%);background-position: 52% 43%;}
.brz .brz-css-cxskg > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/8e9df360bc788f172a9993a111d5fd4a.jpg");}
.brz .brz-css-cxskg > .brz-bg > .brz-bg-map {filter: brightness(100%) hue-rotate(360deg) saturate(100%) contrast(100%);}
.brz .brz-css-cxskg > .brz-bg > .brz-bg-video {filter: brightness(100%) hue-rotate(360deg) saturate(100%) contrast(100%);}}
@media (max-width:767px) {.brz .brz-css-cxskg {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-cxskg > .brz-bg {margin: 0;}
.brz .brz-css-cxskg > .brz-bg > .brz-bg-image {background-image: url("./img/8e9df360bc788f172a9993a111d5fd4a.jpg");filter: brightness(100%) hue-rotate(360deg) saturate(100%) contrast(100%);background-position: 52% 43%;}
.brz .brz-css-cxskg > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/8e9df360bc788f172a9993a111d5fd4a.jpg");}
.brz .brz-css-cxskg > .brz-bg > .brz-bg-map {filter: brightness(100%) hue-rotate(360deg) saturate(100%) contrast(100%);}
.brz .brz-css-cxskg > .brz-bg > .brz-bg-video {filter: brightness(100%) hue-rotate(360deg) saturate(100%) contrast(100%);}}
.brz .brz-css-ktblo {margin: -35% 0% 0px 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ktblo {margin: -30% 0px 0px 0px;}}
@media (max-width:767px) {.brz .brz-css-ktblo {margin: 0;}}
.brz .brz-css-azayb {height: 50px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-azayb {height: 50px;}}
@media (max-width:767px) {.brz .brz-css-azayb {height: 263px;}}
.brz .brz-css-eaggd {padding: 100px 0px 100px 0px;}
.brz .brz-css-eaggd > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,1);}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-eaggd {padding: 75px 15px 75px 15px;}
.brz .brz-css-eaggd > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,1);}}
@media (max-width:767px) {.brz .brz-css-eaggd {padding: 75px 25px 75px 25px;}
.brz .brz-css-eaggd > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,1);}}
.brz .brz-css-ivrng {flex: 1 1 42.7%;max-width: 42.7%;}
.brz .brz-css-ivrng > .brz-bg {margin: 0;}
.brz .brz-css-ivrng > .brz-bg > .brz-bg-image {background-image: url("./img/0fea9807ed8ae8bb8808aebb44d43f8a.jpg");background-position: 100% 0%;}
.brz .brz-css-ivrng > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/0fea9807ed8ae8bb8808aebb44d43f8a.jpg");}
.brz .brz-css-ivrng > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,0);}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ivrng {flex: 1 1 42.7%;max-width: 42.7%;}
.brz .brz-css-ivrng > .brz-bg {margin: 0;}
.brz .brz-css-ivrng > .brz-bg > .brz-bg-image {background-image: url("./img/0fea9807ed8ae8bb8808aebb44d43f8a.jpg");background-position: 100% 0%;}
.brz .brz-css-ivrng > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/0fea9807ed8ae8bb8808aebb44d43f8a.jpg");}
.brz .brz-css-ivrng > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,0);}}
@media (max-width:767px) {.brz .brz-css-ivrng {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-ivrng > .brz-bg {margin: 0;}
.brz .brz-css-ivrng > .brz-bg > .brz-bg-image {background-image: url("./img/0fea9807ed8ae8bb8808aebb44d43f8a.jpg");background-position: 100% 0%;}
.brz .brz-css-ivrng > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/0fea9807ed8ae8bb8808aebb44d43f8a.jpg");}
.brz .brz-css-ivrng > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,0);}}
.brz .brz-css-ylzrp {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ylzrp {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-ylzrp {margin: 0;}}
.brz .brz-css-fslog {height: 50px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-fslog {height: 50px;}}
@media (max-width:767px) {.brz .brz-css-fslog {height: 272px;}}
.brz .brz-css-deiuc {flex: 1 1 57.3%;max-width: 57.3%;}
.brz .brz-css-deiuc > .brz-bg {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-deiuc {flex: 1 1 57.3%;max-width: 57.3%;}
.brz .brz-css-deiuc > .brz-bg {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-deiuc {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-deiuc > .brz-bg {margin: 0;}}
.brz .brz-css-rnnnq {margin: 0;padding: 5px 15px 5px 20%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-rnnnq {margin: 0;padding: 5px 15px 5px 35px;}}
@media (max-width:767px) {.brz .brz-css-rnnnq {margin: 0;padding: 30px 0px 0px 0px;}}
.brz .brz-css-pskub {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-pskub {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-pskub {margin: 0;}}
.brz .brz-css-iwbjh {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 30px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-iwbjh {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 30px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-iwbjh {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.3;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-bodlo {height: 35px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-bodlo {height: 35px;}}
@media (max-width:767px) {.brz .brz-css-bodlo {height: 35px;}}
.brz .brz-css-dpjbj {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-dpjbj {margin: 10px 0px 10px 0px;}}
@media (max-width:767px) {.brz .brz-css-dpjbj {margin: 0;}}
.brz .brz-css-yliiy {margin-top: 0px !important;margin-bottom: 10px !important;text-align: left;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-yliiy {margin-top: 0px !important;margin-bottom: 10px !important;text-align: left;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-yliiy {margin-top: 0px !important;margin-bottom: 10px !important;text-align: left;font-family: 'Nunito Regular';font-size: 21px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-aiztu {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.9;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-aiztu {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-aiztu {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-kguad {height: 35px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-kguad {height: 35px;}}
@media (max-width:767px) {.brz .brz-css-kguad {height: 35px;}}
.brz .brz-css-hwxgm {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-hwxgm {margin: 10px 0px 10px 0px;}}
@media (max-width:767px) {.brz .brz-css-hwxgm {margin: 0;}}
.brz .brz-css-bzkvh {margin-top: 0px !important;margin-bottom: 10px !important;text-align: left;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-bzkvh {margin-top: 0px !important;margin-bottom: 10px !important;text-align: left;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-bzkvh {margin-top: 0px !important;margin-bottom: 10px !important;text-align: left;font-family: 'Nunito Regular';font-size: 21px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-xdypf {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.9;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-xdypf {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-xdypf {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-mqkjy {padding: 60px 0px 60px 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-mqkjy {padding: 50px 15px 50px 15px;}}
@media (max-width:767px) {.brz .brz-css-mqkjy {padding: 50px 15px 50px 15px;}}
.brz .brz-css-lyreh {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.5;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-lyreh {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 22px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-lyreh {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 21px;line-height: 1.4;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-idczk {flex: 1 1 25%;max-width: 25%;justify-content: center;}
.brz .brz-css-idczk > .brz-bg {margin: 10px;}
.brz .brz-css-idczk > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,.5);}
@media (min-width:991px) {.brz .brz-css-idczk:hover > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,.5);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-idczk {flex: 1 1 25%;max-width: 25%;justify-content: center;}
.brz .brz-css-idczk > .brz-bg {margin: 10px;}
.brz .brz-css-idczk > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,.5);}}
@media (max-width:767px) {.brz .brz-css-idczk {flex: 1 1 50%;max-width: 50%;justify-content: center;}
.brz .brz-css-idczk > .brz-bg {margin: 10px;}
.brz .brz-css-idczk > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,.5);}}
.brz .brz-css-ywkvy {margin: 10px;padding: 55px 15px 55px 15px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ywkvy {margin: 10px;padding: 25px 15px 25px 15px;}}
@media (max-width:767px) {.brz .brz-css-ywkvy {margin: 10px;padding: 20px 0px 20px 0px;}}
.brz .brz-css-rodwt {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-rodwt {margin: 10px 0px 10px 0px;}}
@media (max-width:767px) {.brz .brz-css-rodwt {margin: 10px 0px 10px 0px;}}
.brz .brz-css-obmhm {flex: 1 1 25%;max-width: 25%;justify-content: center;}
.brz .brz-css-obmhm > .brz-bg {margin: 10px;}
.brz .brz-css-obmhm > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,.5);}
@media (min-width:991px) {.brz .brz-css-obmhm:hover > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,.5);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-obmhm {flex: 1 1 25%;max-width: 25%;justify-content: center;}
.brz .brz-css-obmhm > .brz-bg {margin: 10px;}
.brz .brz-css-obmhm > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,.5);}}
@media (max-width:767px) {.brz .brz-css-obmhm {flex: 1 1 50%;max-width: 50%;justify-content: center;}
.brz .brz-css-obmhm > .brz-bg {margin: 10px;}
.brz .brz-css-obmhm > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,.5);}}
.brz .brz-css-whyau {margin: 10px;padding: 55px 15px 55px 15px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-whyau {margin: 10px;padding: 25px 15px 25px 15px;}}
@media (max-width:767px) {.brz .brz-css-whyau {margin: 10px;padding: 20px 0px 20px 0px;}}
.brz .brz-css-ciusm {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ciusm {margin: 10px 0px 10px 0px;}}
@media (max-width:767px) {.brz .brz-css-ciusm {margin: 10px 0px 10px 0px;}}
.brz .brz-css-gpmal {flex: 1 1 25%;max-width: 25%;justify-content: center;}
.brz .brz-css-gpmal > .brz-bg {margin: 10px;}
.brz .brz-css-gpmal > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,.5);}
@media (min-width:991px) {.brz .brz-css-gpmal:hover > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,.5);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-gpmal {flex: 1 1 25%;max-width: 25%;justify-content: center;}
.brz .brz-css-gpmal > .brz-bg {margin: 10px;}
.brz .brz-css-gpmal > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,.5);}}
@media (max-width:767px) {.brz .brz-css-gpmal {flex: 1 1 50%;max-width: 50%;justify-content: center;}
.brz .brz-css-gpmal > .brz-bg {margin: 10px;}
.brz .brz-css-gpmal > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,.5);}}
.brz .brz-css-umbpn {margin: 10px;padding: 55px 15px 55px 15px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-umbpn {margin: 10px;padding: 25px 15px 25px 15px;}}
@media (max-width:767px) {.brz .brz-css-umbpn {margin: 10px;padding: 20px 0px 20px 0px;}}
.brz .brz-css-aluxd {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-aluxd {margin: 10px 0px 10px 0px;}}
@media (max-width:767px) {.brz .brz-css-aluxd {margin: 10px 0px 10px 0px;}}
.brz .brz-css-zybzz {flex: 1 1 25%;max-width: 25%;justify-content: center;}
.brz .brz-css-zybzz > .brz-bg {margin: 10px;}
.brz .brz-css-zybzz > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,.5);}
@media (min-width:991px) {.brz .brz-css-zybzz:hover > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,.5);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-zybzz {flex: 1 1 25%;max-width: 25%;justify-content: center;}
.brz .brz-css-zybzz > .brz-bg {margin: 10px;}
.brz .brz-css-zybzz > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,.5);}}
@media (max-width:767px) {.brz .brz-css-zybzz {flex: 1 1 50%;max-width: 50%;justify-content: center;}
.brz .brz-css-zybzz > .brz-bg {margin: 10px;}
.brz .brz-css-zybzz > .brz-bg > .brz-bg-color {background-color: rgba(255,255,255,.5);}}
.brz .brz-css-mcmoy {margin: 10px;padding: 55px 15px 55px 15px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-mcmoy {margin: 10px;padding: 25px 15px 25px 15px;}}
@media (max-width:767px) {.brz .brz-css-mcmoy {margin: 10px;padding: 20px 0px 20px 0px;}}
.brz .brz-css-ukifp {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ukifp {margin: 10px 0px 10px 0px;}}
@media (max-width:767px) {.brz .brz-css-ukifp {margin: 10px 0px 10px 0px;}}
.brz .brz-css-cbzun {padding: 100px 0px 244px 0px;}
.brz .brz-css-cbzun > .brz-bg > .brz-bg-image {background-image: url("./img/c5c63f4acb5ba5d6372d0a1944f91aa0.jpg");background-position: 55% 100%;}
.brz .brz-css-cbzun > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/c5c63f4acb5ba5d6372d0a1944f91aa0.jpg");}
.brz .brz-css-cbzun > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,.51);}
.brz .brz-css-cbzun > .brz-bg > .brz-bg-shape__bottom {background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTkyMCAyNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+PHBhdGggZD0iTTE5MjAgMEgwdjE0NC40MTlMNjQwIDI0MGw2NDAtMTg5LjE5NyA2NDAgOTMuNjE2eiIgZmlsbD0icmdiYSgzOCwgMzUsIDQyLCAxKSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");background-size: 100% 178px;height: 178px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-cbzun {padding: 75px 15px 125px 15px;}
.brz .brz-css-cbzun > .brz-bg > .brz-bg-image {background-image: url("./img/c5c63f4acb5ba5d6372d0a1944f91aa0.jpg");background-position: 55% 100%;}
.brz .brz-css-cbzun > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/c5c63f4acb5ba5d6372d0a1944f91aa0.jpg");}
.brz .brz-css-cbzun > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,.51);}
.brz .brz-css-cbzun > .brz-bg > .brz-bg-shape__bottom {background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTkyMCAyNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+PHBhdGggZD0iTTE5MjAgMEgwdjE0NC40MTlMNjQwIDI0MGw2NDAtMTg5LjE5NyA2NDAgOTMuNjE2eiIgZmlsbD0icmdiYSgzOCwgMzUsIDQyLCAxKSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");background-size: 100% 67px;height: 67px;}}
@media (max-width:767px) {.brz .brz-css-cbzun {padding: 75px 15px 120px 15px;}
.brz .brz-css-cbzun > .brz-bg > .brz-bg-image {background-image: url("./img/c5c63f4acb5ba5d6372d0a1944f91aa0.jpg");background-position: 55% 100%;}
.brz .brz-css-cbzun > .brz-bg > .brz-bg-image:after {content: "";background-image: url("./img/c5c63f4acb5ba5d6372d0a1944f91aa0.jpg");}
.brz .brz-css-cbzun > .brz-bg > .brz-bg-color {background-color: rgba(249,157,0,.51);}
.brz .brz-css-cbzun > .brz-bg > .brz-bg-shape__bottom {background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTkyMCAyNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+PHBhdGggZD0iTTE5MjAgMEgwdjE0NC40MTlMNjQwIDI0MGw2NDAtMTg5LjE5NyA2NDAgOTMuNjE2eiIgZmlsbD0icmdiYSgzOCwgMzUsIDQyLCAxKSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");background-size: 100% 61px;height: 61px;}}
.brz .brz-css-wwajo {flex: 1 1 75.4%;max-width: 75.4%;}
.brz .brz-css-wwajo > .brz-bg {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-wwajo {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-wwajo > .brz-bg {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-wwajo {flex: 1 1 100%;max-width: 100%;}
.brz .brz-css-wwajo > .brz-bg {margin: 0px 0px 10px 0px;}}
.brz .brz-css-uarps {margin: 0;padding: 5px 15px 5px 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-uarps {margin: 0;padding: 5px 15px 5px 15px;}}
@media (max-width:767px) {.brz .brz-css-uarps {margin: 0px 0px 10px 0px;padding: 0;}}
.brz .brz-css-fanxz {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-fanxz {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-fanxz {margin: 0;}}
.brz .brz-css-zfrck {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 36px;line-height: 1.4;font-weight: 400;letter-spacing: -1px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-zfrck {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 35px;line-height: 1.3;font-weight: 400;letter-spacing: -.5px;}}
@media (max-width:767px) {.brz .brz-css-zfrck {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 29px;line-height: 1.3;font-weight: 400;letter-spacing: -.5px;}}
.brz .brz-css-nmclb {flex: 1 1 24.6%;max-width: 24.6%;justify-content: center;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-nmclb {flex: 1 1 100%;max-width: 100%;justify-content: center;}}
@media (max-width:767px) {.brz .brz-css-nmclb {flex: 1 1 100%;max-width: 100%;justify-content: center;}}
.brz .brz-css-hilfl {padding: 5px 0px 5px 15px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-hilfl {padding: 40px 15px 5px 15px;}}
@media (max-width:767px) {.brz .brz-css-hilfl {padding: 0;}}
.brz .brz-css-dyepn {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-dyepn {margin: 10px 0px 10px 0px;}}
@media (max-width:767px) {.brz .brz-css-dyepn {margin: 0;}}
.brz .brz-css-byljw {justify-content: flex-end;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-byljw {justify-content: center;}}
@media (max-width:767px) {.brz .brz-css-byljw {justify-content: center;}}
.brz .brz-css-oakcs.brz-btn {color: rgba(0,0,0,1);border: 0px solid rgba(255,255,255,0);border-radius: 15px;background-color: rgba(253,253,253,1);padding: 14px 42px 14px 42px;}
.brz .brz-css-oakcs.brz-btn.brz-btn-submit {color: rgba(0,0,0,1);background-color: rgba(253,253,253,1);}
.brz .brz-css-oakcs .brz-btn--story-container {border: 0px solid rgba(255,255,255,0);border-radius: 15px;}
@media (min-width:991px) {.brz .brz-css-oakcs.brz-btn:hover {color: rgba(249,157,0,1);border: 0px solid rgba(249,157,0,0);background-color: rgba(253,253,253,.98);}
.brz .brz-css-oakcs.brz-btn.brz-btn-submit:hover {color: rgba(249,157,0,1);background-color: rgba(253,253,253,.98);}
.brz .brz-css-oakcs:hover .brz-btn--story-container {border: 0px solid rgba(249,157,0,0);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-oakcs.brz-btn {color: rgba(0,0,0,1);border: 0px solid rgba(255,255,255,0);border-radius: 0px;background-color: rgba(253,253,253,1);padding: 22px 60px 22px 60px;}
.brz .brz-css-oakcs.brz-btn.brz-btn-submit {color: rgba(0,0,0,1);background-color: rgba(253,253,253,1);}
.brz .brz-css-oakcs .brz-btn--story-container {border: 0px solid rgba(255,255,255,0);border-radius: 0px;}}
@media (max-width:767px) {.brz .brz-css-oakcs.brz-btn {color: rgba(0,0,0,1);border: 0px solid rgba(255,255,255,0);border-radius: 0px;background-color: rgba(253,253,253,1);padding: 22px 60px 22px 60px;}
.brz .brz-css-oakcs.brz-btn.brz-btn-submit {color: rgba(0,0,0,1);background-color: rgba(253,253,253,1);}
.brz .brz-css-oakcs .brz-btn--story-container {border: 0px solid rgba(255,255,255,0);border-radius: 0px;}}
.brz .brz-css-jakdx {padding: 30px 0px 70px 0px;}
.brz .brz-css-jakdx > .brz-bg > .brz-bg-color {background-color: rgba(38,35,42,1);}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-jakdx {padding: 40px 15px 40px 15px;}
.brz .brz-css-jakdx > .brz-bg > .brz-bg-color {background-color: rgba(38,35,42,1);}}
@media (max-width:767px) {.brz .brz-css-jakdx {padding: 25px 15px 25px 15px;}
.brz .brz-css-jakdx > .brz-bg > .brz-bg-color {background-color: rgba(38,35,42,1);}}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-biqqb > .brz-row {flex-direction: row;flex-wrap: wrap;justify-content: flex-start;}}
@media (max-width:767px) {.brz .brz-css-biqqb > .brz-row {flex-direction: row-reverse;flex-wrap: wrap-reverse;justify-content: flex-end;}}
.brz .brz-css-knzat {flex: 1 1 90.7%;max-width: 90.7%;justify-content: center;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-knzat {flex: 1 1 90.7%;max-width: 90.7%;justify-content: center;}}
@media (max-width:767px) {.brz .brz-css-knzat {flex: 1 1 60.3%;max-width: 60.3%;justify-content: center;}}
.brz .brz-css-sojug {padding: 5px 15px 5px 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-sojug {padding: 5px 15px 5px 15px;}}
@media (max-width:767px) {.brz .brz-css-sojug {padding: 0;}}
.brz .brz-css-njfvk {margin: 0;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-njfvk {margin: 0;}}
@media (max-width:767px) {.brz .brz-css-njfvk {margin: 0;}}
.brz .brz-css-ajpoj {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 16px;line-height: 1.9;font-weight: 400;letter-spacing: 0px;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-ajpoj {margin-top: 0px !important;margin-bottom: 0px !important;text-align: left;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
@media (max-width:767px) {.brz .brz-css-ajpoj {margin-top: 0px !important;margin-bottom: 0px !important;text-align: center;font-family: 'Nunito Regular';font-size: 15px;line-height: 1.6;font-weight: 400;letter-spacing: 0px;}}
.brz .brz-css-mwvns {flex: 1 1 9.3%;max-width: 9.3%;}
@media (max-width:991px) and (min-width:768px) {.brz .brz-css-mwvns {flex: 1 1 9.3%;max-width: 9.3%;}}
@media (max-width:767px) {.brz .brz-css-mwvns {flex: 1 1 16.5%;max-width: 16.5%;}}

.mtb-35{
    margin-top: 35px;
    margin-bottom: 35px;
}
.brz{
    background: white;
}

.brz-bg-image-parallax{
    transform: translate3d(0px, 491.805px, 0px);
}

.brz .brz-bg-image-parallax{
    top: 59vh!important;
}
.lohin-htn{
    max-width: 30%!important;
    text-align: right;
    width: 50%;
    flex: 1 1 30%!important;
    margin-top: 24px;
}

.lohin-htn button{
    max-width: 200px;
}
.brz .brz-css-qdygj.main-text  {
    flex: 1 1 81.9%!important;
    max-width: 87.9%!important;
}
.brz .brz-css-xqjbh {
    flex: 1 1 9.1%!important;
    max-width: 14.1%!important;
}

.url-view span:hover{
    color: #ffc107;
}

.f-18{
    font-size: 18px;
    font-family: 'Nunito Regular';
    /* font-size: inherit; */
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    line-height: 30px;
}
.new-secontion-mb{
    margin-bottom:19vh!important;
}



@media (max-width: 767px){
    .lohin-htn button{
        padding: 12px!important;
    
    }

    .brz-css-xbqfe{
        font-size: 30px!important;
    }

}

.videosection{
   width:100%;
   height: 600px;
   object-fit:cover;
}

@media only screen and (max-width: 1024px) {
    .videosection{
        width:100%;
        height: auto;
        
     }
     
  }