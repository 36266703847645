@media (min-width: 1700px) {}

    @media (max-width: 1800px) {
        .product-min > div.sku { width: 140px; }
        .product-min > div.title { max-width: 180px; }
        .product-min > div.category { width: 229px; }
    }
    
    @media (max-width: 1700px) {
        .user_tabss {padding: 0 30px;}
        .product-info-wrap .product-info-col:last-child { display: none; }
        .product-info-col { width: 100%; }
        .product-info-wrap .product-info-col:first-child { width: 100%; }
        .product-min > div.category,.product-min > .msrp-price.price, .product-min > .list-price.price,.product-min > .margin-price.price { display: none; }
        .product-min > div.title { max-width: 180px; }
    }


    @media (min-width: 1400px) {
        .container-fluid {
            padding-left: 30px;
            padding-right: 30px;
        }
        
        
    }

    
    @media (max-width: 1399.98px) {
        .section-title h2 {
            font-size: 38px;
        }
        h1, .h1 {
            font-size: 40px;
        }
        h2, .h2 {
            font-size: 36px;
        }
        h3, .h3 {
            font-size: 34px;
        }
        h4, .h4 {
            font-size: 30px;
        }
        h5, .h5 {
            font-size: 24px;
        }
        .advanced-search-content-wrap { min-width: 550px; }
        /* .product-min > div.title{max-width:105px;} */
        .product-min > div.price { width: 80px; }
        .product-info-wrap tr td:first-child{min-width:auto;}
        .product-price-wrap { width: 130px; }
        .product-content-wrap { width: calc(100% - 230px); }
        .catalog-wrap .first .btn-group.views .tool-tip-icon.btns.feed-bttn { margin: 0;width:180px;}
        
    }
    
    @media (min-width: 1200px) {}
    
    @media (max-width: 1199.98px) {
        .section-title h2 {
            font-size: 36px;
        }
        .section-title h1{font-size:1.8rem;}
        h1, .h1 {
            font-size: 36px;
        }
        h2, .h2 {
            font-size: 34px;
        }
        h3, .h3 {
            font-size: 32px;
        }
        h4, .h4 {
            font-size: 28px;
        }
        h5, .h5 {
            font-size: 22px;
        }
        .page-section {
            padding: 60px 0;
        }
        .page-section-top {
            padding-top: 60px;
        }
        .page-section-bottom {
            padding-bottom: 60px;
        }
        .product-content-wrap { width: 100%; margin-top: 30px; }
        .product-price-wrap { width: 100%; -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; margin-top: 20px; }
        .product-img { width: 100%; text-align: center; }
        .product-listing-box { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
        .product-pr-box + .product-pr-box { margin-top: 0; }
        .supplier-listing-wrapper > div > div:nth-child(n + 4) { margin-top:20px; }
        .product-heading .product-min > div.status, .product-heading .product-min > div.image,.product-heading .product-min > div.sku,.product-heading .product-min > div.title,.product-heading .product-min > div.category,.product-heading .product-min > div.price,.product-heading .product-min > div.quantity { display: none; }
        .logod_col { -webkit-box-flex: 0; -ms-flex: 0 0 100%; flex: 0 0 100%; }
        .setup_col { -webkit-box-flex: 0; -ms-flex: 0 0 120px; flex: 0 0 120px; }

        .product-mobile .mobile-con .main .main-table .content { width: 100%; max-width: calc(100% - 180px); }
        .product-mobile .mobile-con .main .main-table .image { width: 180px; padding: 0 30px; }
        
    }
    
    @media (max-width: 1199.98px){
        .product .product-min { display: none; }
        .product-mobile{display: block;}
        .brandSearch_listing_wrap .brands-cons-list { width: 50%; }
        .catalog-wrap .second { position: relative; top: 0; width: 100%; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); text-align: center; margin-top: 20px;background-color: transparent !important;border: 0;}
    }

    
    @media (min-width: 992px) {}
    
    @media (max-width: 991.98px) {
        html {
            font-size: 14px;
        }
        h1, .h1 {
            font-size: 36px;
        }
        h2, .h2 {
            font-size: 32px;
        }
        h3, .h3 {
            font-size: 28px;
        }
        h4, .h4 {
            font-size: 24px;
        }
        h5, .h5 {
            font-size: 20px;
        }
        .section-title h2 {
            font-size: 36px;
        }
        .theme-btn,
        .theme-btn-1 {
            padding: 15px 30px;
            font-size: 1rem;
        }
        .product-col-left { max-width: 220px; }
        .product-col-right {max-width: calc(100% - 220px);}
        .advanced-search-wrap,.search-input-wrapper { width: 100%; }
        .search-input-wrapper {margin: 10px 0;}
        .search-filter-wrap { margin-left: auto; }
        .supplier-listing-wrapper > div > div:nth-child(n + 3) { margin-top:20px; }
        .product-full > .images > i { font-size:100px; }
        .product-full > .images { width: 120px; }
        .product-full > .row { width: calc(100% - 120px); }
        
        .catalogue-modal-wrap .modal-lg { max-width: calc(100% - 30px); }
        .ints_feeds .feed-rw .mob-integration,.ints_feeds .feed-rw .title_col { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
        .product-mobile .mobile-con .main .main-table .content .foot span { display: block; width: 100%; text-align: center; }

        .advanced-search-content-wrap { min-width: 100%; }
        .advanced-search-wrap { position: relative; }
    }
    
    @media (min-width: 768px) {}

    @media (max-width: 768px) {
        /* Purvank CSS 22-11-2021 */
        .products_listing .dropdown-menu {display: none !important; background: #f3f3f4;}
        .products_listing .dropdown-menu.show {display: block !important; } 
        .btn-group.products_listing {background: #f3f3f4; padding: 5px 15px; } 
        .products_listing .dropdown-toggle::after {display: block; position: absolute; top: 17px; right: 0;}
        /* Purvank CSS 22-11-2021 */
        .top-section{padding:80px 0;}
        .feed-status-section { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
        .feed-status-section h1 { font-size: 18px; }
        .feed-status-toggle { width: 100%; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; margin-bottom: 10px; }
        .feed-status-section h1 a { margin-left: 0; }
        .login-page.register{padding:30px 10px;}
    }
    
    @media (max-width: 767.98px) {
        .p_orders_table {
            overflow-x: auto;
        }
        .section-title h2 {
            font-size: 28px;
        }
        .hero-title h2 {
            font-size: 20px;
            margin-bottom: 15px;
        }
        .hero-area-content .theme-btn-1 {
            padding: 10px 20px;
        }
        /* Purvank CSS 16-11-2021 */
        .p_orders_table .dataTables_length, .p_orders_table .dataTables_filter {width: 100%;}
        .p_orders_table .dataTables_filter {padding-right: 0;}
        .p_orders_table .dataTables_filter label {float: none; display: -webkit-box; display: -ms-flexbox; display: flex; } 
        .p_orders_table .dataTables_filter input {width: 100%; } 
        .p_orders_table .dataTables_info, .p_orders_table .dataTables_paginate.paging_simple_numbers {width: 100%;}
        .p_orders_table .dataTables_paginate.paging_simple_numbers {text-align: left;}
        /* Purvank CSS 16-11-2021 */
        .btns_grps .btn { width: 100%; }
        .btns_grps .btn + .btn { margin-top: 10px; }
        .product-col-left,.product-col-right { max-width: 100%; }
        .product-col-right { margin-top:10px; }
        .supplier-listing-wrapper > div > div:nth-child(n + 2) { margin-top:20px; }
        .catogory-title-wrap { margin-bottom: 0;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
        .catogory-title-wrap.active > i::before {content: '\f077';}
        .categiry-listing-wrap { padding-top: 20px; }
        .product-mobile .mobile-con .main .main-table .content { max-width: 100%; }
       
    }
    
    @media (min-width: 576px) and (max-width:767px) {
        .content-wrap:not(.collapsed) .stat_col,.content-wrap:not(.collapsed) .manage_product_col,.content-wrap:not(.collapsed) .setup_col { -webkit-box-flex: 0; -ms-flex: 0 0 50%; flex: 0 0 50%; }
        .content-wrap:not(.collapsed) .stat_col { margin-bottom: 15px; }
    }
    
    @media (max-width: 575.98px) {
        h1,
        .h1 {
            font-size: 30px;
        }
        h2,
        .h2 {
            font-size: 26px;
        }
        h3,
        .h3 {
            font-size: 22px;
        }
        h4,
        .h4 {
            font-size: 18px;
        }
        h5,
        .h5 {
            font-size: 16px;
        }
        .section-title h2 {
            font-size: 26px;
        }
        /* Purvank CSS 12-11-2021 */
        .login-page {padding: 15px;}
        .p_orders_table .dataTables_length, .p_orders_table .dataTables_filter {width: 100%;}
        .p_orders_table .dataTables_filter {padding-right: 0;}
        .p_orders_table .dataTables_filter label {float: none; display: -webkit-box; display: -ms-flexbox; display: flex; } 
        .p_orders_table .dataTables_filter input {width: 100%; } 
        .p_orders_table .dataTables_info, .p_orders_table .dataTables_paginate.paging_simple_numbers {width: 100%;}
        .p_orders_table .dataTables_paginate.paging_simple_numbers {text-align: left;}
        .action_dropdown {width: 100%; margin-right: 0; }
        .action_dropdown .col-lg-6:first-child {margin-bottom: 15px;}
        .common_btn.float-right {float: none;}
        /* Purvank CSS 12-11-2021 */


        /* Purvank CSS 17-11-2021 */
        .main-sidebar.collapsed {
            width: 68px;
            overflow: visible;
            left: -68px;
        }
        .content-wrap {
            margin-left: 0;
        }
        .content-wrap.collapsed {
            margin-left: 0;
        }
        .header-bar {
            position: fixed;
            width: 100%;
            top: 0;
        }
       
        .content-wrap.position-relative.collapsed.not-collapsed:before {
            content: '';
            display: none;
        }
        .content-wrap.position-relative.collapsed.not-collapsed .header-bar {
            -webkit-box-shadow: 0px 8px 8px 0px rgba(204, 204, 204, 0.75);
                    box-shadow: 0px 8px 8px 0px rgba(204, 204, 204, 0.75);
        }
        .content-area {
            margin-top: 60px;
        }
        .user_tabss {
            padding: 50px 0;
        }
        .col-xs-6 {
            -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                    flex: 0 0 auto;
            width: 50%;
        }
        .common_btn {
            padding: 8px 20px;
        }
        .servic_table table th, .servic_table table td {min-width: 120px;}
        .action_dropdown select {padding: 8px 22px 8px 10px;}
        /* Purvank CSS 17-11-2021 */

        .product-content-wrap h3 { font-size: 16px; line-height: 1.2; text-align: center; }

        .advanced-search-content-wrap { min-width: 100%; }
        .advanced-search-box-title,.advanced-search-box-input-wrap { width: 100%; }
        .advanced-search-row + .advanced-search-row { margin-top: 15px; }
        .advanced-search-box-title h3 { margin-bottom: 2px; }
        /* Purvank CSS 26-11-2021 */
        .breadcrumb_div {
            position: initial;
            margin-top: 15px;
        }
        .img_logo_input {
            padding-left: 0px;
        }        
        /* Purvank CSS 26-11-2021 */

        /* Purvank CSS 29-11-2021 */
        /*.user_login_logout {display: none;}*/
        /* Purvank CSS 29-11-2021 */

        /* Purvank CSS 18-12-2021 */
        .feed-rw .col-6 {-webkit-box-pack: center !important;-ms-flex-pack: center !important;justify-content: center !important; width: 100%;}
        .integrations_heading {display: -ms-grid !important;display: grid !important;}
        .integrations_heading h1 {-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2; margin-top: 32px;}
        .feed-rw {display: -ms-grid !important;display: grid !important; padding: 0;}
        .logod_col {-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center; padding:10px 0 !important; -webkit-box-flex: 100%; -ms-flex: 100%; flex: 100%; -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2;}
        .title_col {-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; padding: 10px 0 !important; border-bottom: 1px solid #ddd;}
        .feed-rw .col-6:nth-child(2) {
            background: #FAFAFA;
        }
        .feed-rw .col-6:nth-child(2) div {
            border-right: 1px solid #ddd;
            padding: 6px 0;
        }
        .feed-rw .col-6:nth-child(2) div:first-child {
            border: 0;
        }
        .stat_col, .setup_col, .manage_product_col {
            -webkit-box-flex: 1;
                -ms-flex: auto;
                    flex: auto;
        }
        .stat_col:nth-child(1) {-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
        .stat_col {-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
        .setup_col {-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3; height: 50px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;width: 100%;}
        .title_col h2 {font-size: 14px;}
        .feed-rw .is_narrow i.fa-exchange-alt {
            font-size: 24px;
            padding: 0 20px;
        }
        /* Purvank CSS 18-12-2021 */

        .searchByBrands_modal .search-wrapper .search-input-wrapper { width: 100%; margin-bottom: 10px; }
        /* .product-mobile .mobile-con .main .main-table .image { width: 16px; font-size: 20px; } */
        .product-mobile .content { width: 100%; }
        .product-full > .row { width: 100%; margin-top: 20px; }
        .product-full { width: 100%; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
        .product-full > .images > i { font-size: 60px; }
        .catalogue-rules-inner-tab-wrap .nav-item button { padding: 10px 5px;width:100%;}
        .catalogue-rules-inner-tab-wrap > ul { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
        .catalogue-rules-inner-tab-wrap ul.nav-pills > button { margin: 10px auto 10px; }
        .catalogue-input-wrap .form-group select { width: 100%; }
        .example-products .content { overflow: auto; }
        .example-products .content table td, .example-products .content table th { white-space: nowrap; padding: 5px 8px; }
        .catalogue-input-wrap label { width: 100%; }
        .switch-con > div:last-child { width: 100%; }
        .switch-con .custom-switch-wrap { width: auto; }
        .catalogue-rules-inner-tab-wrap > ul li { width: 100%; }
        .catalogue-rules-inner-tab-wrap > ul > li + li { border-left: 0; }
        .catalogue-modal-wrap .modal-lg { max-width: 100%; }
        .nested-box-wrap { margin-right: 0; margin-left: 10px; padding-left: 10px; }
        .nested-box-wrap h3 { font-size: 14px; }
        .category-mapping-title,.category-mapping-filter-wrap { -ms-flex-wrap: wrap; flex-wrap: wrap; }
        .category-mapping-filter-wrap button:last-child::before { content: ''; display: block; width: 100%; }
        .category-mapping-title h2 { width: 100%; font-size: 20px; }
        .category-mapping-filter-wrap > * { margin: 10px 0; }
        .category-mapping-title { margin-bottom: 20px; }
        .category-product--modal-listing--box .see-more { width: 40px; }
        .section-title{padding: 20px 15px;}
        .brandSearch_listing_wrap .brands-cons-list { width:100%; }
        .search-input-wrapper-1 { width: 100%; margin: 10px 0; -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2; }
    }


    @media (max-width: 360px) {
        .d_block.ms-auto {
            margin: 10px 0 !important;
            display: block;
            width: 100%;
        }
        .nav-link.common_btn {
            padding: 12px 20px;
            font-size: 14px;
        }
        
    }

    /* Purvank CSS 26-11-2021 */
    @media (max-width: 320px) {
        ul.breadcrumb li {
            font-size: 12px;
        }
    }
    /* Purvank CSS 26-11-2021 */