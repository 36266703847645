a,span,i{display:inline-block;}
.login-page{
    width: 100%;
    min-height: 100vh;
}   
.text-left{
  text-align:left;
}
.text-right{
  text-align:right;
}
.section-title{
    padding: 40px 30px;
}

.supplier-listing-feature-wrap-1{
    margin: 15px 0 20px;
    text-align: left;
}

.supplier-listing-feature-wrap-1 li {
   
    -webkit-box-align: start;
   
        -ms-flex-align: start;
   
            align-items: flex-start;
    font-size: 14px;
    line-height: 1.4;
}
.text-primary{
  color: #ff9a00!important;
}
.bg-primary{
  background-color:  #ff9a00!important;
}
.w-30{
  width: 30px;
}
.w-40{
  width:40px;
}
.supplier-listing-feature-wrap-1 ul{
    padding-left: 20px;
}

.main-sidebar{
    overflow: auto;
} 

.main-sidebar::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .main-sidebar::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .main-sidebar::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .main-sidebar::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background: #ff9a00;
    border: 1px solid #ff9a00;
}

.arrow-con{
  cursor: pointer;
  padding-top: 10px;
}

.disable-link{
  opacity: 0.2;
  cursor: auto;
}

.custom-dropdown, .dropdown-toggle {
  background-color: #f1f5f9;
  padding: 10px 10px;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,.125);
  line-height: 1;
  color: #323232;
}

.custom-dropdown, .dropdown-toggle:hover {
  color: #ff9a00;
  border: 1px solid rgba(0,0,0,.125);
  background-color: #f1f5f9;
}

/* .custom-dropdown, .btn-check:active + .btn-primary, .btn-check:checked + .btn-primary, .btn-primary.active, .btn-primary:active, .show > .btn-primary.dropdown-toggle, .btn-check:focus + .btn-primary, .btn-primary:focus{
  color: #ff9a00;
  border: 1px solid rgba(0,0,0,.125);
  background-color: #f1f5f9;
} */

.category-list-box:nth-last-child(2) {
  border-bottom: 1px solid #5454!important;
}

.fields-con{
  max-height: 650px;
  overflow: auto;
}

.fields-con::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.fields-con::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.fields-con::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.fields-con:-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.distri-bttn {
  font-size: 16px;
  line-height: 1;
  background-color: #ff9a00;
  color: #ffffff;
  display: inline-block;
  padding: 12px 25px;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.supplier-listing-box h3{
  margin-top: 10px;
}

.customer-spinner{
  
  margin-right: 10px;
  width: 16px;
  height: 16px;

}

.supplier-listing-box img {
  max-width: 250px;
  height: 100px;
  width: 100%;
}

.country-data-s{
  text-align: center;
    text-transform: capitalize;
}

.custom-success {
  font-size: 16px;
  line-height: 1;
  background-color: #198754;
  
  color: #ffffff;
  display: inline-block;
  padding: 12px 25px;
  border-radius: 6px;
}

.section.min-width {
  margin: 0 auto;
  padding: 18px;
}

.btn-gold {
  background-color: #FFCB62;
  border-color: #FFCB62;
}

.c-spiner{
  margin: auto;
  width: 60px;
  height: 60px;
  margin-top: 20px;
}
.sidebar-nav li a:hover{
  color: #ff9a00!important;
  background: #000!important;
}
.sidebar-nav li a:focus{
  color: #ff9a00!important;
  background: #000!important;
}

.active-page a{
  color: #ff9a00!important;
  background: #000!important;
}
.sidebar-nav li a:active{
  color: #ff9a00!important;
  background: #000!important;
}

.requestNewintegrateBox-wrap form button {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 495 434.4' style='enable-background:new 0 0 495 434.4;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath id='XMLID_53_' class='st0' d='M164.7,426.4c0,3,1.6,5.7,4.3,7.1c2.6,1.4,5.8,1.2,8.2-0.5l55.1-37.6l-67.6-32.2L164.7,426.4 L164.7,426.4z'/%3E%3Cpath id='XMLID_52_' class='st0' d='M492.4,2.1C490.9,0.7,489,0,487,0c-1.2,0-2.4,0.3-3.5,0.8L7.9,234.1C3,236.5,0,241.5,0,246.9 c0,5.4,3.2,10.4,8.1,12.7l125.3,59.7l250.6-206L164.5,334.1l156.1,74.4c1.9,0.9,4,1.4,6.1,1.4c1.8,0,3.5-0.3,5.2-1 c3.6-1.4,6.5-4.3,8-8l154.6-390C495.7,7.9,494.9,4.4,492.4,2.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-color: #ff9a00;
  border-color: #ff9a00;
  color: #FFF;
  width: auto;
  border-radius: 4px;
  padding: 6px 10px 6px 30px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center left 8px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.requestNewintegrateBox-wrap form button:hover {
  background-color: #FFCB62;
}
.cl-form .form-select {
  outline: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  height: 39px;
  border: 1px solid #e5e6e7;
  padding: 8px 10px;
  width: 400px;
}

.register .form-select {

  height: 45px;

}

.requestNewintegrateBox-wrap form input{
  width: 400px;
}

a{
  cursor: pointer!important;
}

.advanced-search-content-wrap{
  cursor: auto;
 
}
.product-listing-box:hover{
  border-color: #ffbe5a;
  -webkit-box-shadow: 1px 1px 2px 0 #e3e3e3;
          box-shadow: 1px 1px 2px 0 #e3e3e3;
}

.product-listing-box{
  cursor: pointer;
}

.btn-close {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: .5;
  position: initial;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin: 0;
}

.advanced-search-content-wrap{
  position: absolute;
  background-color: #FFF;
  padding: 20px;
 
  z-index: 1000;
  border-bottom: 4px solid #ff9a00;
  -webkit-box-shadow: 0 0 6px 2px #d9d9d9;
          box-shadow: 0 0 6px 2px #d9d9d9;
  border-radius: 0 5px 5px;
  margin-top: 10px;
}

a.text-ls-primary:hover{
  color: #a76705;
}
.text-ls-primary{
  color: #ff9a00;
}

.float-left{
  float: left;
}

.float-right{
  float: right;
}

.login-note{
  font-size: 13px;
  color: #000000ba;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
}

.cat-block-cha{
  margin-top: 0px;
  border-top: 0px;
  padding-top: 0px;
  border-bottom: 1px solid #E3E3E3;
  padding-bottom: 12px;
}

.top-section {
  padding: 100px 0px!important;

}

input[type="text"]:disabled {
  opacity: 0.7;
  background: #d5d5d5;
}

.newText-Center h3{
  text-align: center!important;
}

.newText-Center .modal-header{
  text-align: center!important;
  justify-content: center!important;
}



.search-attr-item .modal-header{
  padding: 0px;
  border-bottom: none;
}

.same-description{
  font-size: 13px;
  color: #7a7a7a;
  line-height: 1.8;
  font-weight: 700;
  padding-right: 10px;
  min-width: 170px;
  font-family: 'Nunito SemiBold';
}

.pagination_wrap_custom, .pagination_wrap_custom li{
  list-style-type: none;
}

.pagination_wrap_custom .page_box_custom {
  width: auto;
  min-width: 28px;
  padding: 5px;
  height: 28px;
}

.searchByBrands_modal  .modal-content {
 
}

/* .brandSearch_listing_wrap .brands-cons-list.active {
  box-shadow: 0 0 3px 1px #7f7f7f;
  font-weight: 700;
} */

.c-badges{
  padding-left: 10px;
  margin-right: 4px;
  padding-top: 7px;
  margin-bottom: 2px;
  padding-bottom: 8px;
  padding-right: 22px;
  position: relative;
}

.custom-close{
    background-color: transparent;
    color: #ffffff;
    font-size: 19px;
    position: absolute;
    top: 3px;
    right: 5px;
}

.modal-header .btn-close {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: .5;
  position: initial;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin: 0;
}

.responsive-font-auto{
  font-size: 1.4vw;
  font-weight: 700;
}

.btn-primary{
  background-color: #ff9a00;
  border: 1px solid #ff9a00;
}

.btn-primary:hover{
  background-color: #e98c00;
  border-color: #e98c00;
}


.wd-auth button {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 495 434.4' style='enable-background:new 0 0 495 434.4;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath id='XMLID_53_' class='st0' d='M164.7,426.4c0,3,1.6,5.7,4.3,7.1c2.6,1.4,5.8,1.2,8.2-0.5l55.1-37.6l-67.6-32.2L164.7,426.4 L164.7,426.4z'/%3E%3Cpath id='XMLID_52_' class='st0' d='M492.4,2.1C490.9,0.7,489,0,487,0c-1.2,0-2.4,0.3-3.5,0.8L7.9,234.1C3,236.5,0,241.5,0,246.9 c0,5.4,3.2,10.4,8.1,12.7l125.3,59.7l250.6-206L164.5,334.1l156.1,74.4c1.9,0.9,4,1.4,6.1,1.4c1.8,0,3.5-0.3,5.2-1 c3.6-1.4,6.5-4.3,8-8l154.6-390C495.7,7.9,494.9,4.4,492.4,2.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-color: #ff9a00;
  border-color: #ff9a00;
  color: #FFF;
  width: auto;
  border-radius: 4px;
  padding: 6px 10px 6px 30px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center left 8px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.wd-auth  button:hover {
  background-color: #FFCB62;
}
.wd-auth .form-select {
  outline: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  height: 39px;
  border: 1px solid #e5e6e7;
  padding: 8px 10px;
  width: 400px;
}

.wd-auth  input{
  width: 400px;
}

.wd-auth input {
  width: 400px;
  border: 1px solid #e5e6e7;
  padding: 8px 10px;
}

.select-size{
  background-color: #f1f5f9;
  padding: 6px 6px;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,.125);
  margin-right: 15px;
}

.line-height-2{
  line-height: 2.2!important;
}

.configure{
  cursor: pointer;
}

.ml-150{
  margin-left: 150px;
}
.bg-error{
  background-color: #ffabab!important;
}

[data-tooltip]:before {
  content: attr(data-tooltip);
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  padding: 5px;
  color: #333;
  border-radius: 4px;
  -webkit-box-shadow: 2px 2px 1px silver;
          box-shadow: 2px 2px 1px silver;
}

[data-tooltip]:hover:before {
  opacity: 1;
  background: #ece9e5;

  margin-top: 61px;
  margin-left: 20px;

  width: 95%;
} 


[data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
}

.btn-group > .btns:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btns:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btns{
  border: 1px solid #d2d2d2;
  -webkit-box-shadow: 3px 4px 17px 0px #858585;
          box-shadow: 3px 4px 17px 0px #858585;
}
.btns:hover{
  background-color: #e6e6e6;
}

.catalog-header{
  background-color: #5b5b5b!important;
}

.btns.active {
  /* background: #ffc107; */
  color: #ffff;
  background-color: #ff9a00;
  color: #ffffff;
  border-color: #ff9a00;
  border: 1px solid #ff9a00;
  /* box-shadow: 3px 4px 17px 0px #858585; */
}

.feed-bttn{
  border-radius: 0px!important;
}

.active .btns-icon{
  color:#fff;
}

.border-top-card-1{
  border-bottom: 4px solid rgb(249 157 0);
  -webkit-box-shadow: 0 0 6px 2px #d9d9d9;
          box-shadow: 0 0 6px 2px #d9d9d9;
}
.category-title{
  text-transform: capitalize;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  /* height: 1.2em; */
  white-space: nowrap;
}

.border-top-card-2{
  border-bottom: 4px solid #198754;
  -webkit-box-shadow: 0 0 6px 2px #d9d9d9;
          box-shadow: 0 0 6px 2px #d9d9d9;
}

.btn-div-card{
  padding: 15px;
  border-bottom: 1px solid rgba(0,0,0,.125);
  font-size: 20px;

  font-weight: 600;

  text-align: center;
}

.to-bar{
  border-top: 1px solid rgba(0,0,0,.125);
  cursor: pointer;
  font-size: 18px;
}

.to-bar a:hover{
  color: #ffc107!important;
}

.call-out-box-1{
  padding: 30px 20px;
}

.call-out-box{
  padding: 0px!important;
}

.catalogue-input-wrap .input-group span {
  padding: 5px 10px!important;
  background-color: transparent;
}

.border-bottom-0{
  border-bottom: 0px;
}

.search-filter-wrap button{
  color: white;
}


.search-filter-wrap button:hover{
  color: white;
}

.visti-web{
  color: #ff9a00;
    border-bottom: 1px solid #ff9a00;
    border-radius: 0px;
    margin-bottom: 6px;
    line-height: 1;
}

.visti-web:hover{
  color: #4d3005;
    border-bottom: 1px solid #4d3005;
    border-radius: 0px;
    margin-bottom: 6px;
    line-height: 1;
}

.bg-orange{
  background-color: #ff9a00;
}

.setup_col .common_btn{
  color: white;
}

.navbar-close-icon{
  float: right;
  background: transparent;
  color: orange;
  font-size: 30px;
  padding-right: 8px;
}

.navbar-close-icon:hover{
  color: #fff;
}

.wd-auth input {
  width: 400px;
  border: 1px solid #e5e6e7;
  padding: 8px 10px;
}
.main-sidebar{
  z-index: 100!important;
}
.img_col img {
  height: 40px;
  -o-object-fit: contain;
     object-fit: contain;
}
.fileUpload{
  position: absolute;
  cursor: pointer;
  right: 76px;
}
.collapsed .fileUpload{
    position: absolute;
    right: 7px;
    font-size: 12px;
}

@media (max-width: 767.98px) {
  .wd-auth input{
    width: auto;
  }

  .custom-product-video-btn{
    padding: 14px 42px!important;
  }

  .responsive-font-auto{
    font-size: 25px!important;
  }
  .img_col img {
      height: 44px;
      -o-object-fit: contain;
         object-fit: contain;
  }
  .catogory-title-wrap {
    margin-bottom: 10px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}

.btn-tab-view{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.btn-tab-view button{
  margin-bottom: 4px;
  position: relative;
}

.sticky-main-top{
  z-index: 10;
}

.image-profile-rounded{
  -o-object-fit: cover;
     object-fit: cover;
  width: 100px;
  height: 100px;


}
.collapsed .image-profile-rounded{
  -o-object-fit: cover;
     object-fit: cover;
  width: 40px;
  height: 40px;


}

.custom-product-video-btn{
  margin: auto!important;
  font-size: 17px!important;
  margin-top: 25px!important;
  color: rgba(249,157,0,1)!important;
}

.custom-product-video-btn:hover{
  color: rgb(0, 0, 0)!important;
}

.max-width-fit-content .modal-lg{
  max-width: -webkit-fit-content!important;
  max-width: -moz-fit-content!important;
  max-width: fit-content!important;
}

.embed-youtube{
  padding: 20px 20px;
  display: block;
  background: rgb(246 245 245 / 75%);
  background: rgb(255 255 255 / 69%);

  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  border-color: #333;
}

.search-input-wrapper-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
  margin: 0 20px;
  width: calc(100% - 350px);
}
@media screen and (max-width: 991px) {
  .search-input-wrapper-1 {
    width: calc(100% - 227px);
  }
}

.register{
  height: auto!important;
  padding: 50px 100px;
}

.copy-rights p{
  color: #ffffff;
    font-weight: 500;
}

.customized{
  color: white!important;
  background-color: #ff9a00!important;
}

.non-customized{
  color: white!important;
  background-color: #b8a78e!important;
}

.product-title-test{
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 10px;
}
.second {

  background-color: #6c757d!important;
  border: 1px solid #6c757d;
 
}

.second.disabled{
  opacity: 0.7;
  background-color: #17a2b8!important;
}
.second.disabled-data{
  opacity: 0.7;
  background-color: #17a2b8!important;
}

.image-profile-rounded-1{
  -o-object-fit: cover;
     object-fit: cover;
    width: 200px;
    height: 200px;
}


.image-upload{
  font-size: 18px;
}

.ReactCrop__crop-selection{
  border-radius: 50%;
}

.profile-upload .modal-body{
  -webkit-box-shadow:none!important;
          box-shadow:none!important;
}

.profile-upload .modal-header{
  border-bottom:none!important;
  background-color: #fff!important;
}

.profile-upload h3{
  font-weight: 400;
  font-size: 32px;
}

.custom-pau {
  font-size: 16px;
  line-height: 1;
  background-color: #7e7e7e;
  color: #ffffff;
  display: inline-block;
  padding: 12px 25px;
  border-radius: 6px;
}

.custom-dag {
  font-size: 16px;
  line-height: 1;
  background-color: #ff5c5c;
  color: #ffffff;
  display: inline-block;
  padding: 12px 25px;
  border-radius: 6px;
}

.custom-rej {
  font-size: 16px;
  line-height: 1;
  background-color: #6c1313;
  color: #ffffff;
  display: inline-block;
  padding: 12px 25px;
  border-radius: 6px;
}

.w-40{
  width: 40px;
}

#root{
    font-family:'Nunito SemiBold';
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito Bold';
}
.feed-rw p {
    font-family: 'Nunito SemiBold';
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  -webkit-box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
          box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
  background-color: #ff9a00;
  border: 1px solid #ff9a00;
  padding: 0.375rem 1.4rem;
}

.file-input__label svg{
  height: 16px;
  margin-right: 4px;
}

.user-profile-image {
  position: relative;
  max-width: 100px;
  margin: 0 auto;
}

.user-profile-image i {
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: #323232;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.user-profile-image-wrap .user-name {
  font-size: 20px;
  line-height: 1.4;
  padding-top: 12px;
  display: block;
}

.user-profile-image-wrap .user-email {
  font-size: 13px;
  line-height: 1.4;
  margin-top: 4px;
  opacity: 0.7;
}
a.text-primary:hover {
  visibility: hidden;
  color: #945900 !important;
}
a.text-success:hover {
  color: #005e00 !important;
}
.ReactCrop--fixed-aspect .ord-nw{
  visibility: hidden;
}

.ReactCrop--fixed-aspect .ord-ne{
  visibility: hidden;
}

.ReactCrop--fixed-aspect .ord-se{
  visibility: hidden;
}

.ReactCrop--fixed-aspect .ord-sw{
  visibility: hidden;
}


.cropper-crop-box, .cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  -webkit-box-shadow: 0 0 0 1px #39f;
          box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
background-color:inherit !important;
}

.cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw, .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line {
display:none !important;
}

.cropper-view-box {
outline:inherit !important;
}


.btn-remove{
  font-size: 18px;
  padding: 0.375rem 1.4rem;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff!important;
  font-size: 18px;
  -webkit-box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
          box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
  background-color: #ff9a00;
  border: 1px solid #ff9a00;
 padding: 0.375rem 1.35rem;
}

.btn-remove i{
  font-size: 13px;
  margin-right: 3px;
  color: #fff!important;
}

.product-info-wrap .product-info-col:first-child {
  width: 60%;
}

.product-info-wrap .product-info-col:last-child {
  width: 40%;
  padding-top: 25px;
}
.category-name-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  max-width: 100%;
}
.category-name-text label{
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  vertical-align: bottom;
  margin-top: 2px;
}
.brands-cons-list-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  font-size: 13px;
  line-height: 1.4;
  cursor: pointer;
}
.brands-cons-list-name label {
  cursor: pointer;
}
.brands-cons-list-name input {
  margin-top: 2px;
}
.brandSearch_listing_wrap .brands-cons-list:nth-child(n + 4) {
  margin-top: 6px;
}
.brands-cons-listing-box {display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;margin: 0 -10px;}

/*-- Dashboard --*/
.dashboard-steps-box-wrap,.dashboard-overview-wrap {
  background-color: #ffffff;
  padding:40px;
}

.dashboard-steps-box {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.dashboard-steps-left {
  width: 100%;
  text-align: center;
  max-width: 6%;
  background-color: #323232;
  color: #ffffff;
  padding: 6px 6px;
  font-size: 14px;
  margin-top:4px;
  border-radius: 4px;
}

.dashboard-steps-center {width: 100%;max-width: 66%;padding-left: 4%;}

.dashboard-steps-right {
  width: 100%;
  max-width: 28%;
  text-align: right;
}

.dashboard-steps-left span {
  display: block;
  width: 100%;
}

.dashboard-steps-center h3 {
  font-size: 20px;
  line-height: 1.4;
}

.dashboard-steps-center p {
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
}

.dashboard-steps-center p + p {
  margin-top:5px;
}

.dashboard-steps-box + .dashboard-steps-box {
  margin-top: 20px;
  border-top: 1px solid rgb(50 50 50 / 20%);
  padding-top: 20px;
}

.orange-bttn {
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  background-color: #ff9a00;
  color: #ffffff;
  padding: 8px 8px;
  border-radius: 4px;
}
.orange-bttn:hover{
  background-color: #ffbe5a;
  color: #ffffff;
}
.dashboard-overview-wrap{
  margin-top: 30px;
}
.video-overview-tab-wrap > .nav {
  max-width: 15%;
  width: 100%;
}
.video-overview-tab-wrap > .tab-content {
  max-width: 85%;
  width: 100%;
  margin-left: 20px;
}
.video-overview-tab-wrap .nav-link {
  padding: 0;
  background-color: transparent;
  text-align: left;
}
.video-overview-tab-wrap > .nav .nav-link + .nav-link {
  margin-top: 15px;
}
.video-overview-tab-wrap .nav-link img {
  border: 1px solid #000000;
}
.video-overview-tab-wrap .nav-link.active {
  background-color: transparent;
  color: #ff9a00;
}
.video-overview-tab-wrap .nav-link span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #3c3c3c;
  margin-top: 5px;
  word-break: break-all;
}
.video-overview-tab-wrap .nav-link.active span {
  color: #ff9a00;
}
input.form-control {cursor: auto;}
.video-overview-tab-wrap .nav-link.active img {
  border-color: #ff9a00;
}
.video-overview-tab-wrap {
  margin-top: 20px;
}
.dashboard-overview-wrap h3 {
  margin: 0;
  font-size: 22px;
  line-height: 1.4;
}
.dashboard-overview-wrap .tab-pane h3 {
  margin-top:25px;
}
.dashboard-overview-wrap .tab-pane h3 + p{
  margin-bottom: 0;
  margin-top: 10px;
}
.cloud-configure-modal .modal-footer {
  background-color: rgb(126 126 126 / 40%);
}

.cloud-configure-modal .modal-header {
  justify-content: center;
  position: relative;
}

.cloud-configure-modal .modal-header .btn-close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.cloud-configure-modal .modal-body p {
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
}
.cloud-configure-modal .modal-lg {
  max-width: 700px;
}
.video-overview-tab-wrap .nav-link i {
  display: none;
}
.cloud-configure-modal .modal-body .ratio {
  max-width: 92%;
  margin: 0 auto;
}
.first.product-details-list > div + div {
  margin-top: 5px;
}

@media (max-width: 1500px) {
  .dashboard-steps-center { padding-left: 3%; max-width: 65%; }
  .dashboard-steps-right { max-width: 29%; }
}

@media (max-width: 1399.98px) {
  .product-info-wrap tr td:first-child { min-width: auto; }
  .product-info-wrap .product-info-col:first-child{width:100%;padding: 0;}
}


@media screen and (max-width:1199px) {
  .dashboard-steps-center { padding-left: 3%; max-width: 59%; }
  .dashboard-steps-right { max-width: 31%; }
  .dashboard-steps-left { max-width: 10%; }
  .orange-bttn { padding: 8px 8px; font-size: 11px; }
  .video-overview-tab-wrap > .nav { max-width: 20%; }
  .video-overview-tab-wrap > .tab-content { max-width: 80%; margin-left: 10px; }
}

@media screen and (max-width:991px) {
  .category-title { width: 140px; }
  .dashboard-steps-left{max-width:15%;}
  .dashboard-steps-right {
    max-width: 82%;
    text-align: left;
    margin-top:20px;
    margin-left: auto;
  }
  .dashboard-steps-center { max-width: 85%; }

  .video-overview-tab-wrap > .tab-content {
    max-width: 100%;
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }

  .video-overview-tab-wrap {
      flex-wrap: wrap;
  }

  .video-overview-tab-wrap > .nav {
      max-width: 100%;
      justify-content: space-between;
  }

  .video-overview-tab-wrap > .nav > button {
      width: 23%;
  }

  .video-overview-tab-wrap > .nav .nav-link + .nav-link {
      margin-top: 0;
  }
}


@media screen and (max-width:767px) {
  .category-title { width: 100%; }
  .dashboard-steps-left {
    max-width: 70px;
  }
  .video-overview-tab-wrap > .nav{order:1;}
  .video-overview-tab-wrap > .tab-content{margin-top: 0;margin-bottom: 15px;}

  .dashboard-steps-center {
      max-width: 100%;
      padding-left: 0;
      margin:20px 0 0;
  }
  .dashboard-steps-right {
      max-width: 100%;
      text-align: left;
  }
  .video-overview-tab-wrap > .nav {
      flex-direction: column;
      max-width: 100%;
  }

  .video-overview-tab-wrap > .nav > button {
      width: 100%;
  }
  .video-overview-tab-wrap .nav-link img {
    display: none;
  }
  .video-overview-tab-wrap > .nav > button {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
  }

  .video-overview-tab-wrap .nav-link span {
      width: 100%;
      max-width: calc(100% - 40px);
      margin-left: auto;
      margin-top: 0;
      font-size: 14px;
  }

  .video-overview-tab-wrap > .nav .nav-link + .nav-link {
      margin-top: 14px;
      border-top: 1px solid rgb(50 50 50 / 20%);
      padding-top: 14px;
  }

  .video-overview-tab-wrap .nav-link i {
      display:inline-block;
      font-size: 18px;
      line-height: 1;
      color: #3c3c3c;
  }
  .video-overview-tab-wrap .nav-link.active i { color: #ff9a00; }
  .cloud-configure-modal h1 { font-size:24px;text-align: center;}
}


@media screen and (max-width:575px) {
  .section-title{padding:40px 0;}
  .product-info-wrap tr td:first-child { min-width: auto; }
  .product-info-wrap tr td:last-child { width: 100%; }
  .product-info-wrap { width: 100%; margin: 0; }
  .product-info-wrap .product-info-col:first-child { width: 100%; padding: 0; }
  .dashboard-overview-wrap h3 { font-size: 20px; }
  .video-overview-tab-wrap { margin-top: 10px; }
  .cloud-configure-modal h1 { font-size: 20px; }
}