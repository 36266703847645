body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: #3C3C3C;
  background: #f1f5f9;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  overflow-x: hidden;
  line-height: 1.5;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  position: relative;
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: #3C3C3C;
  background: #f1f5f9;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  overflow-x: hidden;
  line-height: 1.5;
  min-height: 100%;
}

.panel-logo{
  padding-right: 10px;
}

.inline-h3{
  line-height: 1.9;
}