/*-------------------------
    Fonts
---------------------------*/
@font-face {
    font-family: 'Nunito Bold';
    src: url('../fonts/Nunito-Bold.woff2') format('woff2'),
        url('../fonts/Nunito-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Black';
    src: url('../fonts/Nunito-Black.woff2') format('woff2'),
        url('../fonts/Nunito-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito ExtraBold';
    src: url('../fonts/Nunito-ExtraBold.woff2') format('woff2'),
        url('../fonts/Nunito-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Regular';
    src: url('../fonts/Nunito-Regular.woff2') format('woff2'),
        url('../fonts/Nunito-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito ExtraLight';
    src: url('../fonts/Nunito-ExtraLight.woff2') format('woff2'),
        url('../fonts/Nunito-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Light';
    src: url('../fonts/Nunito-Light.woff2') format('woff2'),
        url('../fonts/Nunito-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito SemiBold';
    src: url('../fonts/Nunito-SemiBold.woff2') format('woff2'),
        url('../fonts/Nunito-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Medium';
    src: url('../fonts/Nunito-Medium.woff2') format('woff2'),
        url('../fonts/Nunito-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



/*-------------------------
    transition
---------------------------*/

a,
.btn {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

button, input, optgroup, select, textarea{cursor: pointer;}


/*-------------------------
    Reset
---------------------------*/

* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
    font-size: 100%;
}

body {
    position: relative;
    padding: 0;
    margin: 0;
    /* font-family: 'Inter', sans-serif; */
    font-family: 'Nunito SemiBold';
    color: #3C3C3C;
    background: #f1f5f9;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    overflow-x: hidden;
    line-height: 1.5;
    min-height: 100%;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    height: 100%;
}

a {
    color: #323232;
    text-decoration: none;
}

a:hover {
    color: #323232;
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
}

.c-p {
    cursor: pointer;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.text-warning {
    color: #f59e0b !important;
}


/***==============
         General
 ====================  ***/

p {
    font-size: 1rem;
    /* font-family: 'Inter', sans-serif; */
    font-family: 'Nunito SemiBold';
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

h1,
.h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

h2,
.h2 {
    font-size: 2rem;
}

h3,
.h3 {
    font-size: 1.8rem;
}

h4,
.h4 {
    font-size: 1.6rem;
}

h5,
.h5 {
    font-size: 1.4rem;
}

h6,
.h6 {
    font-size: 1.2rem;
}

.theme-clr {
    color: #ff9a00;
}

.font-inter {
    /* font-family: 'Inter', sans-serif; */
    font-family: 'Nunito SemiBold';
}

.trans3s,
.btn,
a {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

a:hover,
a:focus,
a:active {
    color: #ff9a00;
}

.trans5s {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.theme-btn {
    background: #ff9a00;
    padding: 15px 40px;
    border: 1px solid #ff9a00;
    color: #fff;
    border-radius: 0;
}

.theme-btn-1 {
    background: transparent;
    padding: 20px 40px;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 0;
}

.theme-btn-1.theme-btn-1-clr {
    border: 1px solid #ff9a00;
    color: #ff9a00;
}

.theme-btn-1.btn-light {
    border: 1px solid #323232;
    color: #323232;
}

.theme-btn.theme-btn-light:hover {
    background: #fff;
    border: 1px solid #fff;
}

.theme-btn:hover {
    background: #fff;
    border: 1px solid #ff9a00;
    color: #3C3C3C;
}

.theme-btn-1:hover {
    background: #ff9a00;
    border: 1px solid #ff9a00;
    color: #fff;
}

.fs-14 {
    font-size: 16px;
}

.fs-12 {
    font-size: 14px;
}

.fs-10 {
    font-size: 12px;
}

.container {
    max-width: 1600px;
}

.bg-warning {
    background-color: #ff9a00 !important;
}
.border-warning {
    border-color: #ff9a00 !important;
}

.btn {
    padding: .375rem 1.4rem;
}

.cursor-pointer {
    cursor: pointer;
}


/***==============
         Login
 ====================  ***/

.admin-wrap {
    height: 100%;
}

.login-page {
    background-image: url('../images/login-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px;
}

.login-wrap {
    max-width: 500px;
    background: #fff;
    padding: 30px;
}

.registration-wrap {
    max-width: 850px;
    background: #fff;
    padding: 20px 30px;
}
.contact-wrap {
    max-width: 850px;
    background: #fff;
    padding: 30px;
    margin-top: 30px;
}


/***==============
Sidebar Menu
=================****/

.main-sidebar {
    max-width: 250px;
    background: #323232;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 10px;
    color: #fff;
    font-size: 14px;
    z-index:99999;
}

.main-sidebar.collapsed {
    width: 68px;
    overflow: visible;
}

.sidebar-user-penel p {
    font-size: .9rem;
}

.sidebar-navigation {
    padding: 20px 0;
}

.sidebar-title a span, .sidebar-title a {
    color: #ff9a00;
    padding: 15px;
}

.sidebar-nav,
.sidebar-sub-nav {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-nav li {
    position: relative;
}

.sidebar-nav li a {
    color: #fff;
    padding: 10px 15px;
    display: block;
    margin-bottom: 2px;
    border-radius: 5px;
    position: relative;
}

.sidebar-nav li a:hover {
    background: #000;
}

.sidebar-nav .sidebar-sub-nav {
    display: none;
    margin-left: 10px;
}

.sidebar-nav li.has-childs.do-minus .sidebar-sub-nav {
    display: block;
}

.sidebar-nav li.has-childs>a:before {
    position: absolute;
    width: 7px;
    content: '';
    height: 7px;
    right: 20px;
    top: 15px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -webkit-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    transition: all 600ms ease;
}

.sidebar-nav li.has-childs.do-minus>a:before {
    -ms-transform: rotate(135deg);
        transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    top: 18px;
}

.main-sidebar.collapsed .sidebar-user-penel p,
.main-sidebar.collapsed .sidebar-title,
.main-sidebar.collapsed .sidebar-nav .menu-title {
    display: none;
}

.main-sidebar.collapsed .sidebar-nav .me-2 {
    margin-right: 0 !important;
}

.main-sidebar.collapsed .sidebar-nav .sidebar-sub-nav {
    position: absolute;
    z-index: 999999;
    width: 200px;
    left: 100%;
    top: 0;
    margin: 0;
    padding-left: 10px;
}

.main-sidebar.collapsed .sidebar-nav .sidebar-sub-nav li a {
    background: #000;
    margin-bottom: 1px;
    border-radius: 0;
}

.main-sidebar.collapsed .sidebar-nav li.has-childs:hover .sidebar-sub-nav {
    display: block;
    -webkit-animation: fadeInUp .3s ease-in;
    animation: fadeInUp .3s ease-in;
}


/***==============
Header bar
=================****/

.header-bar {
    background: #fff;
    padding: 15px;
    z-index: 999;
}

.content-wrap {
    margin-left: 250px;
}

.content-wrap.collapsed {
    margin-left: 68px;
}

.section-title {
    padding: 20px 0;
}

.section-title h1 {
    font-size: 2rem;
}


/***==============
callout boxes
=================****/

.callout-header {
    padding: 50px 30px;
}

.call-out-boxes .call-out-box {
    background: #fff;
    padding: 30px 20px;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    -webkit-box-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
}

.call-out-boxes .call-out-box .call-number {
    font-size: 4.5rem;
}

.shadow-box {
    box-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
    -webkit-box-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
}

.panel-box {
    background: #fff;
    padding: 20px 15px;
    border-radius: 10px;
}


/***==============
Forms
=================****/

.cl-form .form-control {
    outline: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    height: 45px;
}

.cl-form textarea.form-control,
textarea.form-control {
    height: 150px;
}

.cl-form .form-select {
    outline: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    height: 45px;
}

.cl-form .form-label {
    font-weight: 500;
}

.cl-form .form-label .required-field {
    color: #d00;
}

.cl-login .form-control {
    height: 55px;
}

.cl-form-2 .form-field {
    position: relative;
    padding-top: 20px;
}

.cl-form-2 .form-label {
    position: absolute;
    top: 25px;
}

.cl-form-2 .form-control:not(:-moz-placeholder-shown)+.form-label {
    transform: translateY(-75%);
    color: var(--color-accent);
    font-size: 14px;
}

.cl-form-2 .form-control:not(:-ms-input-placeholder)+.form-label {
    -ms-transform: translateY(-75%);
        transform: translateY(-75%);
    color: var(--color-accent);
    font-size: 14px;
}

.cl-form-2 .form-control:focus+.form-label,
.cl-form-2 .form-control:not(:placeholder-shown)+.form-label {
    -webkit-transform: translateY(-75%);
        -ms-transform: translateY(-75%);
            transform: translateY(-75%);
    color: var(--color-accent);
    font-size: 14px;
}

.form-field-icon .field-icon {
    position: relative;
}

.form-field-icon .icon-form-field {
    position: absolute;
    top: 0;
    right: 5px;
    top: 7px;
    width: 20x;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    /* background: #eee; */
}
/* 
.form-field-icon .field-icon .icon-form-field:hover {
    background: #CBCBCB;
} */

.checkbox {
    --background: #fff;
    --border: #d1d6ee;
    --border-hover: #bbc1e1;
    --border-active: #1e2235;
    --tick: #fff;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.checkbox input,
.checkbox svg {
    width: 21px;
    height: 21px;
}

.checkbox input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: var(--background);
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 0;
    -webkit-transition: box-shadow 0.3s;
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
    -webkit-box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
            box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
    margin-right: 15px;
}

.checkbox input:hover {
    --s: 2px;
    --b: var(--border-hover);
}

.checkbox input:checked {
    --b: var(--border-active);
}

.checkbox svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border-active));
    position: absolute;
    left: 0;
    top: 0;
    width: 21px;
    height: 21px;
    -webkit-transform: scale(var(--scale, 1)) translateZ(0);
    transform: scale(var(--scale, 1)) translateZ(0);
}

.checkbox.path input:checked {
    --s: 2px;
    -webkit-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
       transition-delay: 0.4s;
}

.checkbox.path input:checked+svg {
    --a: 16.1 86.12;
    --o: 102.22;
}

.checkbox.path svg {
    stroke-dasharray: var(--a, 86.12);
    stroke-dashoffset: var(--o, 86.12);
    -webkit-transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    -o-transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
}

.checkbox.bounce {
    --stroke: var(--tick);
}

.checkbox.bounce input:checked {
    --s: 11px;
}

.checkbox.bounce input:checked+svg {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
        transform: scale(1);
}

.checkbox.bounce svg {
    --scale: 0;
}

.radio-box input {
    border-radius: 50%;
}

@-webkit-keyframes bounce {
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes bounce {
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.modern-select select {
    display: none;
}

.select-selected {
    background-color: #fff;
}


/*style the arrow inside the select element:*/

.select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}


/*point the arrow upwards when the select box is open (active):*/

.select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}


/*style the items (options), including the selected item:*/

.select-items div {
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.select-selected {
    padding: 11px 16px;
    border: 1px solid #CBCBCB;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    height: 45px;
}


/*style items (options):*/

.select-items {
    position: absolute;
    background-color: #fff;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}


/*hide the items when the select box is closed:*/

.select-hide {
    display: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

.form-field .bootstrap-select {
    width: 100% !important;
    background: transparent;
    height: 45px;
    border: 1px solid #CBCBCB;
}

.bootstrap-select>.dropdown-toggle {
    background: transparent;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
    outline: 0 !important;
}

.bootstrap-select .dropdown-item.active,
.bootstrap-select .dropdown-item.selected,
.dropdown-item:active {
    background: #ff9a00;
    color: #fff
}

.bootstrap-select .dropdown-item {
    color: #3c3c3c;
    margin-bottom: 1px;
    padding: 6px;
}

.bootstrap-select .dropdown-item .fal {
    margin-right: 10px;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
    top: 9px;
}


/* Animation */

@keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 40px, 0);
                transform: translate3d(0, 40px, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 40px, 0);
                transform: translate3d(0, 40px, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}


/*==========
Table
*======*/

.cl-table thead tr {
    background: #f8fafc;
}

.cl-table th {
    font-weight: 500;
    padding: 10px 15px;
    vertical-align: middle;
}

.cl-table td {
    padding: 12px 15px;
    vertical-align: middle;
}

.label-btn {
    padding: 3px 5px;
    font-size: 12px;
    border-radius: 5px;
    color: #fff;
}

.label-success {
    background-color: #198754;
}

.label-info {
    background-color: #3699FF;
}

.label-danger {
    background-color: #dc3545;
}

.label-warning {
    background-color: #f59e0b;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: #CBCBCB;
}

.page-link {
    color: #3c3c3c;
}

.page-link:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
}

.cl-pagination .page-item .page-link {
    border-radius: 0;
}

.page-link:hover {
    background: #ff9a00;
    color: #fff;
}


/*** Inventory ****/


.vertical-status-bar {
    width: 3px;
    height: 20px;
    background: #000;
    border-radius: 10px;
    background-color: rgba(25, 135, 84, 0.4);
}

.vertical-status {
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    border-radius: 10px;
    background-color: #198653;
}

.vertical-status-bar.bg-info .vertical-status {
    background-color: rgba(13, 202, 240, 0.4);
}

.vertical-status-bar.bg-danger .vertical-status {
    background-color: rgba(220, 53, 70, 0.4);
}

.vertical-status-bar.bg-danger .vertical-status {
    background-color: rgba(255, 153, 0, 0.4);
}


/* Purvank CSS 12-11-2021 */    
.p_orders_table .dataTables_length {
    width: 50%;
    float: left;
    margin: 2px 0 25px 0;
}
.p_orders_table .dataTables_length select {
    background: transparent;
    border: 1px solid #ced4da;
    padding: 10px;
    border-radius: 4px;
}
.p_orders_table .dataTables_filter {
    width: 50%;
    float: left;
    margin-bottom: 30px;
}
.p_orders_table .dataTables_filter label {
    border: 1px solid #ced4da;
    padding: 10px;
    float: right;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.p_orders_table .dataTables_filter label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 65px;
    height: 100%;
    background: #f2f2f2;
    z-index: -1;
}
.p_orders_table .dataTables_filter label input {
    padding-left: 10px;
}
.p_orders_table .dataTables_info,
.p_orders_table .dataTables_paginate.paging_simple_numbers {
    width: 50%;
    float: left;
    margin: 20px 0 0 0;
}
.p_orders_table .dataTables_paginate.paging_simple_numbers {
    text-align: right;
}
.p_orders_table .dataTables_paginate.paging_simple_numbers a {
    cursor: pointer;
}
.p_orders_table .dataTables_paginate.paging_simple_numbers span a,
.p_orders_table .dataTables_paginate.paging_simple_numbers a {
    border: 1px solid #ced4da;
    padding: 8px 10px;
}
.p_orders_table .dataTables_paginate.paging_simple_numbers span a, .p_orders_table .dataTables_paginate.paging_simple_numbers a:first-child {
    border-right: 0;
}
.p_orders_table .dataTables_paginate.paging_simple_numbers a:hover {
    background: #ff9a00;
    color: #fff;
    border-color: #ff9a00;
}

.p_orders_table .paginate_button.previous::before {
    content: "\f053";
    font-family: 'Font Awesome 5 Pro';
    font-weight: 500;
    margin-right: 10px;
}
.p_orders_table .cl-table thead tr {
    border-top: 0;
}
.p_orders_table .cl-table td {
    border-width: 0;
}
.p_orders_table .cl-table.table {
    width: 100% !important;
}
.p_orders_table .table-bordered > :not(caption) > * > * {
    border-width: 0;
}
.p_orders_table {
    overflow: hidden;
    padding: 15px 0;
}
.p_order_checkbox {margin-right: 10px; width: 15px; height: 15px;}
.action_dropdown select {
    padding: 8px 22px 8px 10px;
    background: transparent;
    border: 1px solid #ced4da;
    margin: 0 0 20px 0;
    -webkit-appearance: none;
    width: auto;
    margin-right: 15px;
}
.common_btn {
    font-size: 14px;
    padding: 8px 20px;
    background: #ff9a00;
    border: 1px solid #ff9a00;
    color: #fff;
    border-radius: 0;
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
}
.common_btn_dark {
    color: #fff;
    background: rgb(181, 181, 181);
    border-color: #b5b5b5;
}
.common_btn:hover {
    background: #323232;
    color: #fff;
    border: 1px solid #323232;
}

.user_form input {
    padding: 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    width: 100%;
    display: block;
    border-radius: 0;
    margin: 18px 0;
}
/* Purvank CSS 12-11-2021 */

/* Purvank CSS 15-11-2021 */
.active_inactive_toggle {
    padding-left: 1.5em;
}
.active_inactive_toggle  .active_inactive_btn {
    width: 3em;
    position: relative;
    margin: 0 4rem;
}
.active_inactive_toggle  .active_inactive_btn:before {
  content: "inactive";
  left: -6rem;
}
.active_inactive_toggle  .active_inactive_btn:after {
  content: "active";
  right: -6rem;
  opacity: 0.5;
}
.active_inactive_btn:checked::before {
    opacity: 0.5;
}
.active_inactive_btn:checked::after {
    opacity: 1;
}
.active_inactive_btn::before, .active_inactive_btn::after {
    color: #6b7381;
}
.active_inactive_btn::before, .active_inactive_btn::after {
    line-height: 1.5rem;
    width: 6rem;
    text-align: center;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    -webkit-transition: opacity 0.25s;
    -o-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.login_page_error p {color: red;}
.user_logout a span, .user_logout a {color: #ff9a00;}

/* Purvank CSS 15-11-2021 */

.invalid-feedback, .error {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .9em;
    color: #dc3545;
}

/* Purvank CSS 17-11-2021 */
.collapsed .user_logout a span, .collapsed 
 .user_logout a {
    display: none;
}
.dropdown_block::before {
    content: "\f107";
    font-family: 'Font Awesome 5 Pro';
    right: 28px;
    position: absolute;
    top: 0px;
}
.dropdown_block {
    position: relative;
}
.float-right {float: right;}
.nav-link.common_btn {
    color: #fff;
    padding: 15px 25px;
    font-size: 18px;
    background: #b5b5b5;
    border-top: 4px solid #b5b5b5;
    margin-right: 10px;
}
.nav-link.common_btn:hover {
    border: 0;
    border-top: 4px solid #ff9a00;
    background: #fff;
    color: #ff9a00;
}
.nav-link.common_btn.active {
    background: #fff;
    color: #ff9a00;
    border-top: 4px solid #ff9a00;
}
.close_icon i {
    color: #ff5c5c;
}
/* Purvank CSS 17-11-2021 */

/* Purvank CSS 18-11-2021 */
.user_tabss .tab-content {
    border-bottom: 4px solid #ff9a00;
}
.user_tabss .bg-success {font-size: 12px;}
.add_modal {z-index: 9999999999;}
.btn-check:active + .btn-primary, .btn-check:checked + .btn-primary, .btn-primary.active, .btn-primary:active, .show > .btn-primary.dropdown-toggle,
.btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #ff9a00;
    border-color: #ff9a00;
    -webkit-box-shadow: none;
            box-shadow: none;
}
/* Purvank CSS 18-11-2021 */

/* Purvank CSS 19-11-2021 */
.fw-300 {font-weight: 300;}
.fw-400 {font-weight: 400;}
.fw-500 {font-weight: 500;}
.fw-600 {font-weight: 600;}
.fw-700 {font-weight: 700;}
.fs-10 {font-size: 12px;}
.fs-11 {font-size: 13px;}
.fs-12 {font-size: 14px;}
.fs-14 {font-size: 16px;}
.fs-16 {font-size: 18px;}
.fs-18 {font-size: 20px;}
.fs-20 {font-size: 22px;}
.fs-22 {font-size: 24px;}
.fs-24 {font-size: 26px;}
.fs-26 {font-size: 28px;}
.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.color-orange {color: #ff9a00;}
.color-dark {color: #323232;}
.color-dark-grey {color: #666666;}
.bg-success {background-color: #21ae6d !important; } 
.border-success {border-color: #21ae6d !important; } 
.bg-danger {background-color: #ff5c5c !important; } 
.border-danger {border-color: #ff5c5c !important; } 
.bg-primary {background-color: #4691ff !important; } 
.border-primary {border-color: #4691ff !important; }
.badge {font-weight: 500; }

.cl-form .select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #ced4da;
    outline: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    height: 45px;
}
.cl-form .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 42px;
}
.cl-form .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 42px;
    right: 6px;
}
.cl-form .form-control.browse_file {
    padding: 12px 0 0 24px
}
.form-switch .form-check-input {
    width: 2.5em;
    border-radius: 2.5rem;
}
.form-check-input {
    height: 1.4em;
    margin-top: 0.10em;
}
.cl-form .select2-container {
    width: 100% !important;
}
/* Purvank CSS 19-11-2021 */

.sidebar-wrap { background-color: #f1f5f9; padding: 20px 15px;border:1px solid rgba(0,0,0,.125);-webkit-box-shadow: 0 0 2px 0 #e3e3e3;box-shadow: 0 0 2px 0 #e3e3e3;}
.sidebar-wrap h3 {
    font-size: 20px;
    line-height: 1;
    color: #545454;
    font-weight: normal;
    margin-bottom: 10px;
}
.category-list-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap:wrap;
        flex-wrap:wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #5454;
    cursor: pointer;
    font-size: 13px;
}
.category-list-more-text {text-align: left;margin-left: 25px;}
.category-list-more-text a { font-size: 13px; line-height: 1; color: #8A8A8A; font-style: italic; }
.catogory-title-wrap { margin-top: 20px; padding-top: 20px; border-top: 1px solid #E3E3E3;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;margin-bottom: 10px;}
.category-list-box:nth-last-child(2) { border-bottom: 0; }
.search-wrapper { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.catogory-title-wrap h3 { margin-bottom: 0; }
.advanced-search-wrap,.search-filter-btn {
    background-color: #f1f5f9;
    padding: 10px 10px;
    cursor: pointer;
    border: 1px solid rgba(0,0,0,.125);
    line-height: 1;
}
.advanced-search-btn {
    font-size: 14px;
    line-height: 1;
    color: #545454;
    padding: 10px;
    display: inline-block;
}
.search-input-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    position: relative;
    margin: 0 20px;
    width: calc(100% - 250px);
}
.search-input-wrapper .search-input { color: #484848; padding: 7px 15px 7px 30px; border-radius: 3px; font-size: 16px; line-height: 1; }
.search-input-wrapper i { position: absolute; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); left: 10px; }
.advanced-search-btn i { margin-right: 5px; }
.advanced-search-wrap { padding: 0; }
.search-filter-btn i { margin-left: 5px; }
.result-wrap {margin: 20px 0;}
.result-wrap h4 { font-size: 14px; line-height: 1.2; color: #545454; font-weight: normal; margin: 0; }
.result-wrap h4 b { font-weight: bold; }
.product-listing-box { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; background-color: #ffffff; border: 1px solid rgba(0,0,0,.125); -webkit-box-shadow: 0 0 2px 0 #e3e3e3; box-shadow: 0 0 2px 0 #e3e3e3; padding:20px; border-radius: 3px;position: relative;}
.product-listing-box .product-listing-link{position:absolute;width:100%;height:100%;z-index:9;}
.product-img img { max-width: 100px; max-height: 100px;}
.product-price-wrap { width: 200px; text-align: center; }
.product-content-wrap { width: calc(100% - 300px); }
.product-content-wrap h3 { font-size: 19px; line-height:1.5; font-weight: normal; }
.product-info-wrap { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin: 0 -10px; }
.product-info-col { width: 50%; padding: 0 10px; }
.product-info-wrap tr td:first-child { font-weight: 700; padding-right: 10px;min-width: 170px;}
.product-info-wrap tr td { font-size: 13px; color: #7A7A7A; line-height: 1.8; }
.product-info-content { margin: 0; font-size: 13px; line-height: 1.4; color: #545454; }
.product-pr-box { font-size: 14px; line-height: 1.4; color: #545454; }
.product-pr-box span { font-size:12px; display: block; }
.product-pr-box + .product-pr-box{ margin-top: 10px; }
.product-info-wrap tr td:last-child { color: #545454; }
.product-listing-box + .product-listing-box{margin-top:20px;}
.category-sorting-wrap { border: 1px solid #e7eaec; background-color: #ffffff; padding: 2px 5px; cursor: pointer; }
.product-col-left { max-width: 300px; }
.product-col-right { max-width: calc(100% - 300px); }
.supplier-listing-box { background-color: #ffffff; padding: 20px 25px; text-align: center;border-radius:5px;-webkit-box-shadow: 0 0 8px rgb(0 0 0 / 10%);box-shadow: 0 0 8px rgb(0 0 0 / 10%);border:2px solid #ffffff;-webkit-transition:0.3s all ease-in-out;-o-transition:0.3s all ease-in-out;transition:0.3s all ease-in-out;}
.supplier-listing-box:hover{border-color:#ffbe5a;-webkit-box-shadow:none;box-shadow:none;}
.supplier-listing-box img {max-width: 250px;height: auto;width: 100%;}
.supplier-listing-box h3 { text-align: center; font-size:20px; line-height: 1.4; }
.supplier-listing-feature-wrap { margin: 15px 0 20px; text-align: left; }
.supplier-listing-feature-wrap ul { list-style: none; margin: 0; padding: 0; }
.supplier-listing-feature-wrap li img { max-width: 18px; margin-right: 8px; }
.supplier-listing-feature-wrap li { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; font-size: 14px; line-height: 1.4; }
.supplier-listing-feature-wrap ul li + li { margin-top: 7px; }
.supplier-listing-feature-wrap h4 { font-size:18px; line-height: 1.6; margin-bottom: 5px; }
.distri-bttn { font-size: 16px; line-height: 1; background-color: #ff9a00; color: #ffffff; display: inline-block; padding: 12px 25px; border-radius: 6px; }
.distri-bttn:hover { background-color: #ffbe5a; color: #ffffff; }
.supplier-listing-wrapper > div > div:nth-child(n + 5) { margin-top:20px; }
.category-sub-menu {width: 100%;padding: 0;list-style: none;border-left: 1px solid #BABABA;margin: 10px 0 10px 6px;}
.category-sub-menu li {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;position: relative;padding-left: 25px;margin-top:10px;margin-bottom:10px;}
.category-sub-menu li::before{ content:''; width:17px; height:1px; background-color:#BABABA; left:0; top:50%; -webkit-transform:translateY(-50%); -ms-transform:translateY(-50%); transform:translateY(-50%); position:absolute; }
.toggleDropdown .slideCategory-bttn i::before {content: "\f077";}
.category-name-text .slideCategory-bttn { margin-right: 8px; }
.advanced-search-content-wrap { min-width: 716px;padding:20px;}
.advanced-search-row { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.advanced-search-box-title {width: 200px; }
.advanced-search-box-title h3 { font-size: 14px; line-height: 1.4; font-weight: normal; }
.advanced-search-box-input-wrap {width: calc(100% - 200px);}
.advanced-search-row + .advanced-search-row { margin-top: 10px; }
.advanced-search-btn.show {background-color: #ffffff;width:100%;}
.moreInfoModal  h3 { margin: 0; text-align: center; display: block; width: 100%; font-size: 22px; line-height: 1.4; font-weight: normal; }
.btn-close { position: absolute; right: -10px; top: -8px; background-color: #ffffff; opacity: 1; border-radius: 50%; border: 1px solid black; -webkit-box-shadow: inset 0 0 13px rgb(0 0 0 / 30%); box-shadow: inset 0 0 13px rgb(0 0 0 / 30%);-webkit-transition:0.3s all ease-in-out;-o-transition:0.3s all ease-in-out;transition:0.3s all ease-in-out;}
.btn-close:hover { background-color: #ebebeb; opacity: 1; }
.moreInfoModal hr { opacity: 0.1; }
.moreInfoModal h5 { font-size: 14px; line-height: 1.4; margin: 0 0 5px; font-weight: 600; }
.moreInfoModal p { font-size: 14px; line-height: 1.4; }
.moreInfoModal a { color: #db8400; }
.moreInfoModal .modal-body,.moreInfoModal .modal-header { padding: 25px; }
.product-modal { display: -webkit-box; display: -ms-flexbox; display: flex;}
.productDetailModal .modal-body { padding: 0; }
.product-modal .fields-con {-webkit-box-flex: 0;-ms-flex: 0 0 33.33%;flex: 0 0 33.33%;background-color: #FAFAFA; border-right: 1px solid #E3E3E3; padding-top: 10px;padding-bottom: 10px;}
.product-modal .main-con {-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.product-modal .main-con .gallery-con .primary-img { padding: 20px; }
.product-modal .fields-con .field { padding: 6px 10px 6px 20px; border-bottom: 1px solid #F2F2F2; }
.price-con { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.price-con .price { -webkit-box-flex:1; -ms-flex-positive:1; flex-grow:1; text-align: center; border-bottom: 1px solid #E3E3E3; border-right: 1px solid #E3E3E3; padding:10px 0;color:#545454;}
.product-modal .main-con .gallery-con img { max-height: 360px; margin: 0 auto;max-width:100%;}
.product-modal .main-con .description-con { padding: 20px; }
.product-modal .fields-con .field .heading { font-size: 13px; line-height: 1.2; color: #545454; font-weight: bold; margin-bottom: 4px; }
.product-modal .fields-con .field .value { font-size: 13px; line-height: 1.2; color: #545454; }
.price-con .price div { font-size:12px; line-height: 1.2; color: #545454; }
.product-modal .fields-con .field .value i.fa-search { width: 23px; height: 23px; padding: 0; border: 1px solid #e7eaec; font-size: 12px; line-height: 1; display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
/* Purvank CSS 24-11-2021 */
ul.breadcrumb {
  /*padding: 10px 16px;*/
  padding: 0px 16px;
  list-style: none;
}
ul.breadcrumb li {
    display: inline;
    font-size: 14px;
    color: #ff9a00;
}
ul.breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "\003E\003E";
}
ul.breadcrumb li a {
    color: #333;
    text-decoration: none;
}
ul.breadcrumb li a:hover {
  color: #ff9a00;
}
.custom_active_unactive_btn input[type=checkbox].checkbox-toggle {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}

.custom_active_unactive_btn .switch_custom {
    position: relative;
    width: 78px;
    height: 36px;
    margin-top: 18px;
}
.custom_active_unactive_btn label.checkbox-toggle-label {
  color: #B3B2B3;
}
.custom_active_unactive_btn label.checkbox-toggle-label span.on {
    position: absolute;
    display: none;
    top: 6px;
    left: 10px;
}
.custom_active_unactive_btn label.checkbox-toggle-label span.off {
    position: absolute;
    display: inline-block;
    top: 6px;
    right: 10px;
}

.custom_active_unactive_btn label.checkbox-toggle-label::before {
    content: "";
    background: #fff;
    border: 1px solid #ced4da;
    width: 78px;
    height: 36px;
    position: absolute;
    top: 0;
    left: 0;
}
.custom_active_unactive_btn label.checkbox-toggle-label::after {
    content: "";
    position: absolute;
    width: 37px;
    height: 36px;
    background: #B3B2B3;
    -webkit-transition: 0.1s ease-in;
    -o-transition: 0.1s ease-in;
    transition: 0.1s ease-in;
    top: 0;
}

.custom_active_unactive_btn input.checkbox-toggle {
  visibility: hidden;
}
.custom_active_unactive_btn input.checkbox-toggle:checked + label.checkbox-toggle-label:after {
  opacity: 1;
  -webkit-transform: translateX(40px);
      -ms-transform: translateX(40px);
          transform: translateX(40px);
  background: #2A95D2;
}
.custom_active_unactive_btn input.checkbox-toggle:checked + label.checkbox-toggle-label:before {
  background: #fff;
  border: 1px solid #ced4da;
}
.custom_active_unactive_btn input.checkbox-toggle:checked + label.checkbox-toggle-label {
  color: #2A95D2;
}
.custom_active_unactive_btn input.checkbox-toggle:checked + label.checkbox-toggle-label span.on {
  display: inline-block;
}
.custom_active_unactive_btn input.checkbox-toggle:checked + label.checkbox-toggle-label span.off {
  display: none;
}
.p_orders_table .cl-table.table th, .p_orders_table .cl-table.table td {
    border-color: #ddd;
}
/* Purvank CSS 24-11-2021 */


/* Purvank CSS 26-11-2021 */
.top_div {
    position: relative;
}
.breadcrumb_div {
    position: absolute;
    right: 0;
    top: 15px;
}
.custom_active_unactive_btn .ai_switch {
    width: 150px;
}
.custom_active_unactive_btn .ai_switch label.checkbox-toggle-label::before {
    width: 100%;
}
.custom_active_unactive_btn .ai_switch label.checkbox-toggle-label span.on {
    left: 18px;
}
.custom_active_unactive_btn .ai_switch input.checkbox-toggle:checked + label.checkbox-toggle-label::after {
    -webkit-transform: translateX(75px);
        -ms-transform: translateX(75px);
            transform: translateX(75px);
}
.custom_active_unactive_btn .ai_switch label.checkbox-toggle-label::after {
    width: 75px;
}
.flex-auto {-webkit-box-flex: 1;-ms-flex: auto;flex: auto;}

.img_logo_input {
    padding-left: 10px;
    margin-top: 0;
    height: 86px;
    -o-object-fit: contain;
       object-fit: contain;
}
.custom_active_unactive_btn .switch_custom {
    margin-top: 0;
}
.modal-header .btn-close {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
    position: initial;
    -webkit-box-shadow: none;
            box-shadow: none;
    margin: 0;
}
.modal-header .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75;
}
/* Purvank CSS 26-11-2021 */


/* Purvank CSS 29-11-2021 */
.user_login_logout {
    margin-left: auto;
}
.user_login_logout .dropdown a img {
    margin-right: 15px;
    height: 60px;
}
.user_login_logout .dropdown-menu.show {
    inset: 0px 0 auto auto !important;
}
.user_login_logout .btn.dropdown-toggle.show::after {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}
.user_login_logout a:hover, 
.user_login_logout a:focus, 
.user_login_logout a:active {
    color: #212529;
}
.user_login_logout .dropdown-item:active {
    background: #e9ecef;
}
/* Purvank CSS 29-11-2021 */

/* Purvank CSS 18-12-2021 */
.search_box {-webkit-box-flex: 1;-ms-flex: 1;flex: 1; padding-right: 25px;}
.search_box i {position: absolute; top: 10px; left: 10px; z-index: 9;}
.search_box input {padding-left: 30px;}
.search_box .search_wrapper {position: relative;}
.img_col img {
    height: 22px;
    -o-object-fit: contain;
       object-fit: contain;
}
.stat_col p {cursor: default;}
.logod_col {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 300px;
            flex: 0 0 300px;
}
.logod_col .img_col:first-child {
    max-width: 120px;
    width: 100%;
    text-align: center;
}
.title_col {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.stat_col {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100px;
            flex: 0 0 100px;
}
.manage_product_col {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 118px;
            flex: 0 0 118px;
}
.setup_col {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100px;
            flex: 0 0 100px;
}
.title_col h2 {
    font-size: 16px;
    color: #545454;
    font-weight: normal;
}
.feed-rw i.fa-exchange-alt {
    font-size: 12px;
    font-weight: bold;
}
.feed-rw p {font-size: 13px;}
.feed-rw span {font-size: 13px; color: #000;display: block;}
.setup_col .common_btn {
    padding: 4px 8px;
    font-size: 13px;
    background-color:#ff9a00;
    color: #666;
    border-color:#ff9a00;
    border-radius: 3px;
}
.feed-rw {
    background: #fff;
    padding: 18px 20px;
    border-radius: 3px;
    border: 1px solid #ddd;
    margin-bottom: 6px;
    cursor: pointer;
}
/* Purvank CSS 18-12-2021 */


.top-section {
    padding: 140px 0;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;

}
.border-custom {
    border-top: 2px solid #b5b5b5;
}

.custom-input-group {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

.custom-input-group input.form-control {
    font-size: 16px;
    line-height: 1;
    padding: 10px 10px;
    border-color: #e5e6e7;
    color: #545454;
    border-radius: 5px;
    cursor: auto;
}
.custom-input-group input.form-control:focus{border-color:#FFCB62;}
.custom-input-group .btnGold-primary {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background-color:#ff9a00;
    border: 1px solid#ff9a00;
    padding: 8px 21px;
    color:#ffffff;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.custom-input-group .btnGold-primary:hover{
    background-color:#ffbe5a;
}
.requestNewintegrateBox-wrap {
    background: #fff;
    padding: 20px 25px;
    border-radius: 10px;
}
/*.requestNewintegrateBox-wrap h4 {
    font-weight: normal;
    margin: 0 0 8px;
}*/

.requestNewintegrateBox-wrap p {
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
}

.requestNewintegrateBox-wrap form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    max-width:400px;
    margin:20px 0 0;
}

.requestNewintegrateBox-wrap form input {
    width: 100%;
    border: 1px solid #e5e6e7;
    padding: 8px 10px;
}
.requestNewintegrateBox-wrap form input:focus{
    border-color:#FFCB62;
}

.requestNewintegrateBox-wrap form input + input {
    margin-top: 15px;
}

.requestNewintegrateBox-wrap form input[type="submit"] {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 495 434.4' style='enable-background:new 0 0 495 434.4;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath id='XMLID_53_' class='st0' d='M164.7,426.4c0,3,1.6,5.7,4.3,7.1c2.6,1.4,5.8,1.2,8.2-0.5l55.1-37.6l-67.6-32.2L164.7,426.4 L164.7,426.4z'/%3E%3Cpath id='XMLID_52_' class='st0' d='M492.4,2.1C490.9,0.7,489,0,487,0c-1.2,0-2.4,0.3-3.5,0.8L7.9,234.1C3,236.5,0,241.5,0,246.9 c0,5.4,3.2,10.4,8.1,12.7l125.3,59.7l250.6-206L164.5,334.1l156.1,74.4c1.9,0.9,4,1.4,6.1,1.4c1.8,0,3.5-0.3,5.2-1 c3.6-1.4,6.5-4.3,8-8l154.6-390C495.7,7.9,494.9,4.4,492.4,2.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-color:#ff9a00;
    border-color:#ff9a00;
    color: #FFF;
    width: auto;
    border-radius: 4px;
    padding: 6px 10px 6px 30px;
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center left 8px;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.requestNewintegrateBox-wrap form input[type="submit"]:hover{
    background-color:#FFCB62;
}

/*-- Search By Brands --*/
.searchByBrands_modal .search-wrapper .search-input-wrapper { width:calc(100% - 150px); margin: 0; }
.searchByBrands_modal  .modal-body { padding: 25px; }
.searchByBrands_modal .search-wrapper .search-input-wrapper .search-input {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #e5e6e7;
    color: #545454;
}
.searchByBrands_modal .search-wrapper { -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }
.searchByBrands_modal .search-filter-btn { background-color: transparent; }
.brandSearch_listing_wrap { margin-top: 10px; }
.brandSearch_listing_wrap .viewing { margin-bottom: 10px; display: block; text-align: right; }
.brandSearch_listing_wrap .brands-cons-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-size: 13px;
    line-height: 1;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    cursor: pointer;
    min-height: 30px;
    width: 33.33%;
    padding:0 10px;
}
/* .brandSearch_listing_wrap .brands-cons-list:hover { box-shadow: 0 0 3px 1px #7f7f7f; font-weight: 700; } */
.pagination_wrap_custom { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; margin-top: 30px; }
.pagination_wrap_custom .page_box_custom {
    width: 28px;
    height: 28px;
    border: 1px solid #ddd;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
    cursor: pointer;
}
.pagination_wrap_custom .page_box_custom:hover,.pagination_wrap_custom .page_box_custom.active{ background-color:#f4f4f4; }
.pagination_wrap_custom .page_box_custom:first-child { border-top-left-radius: 4px; border-bottom-left-radius: 4px; }
.pagination_wrap_custom .page_box_custom:last-child { border-bottom-right-radius: 4px; border-top-right-radius: 4px; }
.searchByBrands_modal .btn-close {
    background-size: 10px;
    background-image: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: #454545;
    color: #ffffff;
    border: 0;
}

.view-mode{
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    margin-right: 10px;
}@media screen and (max-width: 768px) {
    .view-mode {
        text-align: center;
        width: 100%;
    }
}

@media screen and (max-width: 416px) {
    .mob-integration {
        margin: 0 9% 0 9%;
    }
}

.feed-status-section { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.feed-status-toggle { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.feed-status-toggle .btn-default {
    background: #E6E6E6;
    border: 1px solid #E6E6E6;
    border-radius: 3px;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -ms-user-select: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
.feed-status-toggle .btn-default + .btn-default { border-top-left-radius: 0; border-bottom-left-radius: 0; }
.feed-status-toggle .btn-default.pause-btn { background-color: #BF3E3E; border-color: #BF3E3E; color: #ffffff; }
.feed-status-toggle .btn-default.actv-btn { background-color: #28a745; border-color: #28a745; color: #ffffff; }
.feed-status-toggle .btn-default:first-child { border-top-right-radius: 0; border-bottom-right-radius: 0; }
.feed-status-section h1 a { font-size: 15px; line-height: 2; margin: 0px 5px 0 30px; vertical-align: middle; }
.feed-status-section h1 { line-height: 1; font-size: 24px; }
.catalog-wrap { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;position: relative;}
.catalog-header { background-color: #212529; padding: 20px 0; }
.catalog-wrap .second { position: absolute; right: 0;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);}
.feed-bttn {
    width: 200px;
    height: 36px;
    padding: 6px 10px;
    border-radius: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.catalog-wrap .second .btn { background-color: #ffffff; }
.product-min > div { width: 40px; font-size: 13px; }
.product-min { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.actions .dropdown button > span { display: none; }
.product-heading > .product-min > div { font-weight: bold; }
.product-min > div.status { width: 92px;text-align: center;}
.product-min > div.image { width:70px; }
.product-min > div.sku { width: 160px; }
.product-min > div.title {width: 100%;margin-right: auto;max-width:200px;}
.product-min > div.category { width: 270px; }
.product-min > div.price { width: 90px; }
.product-min > div.quantity { width: 48px; }
.bttnAction_product {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    background: #ff9a00;
    border-color: #ff9a00;
    color: #FFF;
}
.product-heading {
    font-weight: 700;
    padding:10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #436FA5;
    background-color:#ffffff;
}
.feed-wrapper .product { padding: 0 10px 10px; margin-bottom: 10px; border-bottom: 1px solid #D9D9D9; }
.products-container > .product:last-child { border-bottom: 0; }
.product-catalogue-section .advanced-search-wrap .dropdown-menu,.product-catalogue-section .search-filter-wrap .dropdown-menu { z-index: 9999; }
.product-min > div.view-full > i { font-size: 16px; line-height: 1;cursor: pointer;}
.product-min > div.image i { font-size: 22px; line-height: 1; }
.products-container > .product > .product-min > .sku, .products-container > .product > .product-min > .title,.products-container > .product > .product-min > .category {font-size: 13px;}
span.label{
    font-size: 13px;
    padding: 3px 4px;
    color: #676a6c;
    background-color: #D1DADE;
    font-weight: 700;
    line-height: 1;
    white-space: nowrap;
    text-align: center;
}
span.label.label-queue{ background-color:#544783; color: #FFF; }
span.label.badge-danger,span.label.label-danger { background-color: #ED5565; color: #FFF; }
span.label.label-green { background-color:#15897C; color: #FFF; }
.widget-wrap { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.widget { border-radius: 5px; padding: 4px 8px; margin-top: 10px; }
.gray-bg { background-color: #f3f3f4; }
.widget span { font-style: normal; font-weight: 400; color: #545454; font-size: 13px; line-height: 1; }
.widget h3 { font-style: normal; font-weight: 400; margin-top: 5px; margin-bottom: 0; font-size: 14px; line-height: 1; }
.widget + .widget { margin-left: 6px; }
.product-full { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; margin-top: 15px; padding-left: 90px; margin-bottom: 10px; }
.product-full > .head { width: 30px;text-align:center;}
.product-full > .images { width: 180px; }
.product-full > .row { width: calc(100% - 210px); }
.first.product-details-list > div { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.first.product-details-list > div > div:first-child { font-weight: 700; padding-right: 6px; width: 140px; font-size: 13px; }
.first.product-details-list > div > div:last-child {
    font-style: normal;
    font-weight: 400;
    color: #545454;
    font-size: 13px;
    line-height: 1;
}
.product-full > .images > i { font-size: 160px; color: #cdcdcd; }
.content strong { font-size: 13px; }
.content small { font-size: 11px; }
.widget.store-price { background-color: #29476B; color: #ffffff; width: 100%; }
.widget + .widget.store-price { margin-left: 0; }
.widget.store-price span { color: #ffffff; }
.product-min > div.view-full.open > i::before { content: "\f151"; }
.catalogue-modal-wrap .modal-header { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;border:0;}
.catalogue-modal-wrap .modal-body { padding: 0; }
.catalogue-rules-main-tab-wrap ul { margin: 0; padding: 0; list-style: none; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.catalogue-rules-main-tab-wrap .nav-item a {
    background:#ff9a00;
    border: 1px solid #ff9a00;
    color: #FFF;
    border-radius: 3px;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -ms-user-select: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
.btn-primary{ background:#ff9a00; border-color:#ff9a00; }
.btn-primary:hover,.catalogue-rules-main-tab-wrap .nav-item a:hover { background-color: #ffb84c; color: #ffffff; border-color: #ffb84c; }
.catalogue-rules-inner-tab-wrap { padding: 30px 0 0;}
.catalogue-rules-inner-tab-wrap > ul { padding: 0 20px;background-color: #F2F2F2;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.catalogue-rules-inner-tab-wrap ul.nav-pills > button { margin-left: auto; }
.catalogue-rules-inner-tab-wrap .nav-item button {
    display: block;
    font-size: 13px;
    line-height: 1;
    padding: 18px 20px;
    background-color: transparent;
    position: relative;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.catalogue-rules-inner-tab-wrap .nav-item button::after {
    content: '';
    position: absolute;
    width: 100%;
    height:0;
    background-color:#ff9a00;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.catalogue-rules-inner-tab-wrap .nav-item button:hover{background-color:#ffffff;}
.catalogue-rules-inner-tab-wrap .nav-item button.active::after,.catalogue-rules-inner-tab-wrap .nav-item button:hover::after{height:3px;}
.catalogue-rules-inner-tab-wrap > ul > li + li { border-left: 1px solid #E3E3E3; }
.catalogue-modal-wrap .modal-header h5 { font-size: 26px; line-height: 1.2; font-weight: 400; }
.catalogue-rules-inner-tab-wrap .tab-content { padding: 25px; }
.catalogue-input-wrap { padding: 10px 0; }
.catalogue-input-wrap .form-group { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; font-size: 13px; line-height: 1; }
.catalogue-input-wrap label {
    width: 150px;
    padding: 8px 10px 8px 0;
    font-size: 13px;
    line-height: 1;
}
.catalogue-input-wrap .input-group small {
    margin-bottom: 5px;
}
.catalogue-input-wrap .form-group select { padding: 7px 20px 7px 10px; border: 1px solid #e5e6e7; }
.catalogue-input-wrap .input-group { -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.catalogue-input-wrap .input-group input {
    width: auto;
    -webkit-box-flex: 0;
        -ms-flex: 0  0 auto;
            flex: 0  0 auto;
    border: 1px solid #e5e6e7;
    font-size: 13px;
    line-height: 1;
    padding: 8.5px 10px;
}
.catalogue-input-wrap .input-group span { border: 1px solid #e5e6e7; }
.catalogue-input-wrap .input-group  span { padding: 5.5px 10px; background-color: transparent; }
.catalogue-input-wrap div + div { margin-top: 10px; }
.example-products { background-color: #FAFAFA; border: 1px solid #F2F2F2; border-radius: 2px; margin-top: 30px; position: relative; }
.example-products .head { background-color: #FAFAFA; padding: 6px; }
.example-products .head h4.m-r-sm { font-size: 14px; font-style: normal; font-weight: 400; line-height: 1; margin: 0; }
.example-products .head .btn.btn-xs { padding: 3px 5px; line-height: 1; }
.example-products .head table { width: 100%; }
.example-products .meta h5 { margin: 0; font-size: 13px; font-style: normal; font-weight: 400; line-height: 1; }
.example-products .meta { background-color: #FFF; padding: 6px; }
.example-products  .content { padding: 6px; }
.example-products .content table { width: 100%; font-size: 13px; line-height: 1; }
.example-products .content table td,.example-products .content table th { padding: 3px 4px; border-right: 1px solid #E3E3E3; text-align: center; }
.example-products .content table td:last-child, .example-products .content table th:last-child { border-right: 0; }
.example-products .computed { text-align: center; background-color: #BEFFD7; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
.example-products .computed .prices { width: 50%; padding: 4px; }
.custom-switch-wrap input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;
}
.custom-switch-wrap input + label {
    width: 55px;
    height: 22px;
    border: 1px solid #e7eaec;
    background-color: #ffffff;
    border-radius: 3px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.custom-switch-wrap input + label span {
    width: 50%;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height:100%;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
}
.custom-switch-wrap input + label span:first-child { background-color: #4483F2; border-color: #4483F2; color: #FFF; }
.custom-switch-wrap input:checked + label span:first-child { background-color: #ffffff; color: #3C3C3C; }
.custom-switch-wrap input:checked + label span:last-child { background-color: #4483F2; border-color: #4483F2; color: #FFF; }
.switch-con { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.advanced-opts .option-con { margin-bottom: 15px; padding-bottom: 15px; border-bottom: 1px solid #F2F2F2; }
.switch-con .custom-switch-wrap { width: 10%; }
.nested-box-wrap { margin: 12px; padding-left: 12px; border-left: 2px solid #f3f3f4; }
.nested-box-wrap .catalogue-input-wrap { padding: 0; }
.nested-box-wrap h3 { font-size: 16px; font-style: normal; font-weight: 400; line-height:1.2; margin-top: 5px; margin-bottom: 10px; }
.nested-box-wrap p { font-style: normal; font-weight: 400; color: #545454; font-size: 13px; line-height: 1.2; margin: 0 0 10px; }
.category-mapping-title { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;margin-bottom: 30px;}
.category-mapping-title h2 { margin: 0; font-size:20px; line-height: 1; font-weight: 500; }
.category-mapping-filter-wrap button { font-size: 12px; line-height: 1; padding: 8px 10px; }
.category-mapping-filter-wrap button.btn-outline-secondary { border-color: rgb(0 0 0 / 20%); }
.category-mapping-filter-wrap { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.category-mapping-filter-wrap > div { padding: 0 10px; }
.category-pricing-wrap { padding:10px; background-color: #FAFAFA; margin-top: 20px; }
.category-product--modal-listing--box { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;margin-bottom: 6px;padding-bottom: 6px;border-bottom: 1px solid #A5A5A5;}
.category-product--modal-listing--box .see-more {width: 5%;font-size: 12px;line-height: 1;min-height: 31px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.category-product--modal-listing--box .configure { width: 5%;text-align: right;}
.category-product--modal-listing--box .cat { -webkit-box-flex: 1; -ms-flex: 1; flex: 1;max-width: calc(100% - 90px);}
.category-product--modal-listing--box .cat .main { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }
.category-product--modal-listing--box .cat .main > div:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.category-product--modal-listing--box .cat .main > div:first-child .cat-name {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    border: 1px solid #E3E3E3;
    padding: 4px;
    background-color: #FFF;
    border-radius: 5px 0 0 5px;
}
.category-product--modal-listing--box .cat .main > div:first-child .markup-value-display {
    background-color: #F7FAFF;
    color: #5A5A5A;
    font-weight: 700;
    text-align: right;
    white-space: nowrap;
    border-radius: 0 5px 5px 0;
    border: 1px solid #E3E3E3;
    padding: 4px;
    border-left: 0;
    font-size: 11px;
    line-height: 1;
    min-height: 31px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.category-product--modal-listing--box .cat .main .text-end { font-size: 12px; line-height: 1; padding: 0 10px; min-width: 62px; }
.category-product--modal-listing--box .see-more i { margin-right:4px;cursor: pointer;}
.border-wall { padding-right: 4%; margin-left: .5%; padding-left: 5.5%; border-left: 1px solid #E3E3E3;margin-top: 8px;}
.category-product--modal-listing--box .configure i { color: #545454; }
.border-wall .category-product--modal-listing--box{margin-bottom: 8px;padding-bottom: 0;border-bottom: 0;position: relative;}
.child-cats{width:100%;}
.border-wall .category-product--modal-listing--box::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 1px;
    background-color: #E3E3E3;
    left: -40px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}
.category-product--modal-listing--box .manage {
    background-color: #F2F2F2;
    padding: 8px 10px;
    border-radius: 5px;
    margin-top: 12px;
    position: relative;
}
.cat-pricing-row { margin-top:14px; }
.cat-pricing-row h5 {
    font-size: 12px;
    line-height: 1;
    margin: 5px 0 10px;
    /* font-family: 'Inter'; */
    font-family: 'Nunito SemiBold';
    font-weight: normal;
}
.brand-rules-box-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.brand-rules-box-head p { margin: 0; }
.brand-rules-box-head .brand-rules-badge { margin-left: auto; }
.brand-rules-box .manage {
    background-color: #F2F2F2;
    padding: 8px 10px;
    border-radius: 5px;
    margin-top: 12px;
    position: relative;
}
.brand-rules-box { border-bottom: 1px solid #F2F2F2; padding: 10px 0; }
.brand-rules-badge { background-color: #E0E3E9; color: #5A5A5A; padding: 7px 12px 8px; line-height: 1.42857143; border-radius: 3px; margin-right: 10px; display: inline-block; }
.brand-rules-toggle {
    color: inherit;
    background: #fff;
    border: 1px solid #e7eaec;
    border-radius: 3px;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -ms-user-select: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    width:40px;
}
.brand-rules-toggle.collapsed i::before { content: "\f013"; }
.brand-rules-toggle i::before { content: "\f106"; }
.product-mobile .title p { font-size: 13px; line-height: 1.2; margin: 0 13px 6px; }
.product-mobile .product-mobile.mobile-con {display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.product-mobile .side-toggles { width: 40px; text-align: center; }
.product-mobile .mobile-con .main .main-table { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.product-mobile .mobile-con .main .main-table .image { width: 100px; font-size: 80px; }
.product-mobile .mobile-con .content table td { font-size: 11px; line-height: 1.2; padding: 5px 10px 5px 0; }
.product-mobile .mobile-con .content table td:first-child { font-weight: bold; }
.product-mobile .main-table .foot { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.product-mobile .main-table .foot > div { background-color: #F9F9F9; border: 1px solid #EDEDED; width: 33.33%; font-size: 13px; line-height: 1.4; text-align: center; padding: 2px 5px; }
.product-mobile .mobile-con { display: -webkit-box; display: -ms-flexbox; display: flex;  -webkit-box-align: center;  -ms-flex-align: center;  align-items: center; }
.product-mobile{display:none;}
.product-mobile .main { max-width: calc(100% - 40px); margin-left:10px ;width:100%;}
.product-full .title.on-small-hide { margin-bottom: 5px; }
.catalogue-modal-wrap { z-index: 999999; }
.customize-product-model td {
    padding: 8px 0;
    border-bottom: 1px solid #dee2e6;
}

.ac_details {
    border-top: 3px solid #ff9a00;
}
.imp_msg p .api_code {
    background: #000;
    color: #fff;
    padding: 5px;
}
.account_input {
    background: #eee;
    border-radius: 0;
    padding: 6px 10px;
}
input.account_input:disabled {
    background: #eee;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #333;
    outline: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
}
img.authen_img {
    max-width: 100%;
    height: auto;
    width: 24px;
}
.pwd_div i {
    right: 9px;
    top: 9px;
    width: 18px;
    cursor: pointer;
}
img {
    max-width: 100%;
    height: auto;
}


@media screen and (max-width:575px) {
    .product-full{padding-left: 0;}
}